import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Route } from "react-router-dom";
import UserRole from  "../businessjs/Enum"

import {
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText,
  AuthHelperLogout
} from "../businessjs/AuthHelper";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_mobile: false,
      loginType: "general",
      userType: "guest",
      selectedmodule: "country"
    };

    this.onClickMenuItem_LogOut = this.onClickMenuItem_LogOut.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      this.updateState(token);
    } else {
      this.props.history.push(`/login`);
    }
  }

  updateState(token) {
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    //console.log(" new tocken decoded ", decoded);
    //console.log(" new tocken decoded accountType ", decoded.accessType);
    this.setState({
      token: token,
      subscriber_id: decodedUser.subscriber_id,
      first_name: decodedUser.first_name,
      last_name: decodedUser.last_name,
      email: decodedUser.email,
      account_type: decodedUser.account_type,
      company_name: decodedUser.company_name,
      loginType: decoded.accessType,
      userType: decoded.accessType,
      role: decodedUser.role,
      home_url:
        decoded.accessType === "subscriber"
          ? "/subscriberhome"
          : "/supportcenter",
      roleText: AuthHelperGerSubscriberRoleText(decodedUser.role)
    });
  }

  onClickMenuItem_LogOut = e => {
    e.preventDefault();
    AuthHelperLogout("layoutsubscriber");

    this.setState({
      loginType: "general",
      userType: "guest"
    });
    this.props.history.push("/login");
  };

  formatExecutiveButton() {
    const ButtonExecutive = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="nobutton"
            onClick={() => {
              history.push({
                pathname: "/executiveedit"
              });
            }}
          >
            Hi, {this.state.first_name} |
          </button>
        )}
      />
    );
    return <ButtonExecutive />;
  }

  formatSubscriberButton() {
    const ButtonSubscriber = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="nobutton"
            onClick={() => {
              history.push({
                pathname: "/subscriberedit"
              });
            }}
          >
            {this.state.company_name} |
          </button>
        )}
      />
    );
    return <ButtonSubscriber />;
  }

  header() {
    return (
      <header className="top">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 animated slideInDown">
              <a href="/">
                <img
                  src="images/logo.png"
                  className="img-responsive"
                  alt="logo"
                />
              </a>
            </div>
            <div className="col-sm-12 col-md-6 animated slideInUp">
              <div className="pull-right cont">
                <ul>
                  <li>
                    <span className="icon">
                      <i className="fa fa-user" aria-hidden="true" />
                    </span>
                    {this.formatExecutiveButton(this.state.itemObject)}
                  </li>
                  <li>
                    <span className="icon">
                      <i className="fa fa-building" aria-hidden="true" />
                    </span>

                    {this.formatSubscriberButton(this.state.itemObject)}
                  </li>
                  <li>
                    <span className="icon">
                      <i className="fa fa-close" aria-hidden="true" />
                    </span>
                    <button
                      className="nobutton"
                      onClick={this.onClickMenuItem_LogOut}
                    >
                      Exit
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }

  executiveMenu() {
    return <ul className="flexy-menu orange">
      <li>
        <Link to="/megamap">Map</Link>
      </li>
      <li>
        <Link to="/vehicles">Vehicles</Link>
      </li>

      <li>
        <Link to="/alerts">Alerts </Link>
      </li>
     
      <li>
        <Link to="/reportshome">Reports</Link>
      </li>
      
      <li>
        <Link to="/charts">Charts</Link>
      </li>
    </ul>;
  }

  executiveAdminMenu() {
    return <ul className="flexy-menu orange">
      <li>
        <Link to="/megamap">Map</Link>
      </li>
      <li>
        <Link to="/vehicles">Vehicles</Link>
      </li>

      <li>
        <Link to="/alerts">Alerts </Link>
      </li>
      <li>
        <Link to="/masters">Masters</Link>
      </li>
      <li>
        <Link to="/reports">Reports</Link>
      </li>
      <li>
        <Link to="/settings">Settings</Link>
      </li>
      <li>
        <Link to="/charts">Charts</Link>
      </li>
    </ul>;
  }

  executiveOwnerMenu() {
    return <ul className="flexy-menu orange">
      <li>
        <Link to="/megamap">Map</Link>
      </li>
      <li>
        <Link to="/vehicles">Vehicles</Link>
      </li>

      <li>
        <Link to="/alerts">Alerts </Link>
      </li>
      <li>
        <Link to="/masters">Masters</Link>
      </li>
      <li>
        <Link to="/reports">Reports</Link>
      </li>
      <li>
        <Link to="/settings">Settings</Link>
      </li>
      <li>
        <Link to="/charts">Charts</Link>
      </li>
    </ul>;
  }

  showMenu()
  { 
   
      if(localStorage.apiAuthRole === UserRole.EXECUTIVE.toString())
      {
        return this.executiveMenu();
      }
      else if (localStorage.apiAuthRole === UserRole.EXECUTIVE_ADMIN.toString())
      {        
        return this.executiveAdminMenu();
      }
      else if(localStorage.apiAuthRole === UserRole.EXECUTIVE_OWNER.toString())
      {               
        return this.executiveOwnerMenu()
      }
      
  }

  render() {
    return (
      <React.Fragment>
        {this.header()}

        <div className="menu-bar">
          <div className="container">
            <div className="row">
              {this.showMenu()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Navbar);
