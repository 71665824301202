var POIItem = {
  map: null,
  maps: null,
  marker: null,
  infowindow: null,
  location: null,
  circle: null,
  dismissCallback: null,
  addnewCallback: null,
  radius: 500,
  radiuInput: null,
  poinameInput: null,
  labelError: null,
  labelLatLng: null,
  init: function(map, maps, addnewCallback, dismissCallback) {
    let self = this;
    self.map = map;
    self.maps = maps;
    self.dismissCallback = dismissCallback;
    self.addnewCallback = addnewCallback;

    self.location = map.getCenter();
    self.marker = new maps.Marker({
      map: map,
      draggable: true,
      animation: maps.Animation.DROP,
      position: self.location
    });

    self.circle = new maps.Circle({
      strokeColor: "#0069d9",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#0069d9",
      fillOpacity: 0.3,
      editable: true,
      map: map,
      center: { lat: self.location.lat(), lng: self.location.lng() },
      radius: self.radius
    });

    self.infowindow = new maps.InfoWindow({
      content: self.getInfoWindowDom(self)
    });

    self.infowindow.open(map, self.marker);

    maps.event.addListener(self.marker, "dragend", function(e) {
      self.locationUpdate(e.latLng);
    });

    maps.event.addListener(self.circle, "radius_changed", function() {
      if (self.circle.getRadius() < 1000) {
        self.radiusChanged(self.circle.getRadius());
      } else {
        self.circle.setRadius(999);
      }
    });

    maps.event.addListener(map, "click", function(e) {
      self.locationUpdate(e.latLng);
    });

    self.marker.addListener("click", function() {
      self.infowindow.open(map, self.marker);
    });

    
    self.labelLatLng.innerText = (Math.round(self.location.lat() * 1000000) / 1000000) +',' + (Math.round(self.location.lng() * 1000000) / 1000000)

    return self;
  },
  radiusChanged: function(changeValue) {
    this.radius = changeValue;
    this.radiuInput.value = Math.round(changeValue);
    // console.log('new radius', changeValue);
    this.locationUpdate(this.location);
  },
  locationUpdate: function(currentPosition) {
    let self = this;
    if (self.location !== currentPosition) {
      self.location = currentPosition;
      self.marker.setPosition(currentPosition);
      self.circle.setCenter(currentPosition);
      // console.log('this.location ', this.location.lat(),this.location.lng()  );
      self.labelLatLng.innerText = (Math.round(currentPosition.lat() * 1000000) / 1000000) +',' + (Math.round(currentPosition.lng() * 1000000) / 1000000)
    }
  },
  dismiss: function() {
    let self = this;
    self.marker.setMap(null);
    self.circle.setMap(null);
    if (self.dismissCallback) {
      self.dismissCallback(self);
    }
  },
  addnew: function() {
    if (this.poinameInput.value.length < 3) {
      this.labelError.innerText = "Please enter a valid name";
      return;
    }
    if (this.addnewCallback) {
      this.addnewCallback(
        this.poinameInput.value,
        this.radius, 
        this.labelLatLng.innerText
      );
      this.dismiss();
    }
  },
  getInfoWindowDom: function(self) {
    let htmlObject = document.createElement("div");
    htmlObject.setAttribute("style", "width: 200px");

    let h3 = document.createElement("h4");
    h3.setAttribute("class", "firstHeading");
    h3.innerText = "Add New POI";

    let div2 = document.createElement("div");

    let labelName = document.createElement("label");
    labelName.innerText = "POI Name";
    let inputName = document.createElement("input");
    inputName.setAttribute("type", "text");
    inputName.setAttribute("class", "form-control");
    inputName.setAttribute("name", "poi_name");

    let labelRadius = document.createElement("label");
    labelRadius.innerText = "Radius(in meters)";
    let inputRadius = document.createElement("input");
    inputRadius.setAttribute("type", "text");
    inputRadius.setAttribute("class", "form-control");
    inputRadius.setAttribute("name", "radius");
    inputRadius.setAttribute("readonly", "true");

    let labelLatLng = document.createElement("label");

    let buttonCancel = document.createElement("button");
    buttonCancel.setAttribute("type", "button");
    buttonCancel.setAttribute("class", "btn btn-secondary btn-sm");
    buttonCancel.innerText = "Cancel";

    buttonCancel.addEventListener("click", function() {
      self.dismiss();
    });

    let buttonAdd = document.createElement("button");
    buttonAdd.setAttribute("type", "button");
    buttonAdd.setAttribute("class", "btn btn-primary btn-sm");
    buttonAdd.innerText = "Add ";

    buttonAdd.addEventListener("click", function() {
      self.addnew();
    });

    let br = document.createElement("br");
    let labelError = document.createElement("label");

    div2.appendChild(labelName);
    div2.appendChild(inputName);
    div2.appendChild(labelRadius);
    div2.appendChild(inputRadius);
    div2.appendChild(labelLatLng);
    div2.appendChild(br);
    div2.appendChild(buttonAdd);
    div2.appendChild(buttonCancel);
    div2.appendChild(labelError);

    htmlObject.appendChild(h3);
    htmlObject.appendChild(div2);

    this.poinameInput = inputName;
    this.radiuInput = inputRadius;
    this.labelError = labelError;
    this.labelLatLng = labelLatLng;

    inputRadius.value = this.radius;

    return htmlObject;
  }
};

module.exports = POIItem;
