import React from "react";
import { IgnitionAlertCount, IgnitionAlerts } from "../businessjs/AlertFn";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import jwt_decode from "jwt-decode";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";

import Pagination from "react-js-pagination";
import moment from "moment-timezone";
import { Route } from "react-router-dom";

class ViewIgnitionAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    this.state = {
      allDataRows: [],
      subscriber_id: 0,
      user_role: 3,
      start: 1,
      end: 10,
      activePage: undefined,

      itemsCountPerPage: 10,
      totalItemsCount: 0,
      vehicle_id: this.props.vehicle_id,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      itemObject: this.props.itemObject
    };
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({ subscriber_id: decodedUser.subscriber_id });
  }

  componentDidMount() {
    this.getIgnitionAlertCount();
  }

  getIgnitionAlertCount = () => {
    const { startDate, endDate, vehicle_id } = this.state;

    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      startDate: startDate,
      endDate: endDate,
      vehicleId: vehicle_id
    };

    IgnitionAlertCount(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let alertCount = res[0].alertcount;
          if (alertCount > 0) {
            this.setState({ totalItemsCount: alertCount, activePage: 1 });
            this.getIgnitionAlerts(1);
          } else {
            this.setState({
              allDataRows: [],
              totalItemsCount: 0,
              activePage: 1
            });
          }
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getIgnitionAlerts = pageNumber => {
    const { startDate, endDate, itemsCountPerPage, vehicle_id } = this.state;

    let start = (pageNumber - 1) * this.state.itemsCountPerPage;
    //let end = start + this.state.itemsCountPerPage;
    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      startDate: startDate,
      endDate: endDate,
      vehicleId: vehicle_id,
      startLimit: start,
      endLimit: itemsCountPerPage
    };

    IgnitionAlerts(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allDataRows: res });

          if (!this.state.activePage) {
            this.setState({ totalItemsCount: res.length, activePage: 1 });
          } else {
          }
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    this.getIgnitionAlerts(pageNumber);
  };

  formatViewButton(item) {
    // console.log("itemObject", this.state.itemObject);
    let historyData = {
      autoid: this.state.itemObject.autoid,
      title: this.state.itemObject.title,
      start: new Date(item.start_time).toLocaleString(),
      end: new Date(item.end_time).toLocaleString()
    };
    const ButtonHistory = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="nobutton"
            onClick={() => {
              history.push({
                pathname: "/viewtracking",
                state: { itemObject: historyData }
              });
            }}
          >
            View
          </button>
        )}
      />
    );
    return <ButtonHistory />;
  }

  render() {
    let showDataRows = this.state.allDataRows;
    return (
      <React.Fragment>
        <section>
          <div className="mt-4" style={{ width: "100%" }}>
            <div>
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="pb-2">
                        Ignition Alerts :{" "}
                        {moment(this.state.startDate).format("DD MMM YYYY")} -{" "}
                        {moment(this.state.endDate).format("DD MMM YYYY")}
                        {/* "DD MMM YYYY, h:mm:ss a" */}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Vehicle Title</th>
                          <th>Plate No</th>
                          <th>Place</th>
                          <th>Start Location</th>
                          <th>Start Time</th>
                          <th>End Location</th>
                          <th>End Time</th>
                          <th>Trip No</th>
                          <th>Mileage</th>
                          <th>Driver</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {showDataRows.map((Item, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>{Item.title}</td>
                              <td>{Item.plate_number}</td>
                              <td>{Item.start_place}</td>
                              <td>
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={`https://www.google.com/maps/place/${
                                    Item.start_geo
                                  }`}
                                >
                                  {Item.start_geo}
                                </a>
                              </td>
                              <td>
                                {AppHelper_DateAndTimeToString(Item.start_time)}
                              </td>
                              <td>
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={`https://www.google.com/maps/place/${
                                    Item.end_geo
                                  }`}
                                >
                                  {Item.end_geo}
                                </a>
                              </td>

                              <td>
                                {AppHelper_DateAndTimeToString(Item.end_time)}
                              </td>
                              <td>{Item.device_tripno}</td>
                              <td>{Item.mileage}</td>
                              <td>{ Item.driver_name}</td>
                              <td>{this.formatViewButton(Item)}</td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                    <center>
                      {showDataRows.length ? "" : "No records available"}
                    </center>
                  </div>

                  <div className="row">
                    <div className="col-12" style={{ textAlign: "right" }}>
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={10}
                        onChange={this.handlePageChange}
                        //   prevPageText="prev"
                        //   nextPageText="next"
                        //   firstPageText="first"
                        //   lastPageText="last"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ViewIgnitionAlerts;
