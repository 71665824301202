import React, { Component } from "react";
import { SubscribersRegistration } from "../businessjs/SubscribersFn";
import { Link, withRouter } from "react-router-dom";

class GuestRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      errorText: "",
      succesText: "",
      account_type: 1
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    // Router.push('/supportcenter');
    //

    const regFormInputes = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      account_type: this.state.account_type,
      updateby: -1 // free registartion
    };

    console.log(" reg regFormInputes", regFormInputes);

    SubscribersRegistration(regFormInputes).then(res => {
      if (res) {
        //this.props.history.push(`/profile`);
        console.log("on submit data", res);

        if (res.status === "success") {
          console.log("login success");
          //Router.push("/supportcenter");

          this.setState({
            errorText: "",
            first_name: "",
            last_name: "",
            email: "",
            succesText: "Your account created, please proceed with login!",
            password: ""
          });
        } else {
          console.log("login error", res);
          if (res.status) {
            this.setState({
              errorText: res.status.toUpperCase() + ": " + res.message
            });
          }
        }
      }
    });
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 mt-5 mx-auto">
            <div className="card mb-1">
              <div className="card-header">
                <h4 className="card-title">Registration</h4>
              </div>
              <div className="card-content collpase show">
                <div className="card-body">
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                      <label htmlFor="first_name">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="first_name"
                        placeholder="Enter Fist Name"
                        required
                        value={this.state.first_name}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        required
                        placeholder="Enter Last Name"
                        value={this.state.last_name}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter Email"
                        required
                        value={this.state.email}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="Enter Password"
                        required
                        value={this.state.password}
                        onChange={this.onChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="password">Type</label>

                      <select
                        className="form-control"
                        name="account_type"
                        onChange={this.onChange}
                        value={this.state.account_type}
                      >
                        <option value="1">Basic</option>
                        <option value="2">Premium</option>
                      </select>
                    </div>

                    <button type="submit" className="btn btn-lg btn-primary d-inline">
                      Register
                    </button>
 
                      <Link to="/login" className="nav-link d-inline">
                        Login Here
                      </Link>

                  </form>
                  <label className="errorlabel"> {this.state.errorText}</label>
                  <label className="successlabel">
                    {" "}
                    {this.state.succesText}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(GuestRegister);
