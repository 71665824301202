import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "lastupdate";

export const LastupdateSelectBySubscriber = objItem => {
    return axios
      .post(proxyServer + "/getbysubscriber", { objItem })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };
  