import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import GuestLogin from "./compon/GuestLogin";
import GuestRegister from "./compon/GuestRegister";
import LogList from "./compon/LogList";
import LogListVehicles from "./compon/LogListVehicles";
import SubscriberHome from "./compon/SubscriberHome";
import SubscriberProfile from "./compon/SubscriberProfile";
import Executive from "./compon/Executive";
import SupportMember from "./compon/SupportMember";
import SupportCenterHome from "./compon/SupportCenterHome";
import SupportCenterLogin from "./compon/SupportCenterLogin";
import Landing from "./compon/Landing";
import Vehicle from "./compon/Vehicle";
import SubscriberList from "./compon/SubscriberList";
import Subscription from "./compon/Subscription";
import SubscriptionList from "./compon/SubscriptionList";
import VehicleList from "./compon/VehicleList";
import LiveTracking from "./compon/LiveTracking";
import HistoryTracking from "./compon/HistoryTracking";
import ViewTracking from "./compon/ViewTracking";
import Driver from "./compon/Driver";
import Passengers from "./compon/Passengers";
import SubscriptionWithVehicle from "./compon/SubscriptionWithVehicle";
import VehicleGroup from "./compon/VehicleGroup";
import POI from "./compon/PointOfIninterest";
import POIVehicles from "./compon/POIVehicles";

import RptAccelerationAlerts from "./compon/RptAccelerationAlerts";
import RptBrakingAlerts from "./compon/RptBrakingAlerts";
import RptCorneringAlerts from "./compon/RptCorneringAlerts";

import RptIgnitionAlerts from "./compon/RptIgnitionAlerts";
import RptOverSpeedAlerts from "./compon/RptOverSpeedAlerts";
import RptPOIAlerts from "./compon/RptPOIAlerts";
import RptIdlingAlerts from "./compon/RptIdlingAlerts";



import RptIgnitionAlertSummary from "./compon/RptIgnitionAlertSummary";
import RptIgnitionUsageSummary from "./compon/RptIgnitionUsageSummary";
import RptIgnitionConsolidatedAlertSummary from "./compon/RptIgnitionConsolidatedAlertSummary"
import RptIgnitionConsolidatedUsageSummary from "./compon/RptIgnitionConsolidatedUsageSummary"

import RptReconnectAlert from "./compon/RptReconnectAlert";
import RptTimedPingAlert from "./compon/RptTimedPingAlert";

import RptDeviceStatus from "./compon/RptDeviceStatus";

import ChangePassword from "./compon/ChangePassword";
import ChangePasswordByAdmin from "./compon/ChangePasswordByAdmin"

import VehicleTile from "./compon-styled/VehicleTile";
import Ignition from "./compon/Ignition";
import AlertHome from "./compon/AlertHome";
import MasterHome from "./compon/MasterHome";
import ReportHome from "./compon/ReportHome";
import SettingsHome from "./compon/SettingsHome";
import ChartHome from "./compon/ChartHome";
import ViewVehicle from "./compon/ViewVehicle";
import CommingSoon from "./compon/CommingSoon";
import MegaMap from "./compon/MegaMap";
import VehicleControls from "./compon/VehicleControls";
import VehicleEdit from "./compon/VehicleEditPopup";
import ExecutiveEdit from "./compon/ExecutiveEdit";
import SubscriberEdit from "./compon/SubscriberEdit";
import SpeedChart from "./compon/SpeedChart";
import FuelChart from "./compon/FuelChart";
import VehicleListByTitle from "./compon/VehicleListByTitle";
import ExportToExcel from "./compon/ExportToExcel";
import VerifyEmail from "./compon/VerifyEmail";
import VehicleUtilization from "./compon/VehicleUtilization";
import ExecutiveVehicleGroup from "./compon/ExecutiveVehicleGroup";
import ChangeEmail from "./compon/ChangeEmail";
import SupportCenterVehicleList from "./compon/SupportCenterVehicleList";
import VehicleAdd from "./compon/VehicleAdd";
import DriverLog from "./compon/DriverLog";
import MultipleVehicleControl from "./compon/MultipleVehicleControl";

require("dotenv").config();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: undefined
    };
  }

  handleOnLoginSuccess = itemObject => {
    //this.setState({token:localStorage.apiAuthAccessKey});
    // jkjk
  };

  render() {
    return (
      <Router>
        <React.Fragment>
          <Route exact path="/vehicletile" component={VehicleTile} />

          <Route exact path="/" component={Landing} />

          <Route exact path="/register" component={GuestRegister} />

          <Route exact path="/subscriberhome" component={SubscriberHome} />
          <Route exact path="/login" component={GuestLogin} />
          <Route exact path="/tcplog" component={LogList} />
          <Route exact path="/tcplog-vehicles" component={LogListVehicles} />

          {/* <Route 
            exact
            path="/login"
            render={props => (
              <GuestLogin
                {...props}
                onLoginSuccess={this.handleOnLoginSuccess}
              />
            )}
            />*/}
          <Route
            exact
            path="/subscriberprofile"
            component={SubscriberProfile}
          />
          <Route exact path="/executives" component={Executive} />
          <Route exact path="/supportcenter" component={SupportCenterHome} />
          <Route exact path="/supportcenter/member" component={SupportMember} />
          <Route
            exact
            path="/supportcenter/subscriberlist"
            component={SubscriberList}
          />
          <Route
            exact
            path="/supportcenter/Login"
            component={SupportCenterLogin}
          />
          <Route exact path="/vehicle" component={Vehicle} />
          <Route exact path="/subscription" component={Subscription} />
          <Route
            exact
            path="/subscriptionwithvehicle"
            component={SubscriptionWithVehicle}
          />
          <Route exact path="/subscriptionlist" component={SubscriptionList} />
          <Route exact path="/vehicles" component={VehicleList} />
          <Route exact path="/live-tracking" component={LiveTracking} />
          <Route exact path="/drivers" component={Driver} />
          <Route exact path="/passengers" component={Passengers} />
          <Route exact path="/vehicle-groups" component={VehicleGroup} />
          <Route exact path="/poi" component={POI} />
          <Route exact path="/poivehicles" component={POIVehicles} />
          <Route
            exact
            path="/acceleration-alerts"
            component={RptAccelerationAlerts}
          />
          <Route exact path="/braking-alerts" component={RptBrakingAlerts} />
          <Route
            exact
            path="/cornering-alerts"
            component={RptCorneringAlerts}
          />

          <Route
            exact
            path="/ignition-alerts"
            component={RptIgnitionAlerts}
          />
          <Route
            exact
            path="/overspeed-alerts"
            component={RptOverSpeedAlerts}
          />
          <Route exact path="/poi-alerts" component={RptPOIAlerts} />
          <Route exact path="/idling-alerts" component={RptIdlingAlerts} />
       
          <Route
            exact
            path="/ignition-alert-summary"
            component={RptIgnitionAlertSummary}
          />
          <Route
            exact
            path="/ignition-usage-summary"
            component={RptIgnitionUsageSummary}
          />

          <Route
            exact
            path="/consolidated-alert-summary"
            component={RptIgnitionConsolidatedAlertSummary}
          />
          <Route
            exact
            path="/consolidated-usage-summary"
            component={RptIgnitionConsolidatedUsageSummary}
          />


          <Route
            exact
            path="/rptreconnectalerts"
            component={RptReconnectAlert}
          />
          <Route
            exact
            path="/rpttimedpingalerts"
            component={RptTimedPingAlert}
          />
          <Route exact path="/device-status" component={RptDeviceStatus} />

          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/change-password-admin" component={ChangePasswordByAdmin} />
          
          <Route exact path="/Ignition" component={Ignition} />

          <Route exact path="/history-tracking" component={HistoryTracking} />
          <Route exact path="/viewtracking" component={ViewTracking} />
          <Route exact path="/alerts" component={AlertHome} />
          <Route exact path="/masters" component={MasterHome} />
          <Route exact path="/reports" component={ReportHome} />
          <Route exact path="/settings" component={SettingsHome} />

          <Route exact path="/view-vehicle" component={ViewVehicle} />

          <Route exact path="/commingsoon" component={CommingSoon} />
          <Route exact path="/megamap" component={MegaMap} />
          <Route exact path="/megamap2" component={MegaMap} />

          <Route exact path="/vehicle-controls" component={VehicleControls} />
          <Route exact path="/vehicle-edit" component={VehicleEdit} />
          <Route exact path="/executiveedit" component={ExecutiveEdit} />
          <Route exact path="/subscriberedit" component={SubscriberEdit} />

          <Route exact path="/charts" component={ChartHome} />
          <Route exact path="/speedchart" component={SpeedChart} />
          <Route exact path="/fuelchart" component={FuelChart} />
          <Route
            exact
            path="/vehicle-list"
            component={VehicleListByTitle}
          />
          <Route exact path="/exporttoexcel" component={ExportToExcel} />
          <Route exact path="/verifyemail/:id" component={VerifyEmail} />
          <Route
            exact
            path="/vehicleutilization"
            component={VehicleUtilization}
          />
          <Route
            exact
            path="/executive-vehicle-group"
            component={ExecutiveVehicleGroup}
          />
          <Route exact path="/changeemail" component={ChangeEmail} />
          <Route
            exact
            path="/supportcentervehicle"
            component={SupportCenterVehicleList}
          />
          <Route exact path="/vehicleadd" component={VehicleAdd}/>
          <Route exact path="/driverlog" component={DriverLog}/>
          <Route exact path="/multiple-vehicle-control" component={MultipleVehicleControl}/>
          
          {/* <Route
            exact   WebPush
            path="/historytracking/:autoid"
            render={({
              match: {
                params: { autoid }
              }
            }) => <HistoryTracking autoid={autoid} />}
          /> */}
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
