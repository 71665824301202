import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "changepassword";



export const ExecutiveChangePassword = objItem => { 
  return axios
    .post(proxyServer + "/changePassword", { objItem })
    .then(res => {      
      return res.data;
    })
    .catch(err => { 
      return err;
    });
};

export const SupportMemberSelectById = objItem => {
  return axios
    .post(proxyServer + "/getbysupportmember", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const SupportMemberChangePassword = objItem => {
  return axios
    .post(proxyServer + "/suppoertmemberchangePassword", { objItem })
    .then(res => {      
      return res.data;
    })
    .catch(err => { 
      return err;
    });
};



