const megaMapMarker = require("./MegaMapMarker");

const MegaMapObj = {
  map: null,
  maps: null,
  vehicles: [],
  lastUpdate: [],
  markerCluster: null,
  mapIcons: {
    Stopped: "/mapassets/blue-dot.png",
    Running: "/mapassets/pickup_running.png",
    Idle: "/mapassets/red-dot.png"
  },
  init: function() {
    return this;
  },
  distroy: function() {
    this.vehicles = [];
    this.lastUpdate = [];
  },
  setSelectedVehicle(vehicleId) {
    if (vehicleId > 0) {
      this.lastUpdate.forEach(element => {
        if (element.id === vehicleId) {
          element.show(true, true);
          this.map.panTo(element.position);
          
        } else {
          element.show(false, false);
        }
      });
    } else if (vehicleId === 0) {
      this.lastUpdate.forEach(element => {
        element.show(true, false);
      });
    } else if (vehicleId === -1) {
      this.lastUpdate.forEach(element => {
        element.show(false, false);
      });
    }
  },
  setMap: function(map, maps) {
    let self = this;
    self.map = map;
    self.maps = maps;

    //self.markerCluster = new MarkerClusterer(self.map, [],
    //    {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

    if (this.lastUpdate.length) {
      this.setLastUpdates(this.lastUpdate);
    }

    return self;
  },
  setVehicles: function(items) {
    this.vehicles = items;
  },
  setLastUpdates: function(items) {
    if (this.vehicles.length === 0) return;
    if (this.maps === null) return;
    let oldInfo = null;

    items.forEach(newInfo => {
      // checking is same id available
      oldInfo = this.lastUpdate.find(obj => obj.id === newInfo.vehicle_id);

      this._setMegaMarker(oldInfo, newInfo);
    });
  },
  _setMegaMarker: function(oldInfo, newInfo) {
    if (oldInfo) {
      oldInfo.updateInfo(newInfo);
    } else {
      let _vehicle = this.vehicles.find(
        vehi => vehi.autoid === newInfo.vehicle_id
      );
      if (_vehicle) {
        let _marker = this._getNewMegaMarkerObj(newInfo, _vehicle);
        this.lastUpdate.push(_marker);
      }
    }
  },
  _getNewMegaMarkerObj: function(info, vehicle) {
    let self = this;

    let position = { lat: parseFloat(info.lat), lng: parseFloat(info.log) };
    let mapmarker = new this.maps.Marker({
      id: info.vehicle_id,
      position: position,
      title: vehicle.title,
      map: this.map
    });

    let _newMarker = megaMapMarker(self, info, vehicle, mapmarker, position);

    return _newMarker;
  }
};

module.exports = MegaMapObj;
