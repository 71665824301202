import React from "react";
import {
  SubscriptionAddNew,
  SubscriptionUpdate,
  SubscriptionStatusUpdate,
  SubscriptionGetAll,
  SubscriptionAssignToVehicle
} from "../businessjs/SubscriptionFn";

import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import { SubscriberGetAll } from "../businessjs/SubscribersFn";

import SubscriptionItem from "./SubscriptionItem";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriber_id: 0,
      title: "",
      type: "",
      device_id: "",
      subscription_code: "",
      //expiry_date: "",
      re_newal_interval: "",

      toastItems: [],
      subscribers: [],

      allDataRows: [],
      filteredDataRows: []
    };

    this.searchHandler = this.searchHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value,
      filteredDataRows = this.state.allDataRows.filter(el => {
        let searchValue = el.title;
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      filteredDataRows: filteredDataRows
    });
  }

  componentDidMount() {
    SubscriptionGetAll({
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        this.setState({ allDataRows: res });
        this.setState({ filteredDataRows: res });
      }
    });

    SubscriberGetAll({
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        if (res.userdata) {
          //this.state.subscribers.map(sub => (

          var jsonArr = [];
          res.userdata.map(data => {
            jsonArr.push({ value: data.autoid, text: data.company_name });
            return 0;
          });

          this.setState({ subscribers: jsonArr });
        }
      }
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // subscriberChange = selected => {
  //   console.log(selected.target.value)
  //   this.setState({ subscriber_id: selected.target.value });
  // };

  onSubmit(e) {
    e.preventDefault();

    const regFormInputes = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      title: this.state.title,
      type: this.state.type,
      device_id: this.state.device_id,
      subscription_code: this.state.subscription_code,
      //expiry_date: this.state.expiry_date,
      re_newal_interval: this.state.re_newal_interval,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    };

    //console.log(" submit regFormInputes ", regFormInputes);

    SubscriptionAddNew(regFormInputes).then(res => {
      if (res) {
        //this.props.history.push(`/profile`);
        //console.log('on submit data',res); value={this.state.SupportMember_name}
        //this.setState({ SupportMember_name: "", short_code: "" });

        this.onToastItemAdd(res.status, res.message);
        if (res.status === "success") {
          SubscriptionGetAll({
            user_token: localStorage.apiAuthAccessKey,
            user_token_id: localStorage.apiAuthAccessId
          }).then(res => {
            if (res) {
              this.setState({ allDataRows: res });
              this.setState({ filteredDataRows: res });
            }
          });
        }
      }
    });
  }

  handleOnDelete = itemObject => {
    const newFiltedItems = this.state.filteredDataRows.filter(
      c => c.autoid !== itemObject.autoid
    );

    const newAllItems = this.state.allDataRows.filter(
      c => c.autoid !== itemObject.autoid
    );

    this.setState({ allDataRows: newAllItems });
    this.setState({ filteredDataRows: newFiltedItems });

    // console.log("handleOnDelete fired, Deleted Success",itemObject);

    SubscriptionStatusUpdate(itemObject).then(res => {
      if (res) {
        this.onToastItemAdd(res.status, res.message);
      }
    });
  };

  handleVehicleAssign= itemObject => {
    
    SubscriptionAssignToVehicle(itemObject).then(res=>
      {
        if(res)
        {
          this.onToastItemAdd(res.status, res.message);
        }
      })
  }

  handleOnUpdate = itemObject => {
    SubscriptionUpdate(itemObject).then(res => {
      if (res) {
        //this.props.history.push(`/profile`);
        //console.log('on submit data',res);
        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          SubscriptionGetAll(this.state).then(res => {
            if (Array.isArray(res)) {
              this.setState({ allDataRows: res });
              this.setState({ filteredDataRows: res });
            } else {
              // AuthHelperAccessCheck(res);
            }
          });
        }
      }
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    let showDataRows = this.state.filteredDataRows;

    return (
      <React.Fragment>
        <Navbar />
        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <h1> Subscription </h1>
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />
                  <div className="col-md-6 col-12">
                    <div className="card mb-1">
                      <div className="card-header">
                        <h4 className="card-title"> Form</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <form className="form" onSubmit={this.onSubmit}>
                            <div className="form-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>Subscriber</label>
                                    <div className="position-relative has-icon-left">
                                      <SubscriberSelect
                                        name="subscriber_id"
                                        value={this.state.subscriber_id}
                                        onChange={this.onChange}
                                      />

                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>Title</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        value={this.state.title}
                                        onChange={this.onChange}
                                        required
                                      />
                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>Device Id</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="device_id"
                                        value={this.state.device_id}
                                        onChange={this.onChange}
                                        required
                                      />
                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label>Type</label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="type"
                                        onChange={this.onChange}
                                        value={this.state.type}
                                      >
                                        <option value=""> Select </option>
                                        <option value="1">Tracking</option>
                                        <option value="2">Other</option>
                                      </select>
                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label>Re-Newal Interval</label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="re_newal_interval"
                                        onChange={this.onChange}
                                        value={this.state.re_newal_interval}
                                      >
                                        <option value="0"> Select </option>
                                        <option value="30">1 Month</option>
                                        <option value="90">3 Month</option>
                                        <option value="365">1 Year</option>
                                        <option value="730">2 Year</option>
                                      </select>

                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="form-actions right">
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                >
                                  <i className="ft-x" /> Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-check-square-o" /> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="card">
                      <div className="card-header">
                        <input
                          type="text"
                          className="card-search float-right"
                          placeholder="Search... "
                          onChange={this.searchHandler}
                        />
                        <h4 className="card-title"> List</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <div className="filterlist">
                            <ul>
                              {showDataRows.map(el => {
                                return (
                                  <SubscriptionItem
                                    key={el.autoid}
                                    itemObject={el}
                                    onDelete={this.handleOnDelete}
                                    onUpdate={this.handleOnUpdate}
                                    onAssign= {this.handleVehicleAssign}
                                    subscribers={this.state.subscribers}
                                  />
                                );
                              })}
                            </ul>
                            <center>
                              {showDataRows.length
                                ? ""
                                : "No records available"}
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default Subscription;
