import React, { Component } from "react";
import Navbar from "../compon-styled/Navbar";
import jwt_decode from "jwt-decode";

import {
  VehicleControlSelectByVehicle,
  VehicleControlUpdatecontrol
} from "../businessjs/VehicleFn";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import ToastAlert from "./ToastAlert";

class VehicleControlItem extends React.Component {
  constructor(props) {
    super(props);

    let newValue = this.props.itemObject.new_value;

    if (this.props.itemObject.type_code === 13) {
      switch (newValue) {
        case "Driver RFID":
          newValue = "1";
          break;
        default:
          newValue = "0";
          break;
      }
    }

    this.state = {
      txt_newvalue: newValue
    };
  }

  ChangeImmob = e => {
    e.preventDefault();
    let itemObject = this.props.itemObject;

    let newValue = itemObject.new_value;

    if (itemObject.type_code === 1) {
      if (itemObject.new_value === "OFF") {
        newValue = "ON";
      } else {
        newValue = "OFF";
      }
    }

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,IMM," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeOverspeed = e => {
    e.preventDefault();
    let itemObject = this.props.itemObject;
    let newValue = this.state.txt_newvalue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,OV," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeExpiry = e => {
    e.preventDefault();
    let itemObject = this.props.itemObject;
    let newValue = this.state.txt_newvalue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,EXP," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeFuelTankSize = e => {
    e.preventDefault();
    let itemObject = this.props.itemObject;
    let newValue = this.state.txt_newvalue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,FUL," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeAPN = e => {
    e.preventDefault();

    if (this.state.txt_newvalue.length > 30) {
      console.log("Error len"  );
      return;
    }

    let itemObject = this.props.itemObject;
    let newValue = this.state.txt_newvalue.trim() ;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,APN," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    //console.log('apn ', regFormInputes);
    this.props.actionUpdate(regFormInputes);
  };

  ChangeCorneringAngle = e => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txt_newvalue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,CA," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeCorneringSpeed = e => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txt_newvalue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,CS," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeHardBreaking = e => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txt_newvalue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,HB," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeHardAcceleration = e => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txt_newvalue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,HA," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeIdelingMinutes = e => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txt_newvalue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,IDL," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeIdelingBuzzer = e => {
    e.preventDefault();
    let itemObject = this.props.itemObject;

    let newValue = itemObject.new_value;

    if (itemObject.type_code === 1) {
      if (itemObject.new_value === "OFF") {
        newValue = "ON";
      } else {
        newValue = "OFF";
      }
    }

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,BZID," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeAlertBuzzer = e => {
    e.preventDefault();
    let itemObject = this.props.itemObject;

    let newValue = itemObject.new_value;

    if (itemObject.type_code === 1) {
      if (itemObject.new_value === "OFF") {
        newValue = "ON";
      } else {
        newValue = "OFF";
      }
    }

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,BZ," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangePheripheral = e => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txt_newvalue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue === "1" ? "RFID Driver" : "NONE",
      command: "$SET,PORT,1," + newValue + ",9600,#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    // console.log("change ", regFormInputes);

     this.props.actionUpdate(regFormInputes);
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toDateString = baseString => {
    baseString = "20" + baseString;
    baseString = baseString.substr(0, 4) + "-" + baseString.substr(4);
    baseString = baseString.substr(0, 7) + "-" + baseString.substr(7);
    baseString = baseString.substr(0, 10) + " " + baseString.substr(10);
    baseString = baseString.substr(0, 13) + ":" + baseString.substr(13);

    return baseString;
    //    return baseString.substr(0, index) + string + baseString.substr(index);
  };

  statusText(statuscode) {
    if (statuscode === 1) {
      return "Pending";
    } else if (statuscode === 2) {
      return "Active";
    } else {
      return "Cancelled";
    }
  }

  formateButton(Item) {
    if (Item.type_code === 1) {
      return (
        <button
          onClick={this.ChangeImmob}
          className="btn btn-outline-secondary btn-sm"
        >
          {Item.new_value === "OFF" ? "ON" : "OFF"}
        </button>
      );
    } else if (Item.type_code === 2) {
      return (
        <div className="btn-group">
          <input
            type="text"
            name="txt_newvalue"
            onChange={this.onChange}
            value={this.state.txt_newvalue}
            style={{ width: "70px" }}
          />

          <button
            onClick={this.ChangeOverspeed}
            className="btn btn-outline-secondary btn-sm"
          >
            Update
          </button>
        </div>
      );
    } else if (Item.type_code === 3) {
      return (
        <div className="btn-group">
          {this.toDateString(this.state.txt_newvalue)}
        </div>
      );
    } else if (Item.type_code === 4) {
      return (
        <div className="btn-group">
          <input
            type="text"
            name="txt_newvalue"
            onChange={this.onChange}
            value={this.state.txt_newvalue}
            style={{ width: "70px" }}
          />
          <button
            onClick={this.ChangeFuelTankSize}
            className="btn btn-outline-secondary btn-sm"
          >
            Update
          </button>
        </div>
      );
    } else if (Item.type_code === 5) {
      return ;
    } else if (Item.type_code === 6) {
      return (
        <div className="btn-group">
          <input
            type="text"
            name="txt_newvalue"
            onChange={this.onChange}
            value={this.state.txt_newvalue}
            style={{ width: "70px" }}
          />
          <button
            onClick={this.ChangeCorneringAngle}
            className="btn btn-outline-secondary btn-sm"
          >
            Update
          </button>
        </div>
      );
    } else if (Item.type_code === 7) {
      return (
        <div className="btn-group">
          <input
            type="text"
            name="txt_newvalue"
            onChange={this.onChange}
            value={this.state.txt_newvalue}
            style={{ width: "70px" }}
          />
          <button
            onClick={this.ChangeCorneringSpeed}
            className="btn btn-outline-secondary btn-sm"
          >
            Update
          </button>
        </div>
      );
    } else if (Item.type_code === 8) {
      return (
        <div className="btn-group">
          <input
            type="text"
            name="txt_newvalue"
            onChange={this.onChange}
            value={this.state.txt_newvalue}
            style={{ width: "70px" }}
          />
          <button
            onClick={this.ChangeHardBreaking}
            className="btn btn-outline-secondary btn-sm"
          >
            Update
          </button>
        </div>
      );
    } else if (Item.type_code === 9) {
      return (
        <div className="btn-group">
          <input
            type="text"
            name="txt_newvalue"
            onChange={this.onChange}
            value={this.state.txt_newvalue}
            style={{ width: "70px" }}
          />
          <button
            onClick={this.ChangeHardAcceleration}
            className="btn btn-outline-secondary btn-sm"
          >
            Update
          </button>
        </div>
      );
    } else if (Item.type_code === 10) {
      return (
        <div className="btn-group">
          <input
            type="text"
            name="txt_newvalue"
            onChange={this.onChange}
            value={this.state.txt_newvalue}
            style={{ width: "70px" }}
          />
          <button
            onClick={this.ChangeIdelingMinutes}
            className="btn btn-outline-secondary btn-sm"
          >
            Update
          </button>
        </div>
      );
    } else if (Item.type_code === 11) {
      return (
        <button
          onClick={this.ChangeIdelingBuzzer}
          className="btn btn-outline-secondary btn-sm"
        >
          {Item.new_value === "OFF" ? "ON" : "OFF"}
        </button>
      );
    } else if (Item.type_code === 12) {
      return (
        <button
          onClick={this.ChangeAlertBuzzer}
          className="btn btn-outline-secondary btn-sm"
        >
          {Item.new_value === "OFF" ? "ON" : "OFF"}
        </button>
      );
    } else if (Item.type_code === 13) {
      return (
        <div className="btn-group">
          <select
            name="txt_newvalue"
            className="btn btn-outline-secondary btn-sm"
            onChange={this.onChange}
            value={this.state.txt_newvalue}
          >
            <option value="0">NONE</option>
            <option value="1">Driver RFID</option>
          </select>
          <button
            onClick={this.ChangePheripheral}
            className="btn btn-outline-secondary btn-sm"
          >
            Update
          </button>
        </div>
      );
    }
  }

  render() {
    let Item = this.props.itemObject;
    let i = this.props.index;
    return (
      <tr>
        <th scope="row">{i + 1}</th>
        <td>{Item.type_text} </td>
        <td>{Item.new_value} </td>
        <td> {this.statusText(Item.statuscode)} </td>
        <td>{this.formateButton(Item)}</td>
      </tr>
    );
  }
}
class VehicleControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemObject: this.props.location.state.itemObject,
      btnRefreshText: "Refresh",
      allDataRows: [],
      toastItems: []
    };
  }

  componentDidMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({ subscriber_id: decodedUser.subscriber_id });

    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      vehicle_id: this.state.itemObject.autoid
    };

    VehicleControlSelectByVehicle(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allDataRows: res });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  }

  updateControls = params => {
    VehicleControlUpdatecontrol(params).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);

        this.onToastItemAdd(res.status, res.message);

        const fnInputes = {
          user_token: localStorage.apiAuthAccessKey,
          user_token_id: localStorage.apiAuthAccessId,
          vehicle_id: this.state.itemObject.autoid
        };

        VehicleControlSelectByVehicle(fnInputes).then(res => {
          if (res) {
            if (Array.isArray(res)) {
              this.setState({ allDataRows: res });
            } else {
              AuthHelperAccessCheck(res);
            }
          }
        });
      }
    });
  };

  Refresh = () => {
    this.setState({ btnRefreshText: "......." });
    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      vehicle_id: this.state.itemObject.autoid
    };

    VehicleControlSelectByVehicle(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allDataRows: res });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
      this.setState({ btnRefreshText: "Refresh" });
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <ToastAlert
          toastItems={this.state.toastItems}
          onItemClose={this.handleToastItemClose}
        />

        <main role="main">
          <section className="manage-colom">
            <div className="container" style={{ width: "100%" }}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h1>
                    Vehicle Controls - {this.state.itemObject.title} #
                    {this.state.itemObject.autoid}
                  </h1>

                  <div>
                    <div>
                      <div>
                        <div className="form-body">
                          <div className="col-12">
                            <button
                              className="btn float-right"
                              onClick={this.Refresh}
                            >
                              {this.state.btnRefreshText}
                            </button>
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">value</th>
                                  <th scope="col">Status </th>
                                  <th scope="col">Action </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.allDataRows.map((Item, i) => (
                                  <React.Fragment key={i}>
                                    <VehicleControlItem
                                      itemObject={Item}
                                      index={i}
                                      actionUpdate={this.updateControls}
                                    />
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default VehicleControls;
