import axios from "axios";
import Api from "./APIConfig"; 

const proxyServer = Api.serverapi + "appauthtoken";

export const AppAuthTokenCheck = objItem => {
    return axios
      .post(proxyServer + "/check", { objItem })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };
