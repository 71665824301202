import React, { Component } from "react";
import {  Route } from "react-router-dom";
import moment from "moment-timezone";

class AlertCount extends Component {
  formatLiveButton(itemObject, path, title, imgPath) {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <React.Fragment>
            <button
              type="button"
              className="nobutton w-100"
              onClick={() => {
                history.push({
                  pathname: path,
                  state: { itemObject: itemObject }
                });
              }}
            >
              <div className="tile-icon">
                <h1>
                  <img src={imgPath} alt="icon" />
                </h1>
              </div>
              <div className="tile-head">
                <center>{title}</center>
              </div>
            </button>
          </React.Fragment>
        )}
      />
    );

    return <ButtonLive />;
  }

  render() {
    this.itemObject = null;

    if (this.props && this.props.dataSource) {
      this.accelerationCount = this.props.dataSource.accelerationCount;
      this.brakingCount = this.props.dataSource.brakingCount;
      this.corneringCount = this.props.dataSource.corneringCount;
      this.overSpeedCount = this.props.dataSource.overSpeedCount;
      this.POICount = this.props.dataSource.POICount;
      this.idlingCount = this.props.dataSource.idlingCount;
      this.startDate = moment(this.props.dataSource.startDate)
        .local()
        .format("DD MMM YYYY");
      this.endDate = moment(this.props.dataSource.endDate)
        .local()
        .format("DD MMM YYYY"); 
      this.itemObject = {
        vehicle_id: this.props.dataSource.vehicle_id,
        subscriber_id: this.props.dataSource.subscriber_id,
        startDate: this.props.dataSource.startDate,
        endDate: this.props.dataSource.endDate
      };
    }
    
    return (
      <div>
        {/* <div>
          <h5 className="border-bottom border-gray pb-2 mb-3">
            Alert Count : {this.startDate} - {this.endDate}
          </h5>
        </div> */}

        <div className="row tile-colom">
          <div />
          <div className="col-lg-2">
            <div className="tile-one">
              <div className="tile-head">
                <center>{this.accelerationCount}</center>
              </div>
              {this.formatLiveButton(
                this.itemObject,
                "/acceleration-alerts",
                "Acceleration",
                "images/acceleration.png"
              )}
            </div>
          </div>
          <div className="col-lg-2">
            <div className="tile-one">
              <div className="tile-head">
                <center>{this.brakingCount}</center>
              </div>
              {this.formatLiveButton(
                this.itemObject,
                "/braking-alerts",
                "Braking",
                "images/breaking.png"
              )}
            </div>
          </div>
          <div className="col-lg-2">
            <div className="tile-one">
              <div className="tile-head">
                <center>{this.corneringCount}</center>
              </div>
              {this.formatLiveButton(
                this.itemObject,
                "/cornering-alerts",
                "Cornering",
                "images/cornering.png"
              )}
            </div>
          </div>

          <div className="col-lg-2">
            <div className="tile-one">
              <div className="tile-head">
                <center>{this.overSpeedCount}</center>
              </div>

              {this.formatLiveButton(
                this.itemObject,
                "/overspeed-alerts",
                "Overspeed",
                "images/overspeed.png"
              )}
            </div>
          </div>
          <div className="col-lg-2">
            <div className="tile-one">
              <div className="tile-head">
                <center>{this.POICount}</center>
              </div>
              {this.formatLiveButton(
                this.itemObject,
                "/poi-alerts",
                "POI",
                "images/poi.png"
              )}
            </div>
          </div>
          <div className="col-lg-2">
            <div className="tile-one">
              <div className="tile-head">
                <center>{this.idlingCount}</center>
              </div>
              {this.formatLiveButton(
                this.itemObject,
                "/idling-alerts",
                "Idling",
                "images/idling.png"
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlertCount;
