import React from "react";
import { VehicleSelectByRole } from "../businessjs/VehicleFn";
import { LastupdateSelectBySubscriber } from "../businessjs/LastupdateFn";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import jwt_decode from "jwt-decode";
import { Route } from "react-router-dom";
import {
  GroupSelectBySubscriber,
  GroupVehicleSelectBySubscriber
} from "../businessjs/VehicleGroupFn";
import Navbar from "../compon-styled/Navbar";
import CmbSelect from "../compon-controls/CmbSelect";

class VehicleList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allDataRows: [],
      filteredDataRows: [],
      allGroupedVehicles: [],
      lastupdateRows: [],
      toastItems: [],
      subscriber_id: 0,
      user_role: 3,
      searchTex: "",
      group_name: undefined,
      groupDataRows: [],
      executive_id:0
    };
    this.intervalId = null;
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  componentDidMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({ subscriber_id: decodedUser.subscriber_id,executive_id:decodedUser.autoid });

    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      subscriber_id: decodedUser.subscriber_id,
      executive_id:decodedUser.autoid
    };

    console.log(" VehicleSelectByRole ", fnInputes );

    VehicleSelectByRole(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allDataRows: res });
          this.setState({ filteredDataRows: res });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });

    GroupSelectBySubscriber(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({ label: opt.group_name, value: opt }));
          dbArray.splice(0, 0, { label: "Select Group", value: { autoid: 0 } });
          this.setState({ groupDataRows: dbArray });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });

    GroupVehicleSelectBySubscriber(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allGroupedVehicles: res });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });

    this.intervalId = setInterval(this.getLastUpdate, 3000);
  }

  getLastUpdate = () => {
    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      subscriber_id: this.state.subscriber_id
    };
    LastupdateSelectBySubscriber(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ lastupdateRows: res });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
    // clearInterval(this.intervalId);
  };

  // onChange = e => {
  //   this.setState({ [e.target.name]: e.target.value });
  // };

  groupChange = e => {
    let groupId = e.target.value.value.autoid;
    if (groupId !== 0) {
      let groupvehicles = this.state.allGroupedVehicles.filter(
        row => row.group_id === groupId
      );

      let resultVehicles = [];
      let itemVehicle;
      groupvehicles.forEach(gv_element => {
        itemVehicle = this.state.allDataRows.filter(
          row => row.autoid === gv_element.vehicle_id
        );
        resultVehicles.push(itemVehicle[0]);
      });

      this.setState({ filteredDataRows: resultVehicles });
    } else this.setState({ filteredDataRows: this.state.allDataRows });
  };

  clearFilter = () => {
    this.setState({
      filteredDataRows: this.state.allDataRows,
      searchTex: "",
      group_name: { label: "Select Group", value: { autoid: 0 } }
    });
  };

  filterVehicleType = type => {
    const result = this.state.allDataRows.filter(row => row.vtype === type);
    this.setState({ filteredDataRows: result });
  };

  filterLastupdatefForVehicle = (vehicle_id, lastupdateRows) => {
    const result = lastupdateRows.filter(row => row.vehicle_id === vehicle_id);

    if (Array.isArray(result) && result.length) {
      return (
        <div className="w-100">
          <span className="float-right">
            {result[0].ignition === 1 ? "On" : "Off"}
          </span>
          <span className="ellipsis" title={result[0].driver_name}>
            {" "}
            &nbsp;{" "}
            {result[0].driver_name && result[0].driver_name.length > 17
              ? result[0].driver_name.substring(0, 15) + "..."
              : result[0].driver_name}{" "}
          </span>
        </div>
      );
    } else {
      if (lastupdateRows.length) {
        return (
          <div className="w-100">
            <span className="float-right">Offline</span>

            <span className="ellipsis"> &nbsp; </span>
          </div>
        );
      } else {
        return (
          <div className="w-100">
            <span className="float-right"> &nbsp;</span>

            <span className="ellipsis" />
          </div>
        );
      }
    }
  };

  searchHandler = event => {
    let searcjQery = event.target.value.toLowerCase();
    let arrData = searcjQery.split(" ");
    let tempDataRows = this.state.allDataRows;
    let filteredDataRows = this.state.allDataRows;
    arrData.forEach(element => {
      filteredDataRows = tempDataRows.filter(el => {
        return (
          el.title.toLowerCase().indexOf(element) !== -1 ||
          el.plate_number.toLowerCase().indexOf(element) !== -1 ||
          el.fleet_number.toLowerCase().indexOf(element) !== -1 ||
          el.manufacture_name.toLowerCase().indexOf(element) !== -1 ||
          el.model.toLowerCase().indexOf(element) !== -1
        );
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredDataRows: filteredDataRows,
      searchTex: searcjQery
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  formatLiveButton(itemObject) {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <React.Fragment>
            <button
              type="button"
              className="nobutton w-100"
              onClick={() => {
                history.push({
                  pathname: "/view-vehicle",
                  state: { itemObject: itemObject }
                });
              }}
            >
              <span className="text-dark">
                {itemObject.manufacture_name} &nbsp; {itemObject.model}
              </span>

              <center>
                <div className="tile-icon">
                  <h1>
                    <img
                      src={
                        "images/" + itemObject.imagepath.toLowerCase() + ".png"
                      }
                      alt="icon"
                    />
                  </h1>
                </div>
              </center>
              <span className="float-right text-dark">
                Plate: {itemObject.plate_number}
              </span>
              <span className="text-dark">
                Fleet: {itemObject.fleet_number}
              </span>
            </button>
          </React.Fragment>
        )}
      />
    );

    return <ButtonLive />;
  }

  render() {
    let showDataRows = this.state.filteredDataRows;

    let displayVehicleList = showDataRows.filter(function(a) {
      if (!this[a.plate_number]) {
        this[a.plate_number] = true;
        return true;
      }
      return false;
    }, Object.create(null));

    return (
      <React.Fragment>
        <Navbar />

        <section className="manage-colom">
          <div className="container">
            <div className="row" />
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-2">
                    <h1> Vehicles</h1>
                  </div>
                  <div className="col-lg-10">
                    <div className="btn-group float-right">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary ml-2"
                        onClick={this.clearFilter}
                      >
                        Clear
                      </button>

                      <input
                        type="text"
                        placeholder="Search"
                        onChange={this.searchHandler}
                        value={this.state.searchTex}
                      />

                      <div style={{ width: "200px" }}>
                        <CmbSelect
                          name="group_name"
                          value={this.state.group_name}
                          onChange={this.groupChange}
                          dataSource={this.state.groupDataRows}
                        />
                      </div>

                      <button
                        type="button"
                        title="Car"
                        className="btn btn-sm btn-primary"
                        onClick={() => this.filterVehicleType("Car")}
                      >
                        <img
                          src="/images/car-white.png"
                          alt="icon"
                          className="btnicon"
                        />
                      </button>
                      <button
                        type="button"
                        title="Bus"
                        className="btn btn-sm btn-primary"
                        onClick={() => this.filterVehicleType("Bus")}
                      >
                        <img
                          src="/images/bus-white.png"
                          alt="icon"
                          className="btnicon"
                        />
                      </button>

                      <button
                        type="button"
                        title="SUV"
                        className="btn btn-sm btn-primary"
                        onClick={() => this.filterVehicleType("SUV")}
                      >
                        <img
                          src="/images/suv-white.png"
                          alt="icon"
                          className="btnicon"
                        />
                      </button>

                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        title="Trailer Truck"
                        onClick={() => this.filterVehicleType("Truck")}
                      >
                        <img
                          src="/images/trailertruck-white.png"
                          alt="icon"
                          className="btnicon"
                        />
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        title="Pickup"
                        onClick={() => this.filterVehicleType("Pickup")}
                      >
                        <img
                          src="/images/pickup-white.png"
                          alt="icon"
                          className="btnicon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <br />

                <div className="row tile-colom">
                  {displayVehicleList.map((Item, i) => (
                    <React.Fragment key={i}>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div className="tile-one">
                          {this.formatLiveButton(Item)}
                          {this.filterLastupdatefForVehicle(
                            Item.autoid,
                            this.state.lastupdateRows
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                  <center>
                    {showDataRows.length ? "" : "No vehicles available"}
                  </center>
                </div>
              </div>
              Showing {displayVehicleList.length} of &nbsp;
              {showDataRows.length}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default VehicleList;
