import React, { Component } from "react";
import Navbar from "../compon-styled/Navbar";
import { Link } from "react-router-dom";

class AlertHome extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navbar />

        <section className="manage-colom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1>Alerts</h1>
                <div className="row tile-colom">
                  <div />
                  <div className="col-lg-3">
                    <Link to="/acceleration-alerts">
                      <div className="tile-one">
                        <div className="tile-icon">
                          <h1>
                            <img src="images/acceleration.png" alt="icon" />
                          </h1>
                        </div>
                        <div className="tile-head">
                          <center>Acceleration</center>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                    <Link to="braking-alerts">
                      <div className="tile-one">
                        <div className="tile-icon">
                          <h1>
                            <img src="images/breaking.png" alt="icon" />
                          </h1>
                        </div>
                        <div className="tile-head">
                          <center>Braking</center>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                    <Link to="cornering-alerts">
                      <div className="tile-one">
                        <div className="tile-icon">
                          <h1>
                            <img src="images/cornering.png" alt="icon" />
                          </h1>
                        </div>
                        <div className="tile-head">
                          <center>Cornering</center>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                    <Link to="ignition-alerts">
                      <div className="tile-one">
                        <div className="tile-icon">
                          <h1>
                            <img src="images/ignition.png" alt="icon" />
                          </h1>
                        </div>
                        <div className="tile-head">
                          <center>Ignition</center>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                    <Link to="overspeed-alerts">
                      <div className="tile-one">
                        <div className="tile-icon">
                          <h1>
                            <img src="images/overspeed.png" alt="icon" />
                          </h1>
                        </div>
                        <div className="tile-head">
                          <center>Overspeed</center>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-lg-3">
                    <Link to="idling-alerts">
                      <div className="tile-one">
                        <div className="tile-icon">
                          <h1>
                            <img src="images/idling.png" alt="icon" />
                          </h1>
                        </div>
                        <div className="tile-head">
                          <center>Idling</center>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AlertHome;
