
import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi ;
//const proxyServer = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_SERVER_PROD : process.env.REACT_APP_SERVER_LOCAL

export const SpeedChartData = objItem => {
  
    return axios
      .post(proxyServer + "chart/getspeedchartdata", { objItem } )
      .then(res => {
        //localStorage.setItem('usertoken', res.data);
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };
  
  export const FuelChartData = objItem => {
    return axios
      .post(proxyServer + "chart/getfuelchartdata", { objItem } )
      .then(res => {
        //localStorage.setItem('usertoken', res.data);
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };