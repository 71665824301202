import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "changeemail";

export const ExecutiveChangeEmail = objItem => { 
  return axios
    .post(proxyServer + "/changeEmail", { objItem })
    .then(res => {      
      return res.data;
    })
    .catch(err => { 
      return err;
    });
};




