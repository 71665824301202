import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "vehicle";
const proxyServer2 = Api.serverapi + "2vehicle";

export const VehicleAddNew = objItem => {
  return axios
    .post(proxyServer + "/new", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleUpdate = objItem => {
  return axios
    .post(proxyServer + "/update", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleStatusUpdate = objItem => {
  return axios
    .post(proxyServer + "/statusUpdate", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleSelectBySubscriber = objItem => {
  return axios
    .post(proxyServer + "/getbysubscriber", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleGetAll = objItem => {
  return axios
    .post(proxyServer + "/getAll", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleUnAssigned = objItem => {
  return axios
    .post(proxyServer + "/getUnAssigned", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleTypes = objItem => {
  return axios
    .post(proxyServer + "/getvehicletypes", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleForCombo = objItem => {
  return axios
    .post(proxyServer + "/getVehiclesForCombo", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleSelectById = objItem => {
  return axios
    .post(proxyServer + "/getVehicleById", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleControlSelectByVehicle = objItem => {
  return axios
    .post(proxyServer + "/getcontrolsbyvehicle", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleControlUpdatecontrol = objItem => {
  return axios
    .post(proxyServer + "/updatecontrol", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


export const VehicleControlUpdateControlMuiltiple = objItem => {
  return axios
    .post(proxyServer + "/updatecontrolmuiltiple", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleCountById = objItem => {
  return axios
    .post(proxyServer + "/getvehiclecountbyid", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GetVehicleListById = objItem => {
  return axios
    .post(proxyServer + "/getvehiclelistbyid", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const SubscriberTypes = objItem => {
  return axios
    .post(proxyServer + "/getsubscribertypes", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleSelectByRole = objItem => {
  return axios
    .post(proxyServer + "/getbyrole", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


// ======================


export const VehicleGetAll2 = objItem => {
  return axios
    .post(proxyServer2 + "/getAll", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
