import React, { Component } from "react";
import Navbar from "../compon-styled/Navbar";
import Pagination from "react-js-pagination";
import Datetime from "react-datetime";
import { Route } from "react-router-dom";
import {
  VehcileUtilizationCount,
  VehicleUtilizationList
} from "../businessjs/AlertFn";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import jwt_decode from "jwt-decode";
// import { now } from "moment";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class VehicleUtilization extends Component {
  constructor() {
    super();
    let startDay = new Date();
    let endDay = new Date();

    this.state = {
      allDataRows: [],
      toastItems: [],
      subscriber_id: 0,
      activePage: undefined,

      itemsCountPerPage: 25,
      totalItemsCount: 0,
      startDate: startDay,
      endDate: endDay,
      loading: false
    };
  }
  componentWillMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({ subscriber_id: decodedUser.subscriber_id ,executive_id:decodedUser.autoid});
  }

  componentDidMount() {
     this.getVehcileUtilizationCount();
  }

  getVehcileUtilizationCount = () => {
    this.setState({ loading: true });

    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      executive_id:this.state.executive_id
    };

    VehcileUtilizationCount(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let count = res[0].count;
          if (count > 0) {
            this.setState({ totalItemsCount: count, activePage: 1 });
            this.getVehcileUtilizationList(1);
          } else {
            this.setState({
              allDataRows: [],
              totalItemsCount: 0,
              activePage: 1,
              loading: false
            });
          }
        } else {
          this.setState({ loading: false });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getVehcileUtilizationList = pageNumber => {
    let start = (pageNumber - 1) * this.state.itemsCountPerPage;
    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      startLimit: start,
      endLimit: this.state.itemsCountPerPage,
      executive_id:this.state.executive_id
    };
    VehicleUtilizationList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allDataRows: res, loading: false });

          if (!this.state.activePage) {
            this.setState({ totalItemsCount: res.length, activePage: 1 });
          } else {
          }
        } else {
          this.setState({ loading: false });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  handlePageChange = pageNumber => {
    this.setState({
      allDataRows: [],
      activePage: pageNumber
    });
    this.getVehcileUtilizationList(pageNumber);
  };

  onStartDateChange = e => {
    this.setState({ startDate: e._d });
  };

  onEndDateChange = e => {
    this.setState({ endDate: e._d });
  };

  exportToExceldButton = () => {
    const ButtonExport = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-primary btn-sm ml-3 fa fa-file-excel-o"
            onClick={() => {
              history.push({
                pathname: "/exporttoexcel",
                state: {
                  startDate: this.state.startDate,
                  endDate: this.state.endDate,
                  totalItemsCount: this.state.totalItemsCount,
                  name: "Vehicle Utilization",
                  type: "vehicleUtilization"
                }
              });
            }}
          />
        )}
      />
    );
    return <ButtonExport />;
  };

  render() {
    let showDataRows = this.state.allDataRows;
    return (
      <React.Fragment>
        <Navbar />
        <section className="manage-colom">
          <div className="container">
            <main role="main">
              <section>
                <div className="mt-4" style={{ width: "100%" }}>
                  <h2> Vehicle Utilization </h2>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <div className="row">
                            <label className="mr-3">From Time :</label>
                            <Datetime
                              defaultValue={this.state.startDate}
                              input={true}
                              closeOnSelect={true}
                              onChange={this.onStartDateChange}
                            />
                            <label className="ml-3">To Time :</label>
                            <Datetime
                              defaultValue={this.state.endDate}
                              // value={this.state.endDate}
                              input={true}
                              closeOnSelect={true}
                              onChange={this.onEndDateChange}
                              className="ml-3"
                            />

                            <button
                              type="submit"
                              className="btn btn-primary btn-sm ml-3"
                              onClick={this.getVehcileUtilizationCount}
                            >
                              {" "}
                              Submit
                            </button>
                            {this.exportToExceldButton()}
                            <div className="sweet-loading ml-2">
                              <RiseLoader
                                css={loaderCSS}
                                sizeUnit={"px"}
                                size={10}
                                color={"#123abc"}
                                loading={this.state.loading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* card */}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="card">
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Vehicle</th>
                                <th>Used Day Count </th>
                                <th> Running Duration(h:m:s)</th>
                                <th>Idle Duration(h:m:s)</th>
                                <th>Total Distance(km)</th>
                                <th> Total Duration(h:m:s)</th>
                                <th>Duration/Day</th>
                                <th>Distance/Day</th>
                              </tr>
                            </thead>
                            <tbody>
                              {showDataRows.map((Item, i) => (
                                <React.Fragment key={i}>
                                  <tr>
                                    <td>{Item.title}</td>
                                    <td>{Item.count}</td>
                                    <td>{Item.driving_time}</td>
                                    <td>{Item.idle_time}</td>
                                    <td>{Item.distance}</td>
                                    <td>{Item.totalDuration}</td>
                                    <td>{Item.durationPerDay}</td>
                                    <td>{Item.distancePerDay}</td>
                                  </tr>
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                          <center>
                            {showDataRows.length ? "" : "No vehicles available"}
                          </center>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12" style={{ textAlign: "right" }}>
                          <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={10}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default VehicleUtilization;
