import React from "react";
import Navbar from "../compon-styled/Navbar";

import { VehicleSelectById } from "../businessjs/VehicleFn";
import jwt_decode from "jwt-decode";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import AlertCount from "../compon-controls/AlertCount";

// import { WeeklyAlertCount } from "../businessjs/AlertFn";
import ViewIgnitionAlert from "./ViewIgnitionAlerts";

import { Route } from "react-router-dom";

class ViewVehicle extends React.Component {
  constructor(props) {
    super(props);

    this.vehicleDetails = {
      autoid: "",
      title: "",
      plateNumber: "null",
      make: "",
      fuel: "",
      image: "defaultimage",
      manufacture_name: "",
      device_id: ""
    };

    this.alertCounts = {
      accelerationCount: null,
      brakingCount: null,
      corneringCount: null,
      overSpeedCount: null,
      POICount: null,
      idlingCount: null,
      startDate: null,
      endDate: null
    };

    this.ignitionDetails = {
      startDate: null,
      endDate: null,
      showVehicleInfoInGrid: false,
      ignitonList: null
    };

    this.ignition = {
      vehicleTitle: null,
      plateNo: null,
      place: null,
      startLocation: null,
      startTime: null,
      endLocation: null,
      endTime: null,
      tripNo: null,
      mileage: null
    };

    // this.vehicleViewDetails = {
    //   vehicle: this.vehicleDetails,
    //   alert: this.alertCounts,
    //   igniton: this.ignitionDetails
    // };

    let today = new Date();
    let dt = new Date();
    let startDay = new Date(dt.setDate(dt.getDate() - 7));

    this.state = {
      subscriber_id: null,
      vehicle_id: null,
      vehicleDetails: {},

      startDate: startDay,
      endDate: today,
      showPopup: false,
      isOpen: false,
      itemObject: this.props.location.state.itemObject
    };
  }

  componentDidMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({
      subscriber_id: decodedUser.subscriber_id,
      vehicle_id: this.props.location.state.itemObject.autoid
    });

    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      vehicle_id: this.props.location.state.itemObject.autoid
    };

    this.getVehicleDetails(fnInputes);

    /*
    const wkInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      vehicle_id: this.props.location.state.itemObject.autoid,
      subscriber_id: decodedUser.subscriber_id,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };

    this.getWeeklyAlertCount(wkInputes);
    */
  }

  getVehicleDetails = fnInputes => {
    VehicleSelectById(fnInputes).then(res => {
      // console.log("getVehicleDetails res ", res);
      if (res) {
        if (Array.isArray(res)) {
          let vehicleDetails = {
            autoid: res[0].autoid,
            title: res[0].title,
            plateNumber: res[0].plate_number,
            fleetNumber: res[0].fleet_number,
            device_id: res[0].device_id,
            make:
              res[0].manufacture_name + " " + res[0].model + " " + res[0].year,
            fuel: res[0].fuel_type,
            image: res[0].imagepath.toLowerCase()
          };

          this.setState({
            vehicleDetails: vehicleDetails
          });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  /*
  getWeeklyAlertCount = fnInputes => {
    WeeklyAlertCount(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let alertDetails = {
            accelerationCount: res[0].acclereationCount,
            brakingCount: res[0].brakingCount,
            corneringCount: res[0].corneringCount,
            overSpeedCount: res[0].speedCount,
            POICount: res[0].poiCount,
            idlingCount: res[0].idlingCount,

            startDate: fnInputes.startDate,
            endDate: fnInputes.endDate,
            subscriber_id: this.state.subscriber_id,
            vehicle_id: this.state.vehicle_id
          };
          this.setState({ alertCounts: alertDetails });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };
  */

  formatVehicleControlsButton(itemObject) {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              history.push({
                pathname: "/vehicle-controls",
                state: { itemObject: itemObject }
              });
            }}
          >
            Vehicle Controls
          </button>
        )}
      />
    );
    return <ButtonLive />;
  }

  formatLiveButton(itemObject) {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              history.push({
                pathname: "/live-tracking",
                state: { itemObject: itemObject }
              });
            }}
          >
            Live
          </button>
        )}
      />
    );
    return <ButtonLive />;
  }

  formatEditButton(itemObject) {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              history.push({
                pathname: "/vehicle-edit",
                state: { itemObject: { vehicle_id: itemObject.autoid } }
              });
            }}
          >
            Edit
          </button>
        )}
      />
    );
    return <ButtonLive />;
  }

  formatHistoryButton(itemObject) {
    let historyData = { autoid: itemObject.autoid, title: itemObject.title };
    const ButtonHistory = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              history.push({
                pathname: "/history-tracking",
                state: { itemObject: historyData }
              });
            }}
          >
            History
          </button>
        )}
      />
    );
    return <ButtonHistory />;
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <main role="main" className="container">
          <div className="d-flex align-items-center p-3 my-3 -50 rounded box-shadow">
            <div className="col-2">
              <img
                className="mr-3"
                alt="icon"
                src={"images/" + this.state.vehicleDetails.image + ".png"}
                style={{ width: "48", height: "48" }}
              />
              <h6 className="mb-0  lh-100">
                {this.state.vehicleDetails.title}
              </h6>
            </div>
            <div className="lh-100 col-3">
              <h6 className="mb-0  lh-100">{`Plate No : ${
                this.state.vehicleDetails.plateNumber
              }`}</h6>
              <h6 className="mb-0  lh-100">{`Fleet No : ${
                this.state.vehicleDetails.fleetNumber
              }`}</h6>
              <h6 className="mb-0  lh-100">
                {`Make : ${this.state.vehicleDetails.make}`}
              </h6>

              <h6 className="mb-0  lh-100">{`Device id : ${
                this.state.vehicleDetails.device_id
              }`}</h6>
            </div>
            <div className="col-3" />
            <div className="col-5" style={{ textAlign: "right" }}>
              <div className="btn-group">
                {this.formatEditButton(this.state.itemObject)}

                 
                {this.formatHistoryButton(this.state.itemObject)}

                {this.formatVehicleControlsButton(this.state.itemObject)}
              </div>
            </div>
          </div>
          <AlertCount dataSource={this.state.alertCounts} />
          <ViewIgnitionAlert
            vehicle_id={this.props.location.state.itemObject.autoid}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            itemObject={this.state.itemObject}
          />
        </main>
      </React.Fragment>
    );
  }
}

export default ViewVehicle;
