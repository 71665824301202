import React from "react";
import jwt_decode from "jwt-decode";
import {
  SubscriberGet,
  SubscriberUpdate,
  SubscribersChangeImage
} from "../businessjs/SubscribersFn";
import ToastAlert from "./ToastAlert";
import { AppHelper_DateToString } from "../businessjs/AppHelperFn";
import {
  AuthHelperAccessCheck,
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberTypeText
} from "../businessjs/AuthHelper";
import Api from "../businessjs/APIConfig";
import Navbar from "./Navbar";

const proxyServer = Api.serverapi;

class SubscriberProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company_name: "",
      company_code: "",
      account_type: "",
      license_number: "",
      default_currency: "",
      country: "",
      shipment_preference: "",
      registration_type: "",
      createdtime: "",
      logo_image: proxyServer + "images/subscriber/no_image.jpg",
      toastItems: []
    };

    this.searchHandler = this.searchHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
  }

  fileChangedHandler(event) {
    const file = event.target.files[0];
    console.log("file change", file);
    this.setState({ selectedFile: event.target.files[0] });
  }

  onChangeImage(e) {
    e.preventDefault();

    const regFormInputes = new FormData();

    regFormInputes.append("user_token", localStorage.apiAuthAccessKey);
    regFormInputes.append("user_token_id", localStorage.apiAuthAccessId);
    regFormInputes.append("autoid", this.state.autoid);

    console.log(" uploaded_image ", this.state.selectedFile);

    if (this.state.selectedFile) {
      regFormInputes.append(
        "subscriber_file",
        this.state.selectedFile,
        this.state.selectedFile.name
      );

      SubscribersChangeImage(regFormInputes).then(res => {
        if (res) {
          this.setState({ selectedFile: undefined });
          this.onToastItemAdd(res.status, res.message);

          if (res.status === "success") {
            const regFormInputes = {
              subscriber_id: this.state.autoid,
              user_token: localStorage.apiAuthAccessKey,
              user_token_id: localStorage.apiAuthAccessId
            };

            SubscriberGet(regFormInputes).then(res => {
              const subscription_item = res[0];

              let tempimage = subscription_item.logo_image;

              if (!tempimage) {
                tempimage = proxyServer + "images/subscriber/no_image.jpg";
              } else {
                tempimage = proxyServer + "images/subscriber/" + tempimage;
              }

              this.setState({
                autoid: subscription_item.autoid,
                company_name: subscription_item.company_name,
                company_code: subscription_item.company_code,
                account_type: subscription_item.account_type,
                license_number: subscription_item.license_number,
                default_currency: subscription_item.default_currency,
                country: subscription_item.country,
                shipment_preference: subscription_item.shipment_preference,
                registration_type: subscription_item.registration_type,
                createdtime: subscription_item.createdtime,
                logo_image: tempimage
              });
            });
          }
        }
      });
    } else {
      this.onToastItemAdd("error", "Please select image");
    }
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      displayedContacts = this.state.allContacts.filter(el => {
        let searchValue = el.name.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      displayedContacts: displayedContacts
    });
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];

      const regFormInputes = {
        subscriber_id: decodedUser.subscriber_id,
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId
      };

      SubscriberGet(regFormInputes).then(res => {
        const subscription_item = res[0];

        let tempimage = subscription_item.logo_image;

        if (!tempimage) {
          tempimage = proxyServer + "images/subscriber/no_image.jpg";
        } else {
          tempimage = proxyServer + "images/subscriber/" + tempimage;
        }

        this.setState({
          autoid: subscription_item.autoid,
          company_name: subscription_item.company_name,
          company_code: subscription_item.company_code,
          account_type: subscription_item.account_type,
          license_number: subscription_item.license_number,
          default_currency: subscription_item.default_currency,
          country: subscription_item.country,
          shipment_preference: subscription_item.shipment_preference,
          registration_type: subscription_item.registration_type,
          createdtime: subscription_item.createdtime,
          logo_image: tempimage
        });
      });
    } else {
      this.props.history.push("/login");
    }
  }

  componentWillUnmount() {}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const regFormInputes = {
      autoid: this.state.autoid,
      company_name: this.state.company_name,
      license_number: this.state.license_number,
      default_currency: this.state.default_currency,
      country: this.state.country,
      shipment_preference: this.state.shipment_preference,
      registration_type: this.state.registration_type,

      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    };

    SubscriberUpdate(regFormInputes).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);
      }
    });
  }

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    //let contacts = this.state.displayedContacts;

    return (
      <React.Fragment>
        <Navbar />

        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />

                  <div className=" col-12">
                    <div className="card mb-1">
                      <div className="card-header">
                        <h4 className="card-title">SubscriberProfile Form</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-4 col-12 mb-5">
                              <img
                                className="card-img-top commodity_img"
                                src={this.state.logo_image}
                                alt="Card cap"
                              />

                              <form onSubmit={this.onChangeImage}>
                                <input
                                  type="file"
                                  name="change_image"
                                  onChange={this.fileChangedHandler}
                                />

                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-check-square-o" /> Save
                                </button>
                              </form>
                            </div>
                          </div>

                          <form className="form" onSubmit={this.onSubmit}>
                            <div className="form-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label htmlFor="short_code">
                                      Company Name
                                    </label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="company_name"
                                        value={this.state.company_name}
                                        onChange={this.onChange}
                                        required
                                      />
                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label htmlFor="SubscriberProfile_name">
                                      Company Code
                                    </label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="SubscriberProfile_name"
                                        disabled
                                        value={this.state.company_code}
                                      />
                                      <div className="form-control-position">
                                        <i className="ft-user" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label htmlFor="short_code">
                                      Account Type
                                    </label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={AuthHelperGerSubscriberTypeText(
                                          this.state.account_type
                                        )}
                                      />

                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label htmlFor="short_code">
                                      License Number
                                    </label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="license_number"
                                        value={this.state.license_number}
                                        onChange={this.onChange}
                                      />
                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label htmlFor="short_code">Currency</label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="default_currency"
                                        value={this.state.default_currency}
                                        onChange={this.onChange}
                                      >
                                        <option value="1">USD</option>
                                        <option value="2">
                                          Canadian dollar
                                        </option>
                                      </select>

                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label htmlFor="short_code">Country</label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="country"
                                        onChange={this.onChange}
                                        value={this.state.country}
                                      >
                                        <option value="1">Canada</option>
                                      </select>

                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label htmlFor="short_code">
                                      Shipment Preference
                                    </label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="shipment_preference"
                                        value={this.state.shipment_preference}
                                        onChange={this.onChange}
                                      >
                                        <option value="-1">Select</option>
                                        <option value="0">Can Provide</option>
                                        <option value="1">
                                          Cannot provide
                                        </option>
                                      </select>

                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label htmlFor="short_code">
                                      Registration{" "}
                                    </label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="registration_type"
                                        onChange={this.onChange}
                                        value={this.state.registration_type}
                                      >
                                        <option value="Unknown">
                                          {" "}
                                          Select{" "}
                                        </option>
                                        <option value="Corporation">
                                          Corporation
                                        </option>
                                        <option value="Partnership">
                                          Partnership
                                        </option>
                                        <option value="Proprietorship">
                                          Proprietorship
                                        </option>
                                      </select>

                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label htmlFor="short_code">
                                      Created Date{" "}
                                    </label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={AppHelper_DateToString(
                                          this.state.createdtime
                                        )}
                                        disabled
                                      />

                                      <div className="form-control-position">
                                        <i className="fa fa-briefcase" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-actions">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-check-square-o" /> Update
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default SubscriberProfile;
