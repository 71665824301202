import React, { Component } from "react";
import Navbar from "../compon-styled/Navbar";
import { Link } from "react-router-dom";

class ChartHome extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Navbar />

        <section className="manage-colom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1>Charts</h1>
                <div className="row tile-colom">
                  <div   />
                  <div className="col-lg-3">
                    <Link to="/speedchart">
                      <div className="tile-one">
                        <div className="tile-icon">
                          <h1>
                            <img src="images/speed_chart.png" alt="icon" />
                          </h1>
                        </div>
                        <div className="tile-head">
                          <center>Speed</center>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                    <Link to="fuelchart">
                      <div className="tile-one">
                        <div className="tile-icon">
                          <h1>
                            <img src="images/fuel_chart.png" alt="icon" />
                          </h1>
                        </div>
                        <div className="tile-head">
                          <center>Fuel</center>
                        </div>
                      </div>
                    </Link>
                  </div>            
            
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ChartHome;
