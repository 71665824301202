import Navbar from "../compon-styled/Navbar";
import React, { PureComponent } from "react";
import { SpeedChartData } from "../businessjs/ChartFn";
import ToastAlert from "./ToastAlert";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import {
  AppHelper_DateAndTimeToString,
  GetDateDifference
} from "../businessjs/AppHelperFn";
import jwt_decode from "jwt-decode";

import Datetime from "react-datetime";
import { VehicleForCombo } from "../businessjs/VehicleFn";
import CmbSelect from "../compon-controls/CmbSelect";

import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend } from "recharts";

import { css } from "@emotion/core";
import { RiseLoader } from "react-spinners";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class SpeedChart extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    let startDay = new Date();
    startDay.setHours(startDay.getHours() - 6);
    let endDay = new Date();

    this.state = {
      allDataRows: [],
      toastItems: [],
      subscriber_id: 0,
      user_role: 3,
      topSpeeds: [],
      vehicle: undefined,
      vehicles: [],
      startDate: startDay,
      endDate: endDay,

      chartData: [],
      errorMessage: "",
      loading: false,
      executive_id: 0
    };
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({
      subscriber_id: decodedUser.subscriber_id,
      executive_id: decodedUser.autoid
    });
  }

  componentDidMount() {
    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      executive_id: this.state.executive_id
    };
    this.getVehicles(fnInputes);
  }

  getVehicles = fnInputes => {
    VehicleForCombo(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({ label: opt.title, value: opt }));

          let displayVehicleCombo = dbArray.filter(function(a) {
            if (!this[a.value.autoid]) {
              this[a.value.autoid] = true;
              return true;
            }
            return false;
          }, Object.create(null));

          this.setState({
            vehicles: displayVehicleCombo,
            vehicle: displayVehicleCombo[0]
          });

          if (this.state.vehicle !== undefined) {
            this.getSpeedChartData(displayVehicleCombo[0]);
          }
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getSpeedChartData = vehicle => {
    let result = this.validateDateParameters(
      this.state.startDate,
      this.state.endDate
    );
    if (result) {
      this.setState({
        errorMessage: "",
        loading: true
      });
      if (!vehicle.value) {
        vehicle = this.state.vehicle;
      }
      // else { vehicle = "";}

      const { startDate, endDate } = this.state;
      let vehicleId = 0;
      if (vehicle) {
        vehicleId = vehicle.value.autoid;
      }

      const fnInputes = {
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        vehicleId: vehicleId
      };

      SpeedChartData(fnInputes).then(res => {
        if (res) {
          if (Array.isArray(res)) {
            let arry = [];

            let _lastSpeed = 0;
            let _topSpeedItem;
            let _arrSpeedEdges = [];

            res.forEach(function(item) {
              let updatetime = AppHelper_DateAndTimeToString(item.updatetime);
              let speed = item.speed;
              arry.push({ updatetime: updatetime, speed: speed });

              if (item.speed > _lastSpeed) {
                _topSpeedItem = item;
                _lastSpeed = item.speed;
              }
            });

            if (_topSpeedItem) {
              _arrSpeedEdges.push(_topSpeedItem);
            }

            this.setState({
              allDataRows: res,
              chartData: arry,
              loading: false,
              topSpeeds: _arrSpeedEdges
            });
          } else {
            this.setState({
              loading: false
            });
            AuthHelperAccessCheck(res);
          }
        }
      });
    }
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  onStartDateChange = e => {
    // let result = this.validateDateParameters(e._d, this.state.endDate);
    this.setState({ startDate: e._d });
  };

  onEndDateChange = e => {
    // let result = this.validateDateParameters(this.state.startDate, e._d);
    this.setState({ endDate: e._d });
  };

  validateDateParameters = (startDate, endDate) => {
    let dateDiff = GetDateDifference(startDate, endDate);
    if (dateDiff > 5) {
      this.setState({
        errorMessage: "Date range difference should be less than 5"
      });
      return false;
    } else {
      this.setState({
        errorMessage: ""
      });
      return true;
    }
  };

  render() {
    let data = this.state.chartData;
    return (
      <React.Fragment>
        <Navbar />

        <section className="manage-colom">
          <div className="container">
            <main role="main">
              <section>
                <div className="mt-4" style={{ width: "100%" }}>
                  <div>
                    <div className="row">
                      <div className="col-2 align-middle">
                        <h4> Speed Graph: </h4>
                      </div>
                      <div className="col-7 align-middle">
                        <h6 className="m-2">
                          {this.state.vehicle
                            ? this.state.vehicle.label +
                              "-- " +
                              AppHelper_DateAndTimeToString(
                                this.state.startDate
                              ) +
                              " - " +
                              AppHelper_DateAndTimeToString(this.state.endDate)
                            : ""}
                        </h6>
                      </div>
                    </div>

                    <div className="row">
                      <ToastAlert
                        toastItems={this.state.toastItems}
                        onItemClose={this.handleToastItemClose}
                      />

                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <div className="row">
                              <div className="col-3">
                                <div className="row">
                                  <label className="mr-3">Start :</label>
                                  <div style={{ width: "200px" }}>
                                    <Datetime
                                      value={this.state.startDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onStartDateChange}
                                      //timeFormat={false}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="row">
                                  <label className="mr-3">End :</label>
                                  <div style={{ width: "200px" }}>
                                    <Datetime
                                      value={this.state.endDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onEndDateChange}
                                      //timeFormat={false}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-5">
                                <div className="row">
                                  <label className="mr-3">Vehicle :</label>
                                  <div style={{ width: "200px" }}>
                                    <CmbSelect
                                      name="vehicle"
                                      value={this.state.vehicle}
                                      onChange={this.onChange}
                                      dataSource={this.state.vehicles}
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-primary ml-2"
                                    onClick={this.getSpeedChartData}
                                  >
                                    <i className="fa" /> Apply
                                  </button>
                                </div>
                              </div>

                              <div className="sweet-loading ml-2">
                                <RiseLoader
                                  css={loaderCSS}
                                  sizeUnit={"px"}
                                  size={10}
                                  color={"#123abc"}
                                  loading={this.state.loading}
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <span className="text-danger">
                              {this.state.errorMessage} 
                            </span>
                          </div>
                          <div className="m-1">
                                {this.state.topSpeeds.length? (
                                  <center>
                                  <div className="mb-3" > 
                                   
                                    <h2> <small> Top Speed { this.state.topSpeeds[0].speed }(kmph) </small> </h2> 
                                    <small> {AppHelper_DateAndTimeToString(this.state.topSpeeds[0].updatetime )} </small>
                                    <small>
                                    <a rel="noopener noreferrer"
                                          target="_blank"
                                          href={`https://www.google.com/maps/place/${
                                            this.state.topSpeeds[0].lat+ `,` + this.state.topSpeeds[0].log
                                           }`}
                                        >
                                           {this.state.topSpeeds[0].lat+ `,` + this.state.topSpeeds[0].log}
                                        </a>

                                    

                                      </small>
                                  </div>
                                  </center>
                                ):"" }

                            <AreaChart
                              width={this.state.allDataRows.length ? 1050 : 0}
                              height={this.state.allDataRows.length ? 400 : 0}
                              data={data}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0
                              }}
                            >
                              <Area
                                type="monotone"
                                dataKey="speed"
                                stroke="#413ea0"
                                fill="#413ea0"
                              />
                               
                              <XAxis
                                dataKey="updatetime"
                                 
                              />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                            </AreaChart>
                            <center>
                              {this.state.allDataRows.length
                                ? ""
                                : "No data available"}
                            </center>

                             
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default SpeedChart;
