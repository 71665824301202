import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";


import {
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText,
  AuthHelperLogout
} from "../businessjs/AuthHelper";

class SupportCenterNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_mobile: false,
      loginType: "general",
      userType: "guest"
    };
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      this.updateState(token);
    } else {
      this.props.history.push(`/login`);
    }
  }

  updateState = token => {
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({
      token: token,
      subscriber_id: decodedUser.subscriber_id,
      first_name: decodedUser.first_name,
      last_name: decodedUser.last_name,
      email: decodedUser.email,
      account_type: decodedUser.account_type,
      loginType: decoded.accessType,
      userType: decoded.accessType,
      role: decodedUser.role,
      home_url:
        decoded.accessType === "subscriber"
          ? "/subscriberhome"
          : "/supportcenter",
      roleText: AuthHelperGerSubscriberRoleText(decodedUser.role)
    });
  };

  onClickMenuItem_LogOut = e => {
    e.preventDefault();
    AuthHelperLogout("layoutsubscriber");

    this.setState({
      loginType: "general",
      userType: "guest"
    });
    this.props.history.push("/login");
  };

  header() {
    return (
      <header className="top">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 animated slideInDown">
              <a href="/">
                <img
                  src="../images/logo.png" // supportcenter pathname format like "/supportcenter/member".
                  className="img-responsive"
                  alt="logo"
                />
              </a>
            </div>
            <div className="col-sm-12 col-md-6 animated slideInUp">
              <div className="pull-right cont">
                <ul>
                  <li>
                    <span className="icon">
                      <i className="fa fa-user" aria-hidden="true" />
                    </span>
                    Hi, {this.state.first_name} |
                  </li>

                  <li>
                    <span className="icon">
                      <i className="fa fa-close" aria-hidden="true" />
                    </span>
                    <button
                      className="nobutton"
                      onClick={this.onClickMenuItem_LogOut}
                    >
                      Exit
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }

  subscriberLinks() {
    return (
      <ul className="flexy-menu orange">
        <li>
          <Link to="/subscriberprofile">Profile</Link>
        </li>
        <li>
          <Link to="/executives">Executive</Link>
        </li>

        <li>
          <Link to="/vehicles">Vehicles</Link>
        </li>

        <li>
          <Link to="/subscriptionlist">Subscriptions</Link>
        </li>

        <li>
          <Link to="/drivers">Drivers</Link>
        </li>

        <li>
          <Link to="/poi">POI</Link>
        </li>

        <li>
          <Link to="/vehicle-groups">Vehicle Groups</Link>
        </li>

        <li>
          <Link to="/acceleration-alerts">Acceleration Report</Link>
        </li>
      </ul>
    );
  }
  supportCenterMenu() {
    // console.log("in supportcenter");
    return (
      <ul className="flexy-menu orange">
        <li>
          <Link to="/supportcenter/member">Members</Link>
        </li>

        <li>
          <Link to="/supportcenter/subscriberlist">Subscribers</Link>
        </li>

        <li>
          <Link to="/supportcentervehicle">Vehicle List</Link>
        </li>
        <li>
          <Link to="/subscription">Subscriptions</Link>
        </li>

        <li>
          <Link to="/subscriptionwithvehicle">SubscriptionWithVehicle</Link>
        </li>

        <li>
          <Link to={`/change-password/${this.state.loginType}`}>
            Change Password
          </Link>
        </li>
      </ul>
    );
  }

  showMenu() {
    if (this.state.userType === "supportcenter") {
      return this.supportCenterMenu();
    } else if (this.state.userType === "subscriber") {
      return this.subscriberLinks();
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.header()}

        <div className="menu-bar">
          <div className="container">
            <div className="row">{this.showMenu()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SupportCenterNavbar);
