const mapDrawing = {
  map: null,
  maps: null,
  drawingManager: null,
  infowindow: null,
  newPolygon: null,
  init(map, maps, callBackOnPolygonComplete) {
    let self = this;
    const dm = new maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        // drawingModes: ['marker', 'circle', 'polygon', 'polyline', 'rectangle']
        drawingModes: [maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: {
        clickable: false,
        editable: true,
        zIndex: 1
      }
    });

    dm.setMap(map);

    maps.event.addListener(dm, "polygoncomplete", function(polygon) {
      let coordinates = polygon.getPath().getArray();
      let pathPoints = [];
      coordinates.forEach(point => {
        pathPoints.push(point.toString());
      });

      self.showForm(coordinates);
      console.log("the path ", pathPoints);
      if (callBackOnPolygonComplete) {
        callBackOnPolygonComplete(pathPoints);
      }

      if (self.newPolygon) {
        self.newPolygon.setMap(null);
      }

      self.newPolygon = polygon;
    });

    this.infowindow = new maps.InfoWindow({
      content: "getting form"
    });

    this.map = map;
    this.maps = maps;
    this.drawingManager = dm;

    return this;
  },
  showForm(coordinates) {
    if (coordinates && coordinates.length) {
      this.infowindow.setPosition(coordinates[0]);
      this.infowindow.open(this.map);
    }
    return;
  }
};

module.exports = mapDrawing;
