import React, { Component } from "react";
import Select from "react-select";
import { SubscriberSelectActive } from "../businessjs/SubscribersFn";

class SubscriberSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedOption: null, optionsArray: [] };
  }

  componentDidMount() {
    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    };

    SubscriberSelectActive(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({ label: opt.company_name, value: opt }));

          this.setState({ selectedOption: this.props.value,  optionsArray: dbArray });
        }
      }
    });
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    //console.log(' company change ', selectedOption);

    let target = {};
    target.value = selectedOption;
    target.name = this.props.name;
    // target.itemObject = selectedOption.value;

    let event = {};
    event.target = target;
    this.props.onChange(event);
    
  };

  render() {
    return (
      <Select
        name={this.props.name}
        value={this.props.value}
        onChange={this.handleChange}
        options={this.state.optionsArray}
      />
    );
  }
}

export default SubscriberSelect;
