import React from "react";
import {
  ExecutiveAddNew,
  ExecutiveUpdate,
  ExecutiveUpdateStatus,
  ExecutiveSelectBySubscriber
} from "../businessjs/ExecutiveFn";
import ToastAlert from "./ToastAlert";
import jwt_decode from "jwt-decode";
import {
  AuthHelperAccessCheck,
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText
} from "../businessjs/AuthHelper";

import Navbar2 from "../compon-styled/Navbar";
import ExecutiveItem from "./ExecutiveItem"

class Executive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allContacts: [],
      displayedContacts: [],
      subscriber_id: 0,
      executive_email: "",
      user_role: 3,
      first_name: "",
      last_name: "",
      nick_name: "",
      contact_number: "",
      newpassword: "",
      toastItems: []
    };

    this.searchHandler = this.searchHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      displayedContacts = this.state.allContacts.filter(el => {        
        let searchValue = el.email.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      displayedContacts: displayedContacts
    });
  }

  componentDidMount() {    
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];

      this.setState({
        subscriber_id: decodedUser.subscriber_id,
        account_type: decodedUser.account_type,
        company_name: decodedUser.company_name,
        email: decodedUser.email,
        role: decodedUser.role,
        roleText: AuthHelperGerSubscriberRoleText(decodedUser.role)
      });

      const regFormInputes = {
        subscriber_id: decodedUser.subscriber_id,
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId
      };

      ExecutiveSelectBySubscriber(regFormInputes).then(res => {
        if (res) {
          if (Array.isArray(res)) {
            // console.log("data", res);
            this.setState({ allContacts: res });
            this.setState({ displayedContacts: res });
          } else {
            AuthHelperAccessCheck(res);
          }
        }
      });
    }
  }

  componentWillUnmount() {}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const regFormInputes = {
      subscriber_id: this.state.subscriber_id,
      email: this.state.executive_email.toLowerCase(),
      user_role: this.state.user_role,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      nick_name: this.state.nick_name,
      contact_number: this.state.contact_number,
      password: this.state.newpassword,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    };

    ExecutiveAddNew(regFormInputes).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);

        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          const regFormInputes = {
            subscriber_id: this.state.subscriber_id,
            user_token: localStorage.apiAuthAccessKey,
            user_token_id: localStorage.apiAuthAccessId
          };

          ExecutiveSelectBySubscriber(regFormInputes).then(res => {
            if (res) {
              if (Array.isArray(res)) {
                this.setState({
                  first_name: "",
                  last_name: "",
                  nick_name: "",
                  contact_number: "",
                  executive_email: "",
                  newpassword: "",
                  allContacts: res,
                  displayedContacts: res
                });
              } else {
                AuthHelperAccessCheck(res);
              }
            }
          });
        }
      }
    });
  }

  handleOnDelete = itemObject => {
    const newFiltedItems = this.state.displayedContacts.filter(
      c => c.autoid !== itemObject.autoid
    );

    const newAllItems = this.state.allContacts.filter(
      c => c.autoid !== itemObject.autoid
    );

    this.setState({ allContacts: newAllItems });
    this.setState({ displayedContacts: newFiltedItems });

    let temObj = {};
    temObj.autoid = itemObject.autoid;
    temObj.subscriber_id = itemObject.subscriber_id;
    temObj.statuscode = 0;
    temObj.user_token = localStorage.apiAuthAccessKey;
    temObj.user_token_id = localStorage.apiAuthAccessId;

    ExecutiveUpdateStatus(temObj).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);

        this.onToastItemAdd(res.status, res.message);
      }
    });
  };

  handleOnUpdate = itemObject => {
    ExecutiveUpdate(itemObject).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);

        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          const regFormInputes = {
            subscriber_id: this.state.subscriber_id,
            user_token: localStorage.apiAuthAccessKey,
            user_token_id: localStorage.apiAuthAccessId
          };

          ExecutiveSelectBySubscriber(regFormInputes).then(res => {
            if (res) {
              this.setState({ allContacts: res });
              this.setState({ displayedContacts: res });
            }
          });
        }
      }
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd =(type, message)=> {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    // let contacts = this.state.displayedContacts;

    return (
      <React.Fragment>
        <Navbar2 />

        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />

                  <div className="col-md-6 col-12">
                    <div className="card mb-1">
                      <div className="card-header">
                        <h4 className="card-title">Executive Form</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <form className="form" onSubmit={this.onSubmit}>
                            <div className="form-body">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label>User Role</label>
                                      <div className="position-relative has-icon-left">
                                        <select
                                          name="user_role"
                                          className="form-control"
                                          onChange={this.onChange}
                                          value={this.state.user_role}
                                        >
                                          <option value="2"> Admin </option>
                                          <option value="3">User</option>
                                        </select>

                                        <div className="form-control-position">
                                          <i className="ft-user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Email</label>
                                      <div className="position-relative has-icon-left">
                                        <input
                                          type="email"
                                          className="form-control"
                                          name="executive_email"
                                          required
                                          onChange={this.onChange}
                                          value={this.state.executive_email}
                                        />
                                        <div className="form-control-position">
                                          <i className="ft-user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>New Password</label>
                                      <div className="position-relative has-icon-left">
                                        <input
                                          type="password"
                                          className="form-control"
                                          name="newpassword"
                                          required
                                          onChange={this.onChange}
                                          value={this.state.newpassword}
                                        />
                                        <div className="form-control-position">
                                          <i className="ft-user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label>First Name</label>
                                      <div className="position-relative has-icon-left">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="first_name"
                                          required
                                          onChange={this.onChange}
                                          value={this.state.first_name}
                                        />
                                        <div className="form-control-position">
                                          <i className="ft-user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="form-group">
                                      <label>Last Name</label>
                                      <div className="position-relative has-icon-left">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="last_name"
                                          onChange={this.onChange}
                                          required
                                          value={this.state.last_name}
                                        />
                                        <div className="form-control-position">
                                          <i className="ft-user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label>Nick Name </label>
                                      <div className="position-relative has-icon-left">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="nick_name"
                                          onChange={this.onChange}
                                          value={this.state.nick_name}
                                        />
                                        <div className="form-control-position">
                                          <i className="ft-user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label htmlFor="zip_code">
                                        Contact Number
                                      </label>
                                      <div className="position-relative has-icon-left">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="contact_number"
                                          onChange={this.onChange}
                                          value={this.state.contact_number}
                                        />
                                        <div className="form-control-position">
                                          <i className="ft-user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-actions right">
                                <button
                                  type="button"
                                  className="btn btn-warning mr-1"
                                >
                                  <i className="ft-x" /> Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-check-square-o" /> Save
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="card">
                      <div className="card-header">
                        <input
                          type="text"
                          className="card-search float-right"
                          placeholder="Search... "
                          onChange={this.searchHandler}
                        />
                        <h4 className="card-title">Executive List</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <div className="filterlist">
                            <ul>
                              {this.state.displayedContacts.map(el => {
                                return (
                                  <ExecutiveItem
                                    key={el.autoid}
                                    itemObject={el}
                                    onDelete={this.handleOnDelete}
                                    onUpdate={this.handleOnUpdate}
                                    onToastItemAdd = {this.onToastItemAdd}                                   
                                  />
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default Executive;
