
import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi  + "subscription";

export const SubscriptionAddNew = objItem => {    
    return axios
      .post(proxyServer + "/new", { objItem })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const SubscriptionWithVehicleAddNew = objItem => {        
    return axios
      .post(proxyServer + "/newSubWithVehicle", { objItem })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

export const SubscriptionUpdate = objItem => {
  return axios
    .post(proxyServer+  "/update", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const SubscriptionVehicleUpdate = objItem => {
  return axios
    .post(proxyServer+  "/vehicleUpdate", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const SubscriptionStatusUpdate = objItem => {
  return axios
    .post(proxyServer+  "/statusUpdate", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const SubscriptionAssignToVehicle = objItem => {
  console.log('itemobjettt======', objItem)
  return axios
    .post(proxyServer+  "/subscriptionAssignToVehicle", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
 

export const SubscriptionSelectBySubscriber = objItem => {
  return axios
    .post(proxyServer+  "/getbysubscriber", { objItem } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const SubscriptionGetAll = objItem => {
  return axios
    .post(proxyServer+  "/getAll", { objItem } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);     
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


