
// ---  Remove

import React from "react";
import {
  VehicleAddNew,
  VehicleUpdate,
  VehicleStatusUpdate,
  VehicleGetAll,
  VehicleTypes
} from "../businessjs/VehicleFn";
import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import VehicleTypeSelect from "../compon-controls/VehicleTypeSelect";
import VehicleItem from "./VehicleItem";

class Vehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriber_id: undefined,
      vehicle_type: undefined,
      title: "",
      plate_number: "",
      fleet_number: "",
      manufacture_name: "",
      model: "",
      year: "",
      fuel_type: "",
      engine: "",
      tyre: "",
      toastItems: [],
      allDataRows: [],
      filteredDataRows: [],
      sim_provider: "",
      sim: "",
      vehicleTypes: []
    };

    this.searchHandler = this.searchHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value,
      filteredDataRows = this.state.allDataRows.filter(el => {
        let searchValue = el.plate_number.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      filteredDataRows: filteredDataRows
    });
  }

  componentDidMount() {
    VehicleGetAll({
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        this.setState({ allDataRows: res });
        this.setState({ filteredDataRows: res });
      }
    });

    VehicleTypes({
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({ label: opt.title, value: opt }));
          this.setState({ vehicleTypes: dbArray });
        }
      }
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const regFormInputes = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      vehicle_type: this.state.vehicle_type.value.autoid,
      title: this.state.title,
      plate_number: this.state.plate_number,
      fleet_number: this.state.fleet_number,
      manufacture_name: this.state.manufacture_name,
      model: this.state.model,
      year: this.state.year,
      fuel_type: this.state.fuel_type,
      engine: this.state.engine,
      tyre: this.state.tyre,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      sim_provider: this.state.sim_provider,
      sim: this.state.sim
    };

    VehicleAddNew(regFormInputes).then(res => {
      if (res) {
        this.onToastItemAdd(res.status, res.message);
        if (res.status === "success") {
          VehicleGetAll({
            user_token: localStorage.apiAuthAccessKey,
            user_token_id: localStorage.apiAuthAccessId
          }).then(res => {
            if (res) {
              this.setState({ allDataRows: res });
              this.setState({ filteredDataRows: res });
            }
          });
        }
      }
    });
  }

  handleOnDelete = itemObject => {
    const newFiltedItems = this.state.filteredDataRows.filter(
      c => c.autoid !== itemObject.autoid
    );

    const newAllItems = this.state.allDataRows.filter(
      c => c.autoid !== itemObject.autoid
    );

    this.setState({ allDataRows: newAllItems });
    this.setState({ filteredDataRows: newFiltedItems });

    const regFormInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      subscriber_id: itemObject.subscriber_id,
      autoid: itemObject.autoid
    };
    VehicleStatusUpdate(regFormInputes).then(res => {
      if (res) {
        this.onToastItemAdd(res.status, res.message);
      }
    });
  };

  handleOnUpdate = itemObject => {
    VehicleUpdate(itemObject).then(res => {
      if (res) {
        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          VehicleGetAll(this.state).then(res => {
            if (Array.isArray(res)) {
              this.setState({ allDataRows: res });
              this.setState({ filteredDataRows: res });
            } else {
              // AuthHelperAccessCheck(res);
            }
          });
        }
      }
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    let showDataRows = this.state.filteredDataRows;
    return (
      <React.Fragment>
        <Navbar />
        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <h1> Vehicle </h1>
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />
                  <div className="col-md-6 col-12">
                    <div className="card mb-1">
                      <div className="card-header">
                        <h4 className="card-title"> Form</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <form className="form" onSubmit={this.onSubmit}>
                            <div className="form-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>Subscriber</label>
                                    <div className="position-relative has-icon-left">
                                      <SubscriberSelect
                                        name="subscriber_id"
                                        value={this.state.subscriber_id}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Title</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        value={this.state.title}
                                        onChange={this.onChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Type</label>
                                    <div className="position-relative has-icon-left">
                                      <VehicleTypeSelect
                                        name="vehicle_type"
                                        value={this.state.vehicle_type}
                                        onChange={this.onChange}
                                        dataSource={this.state.vehicleTypes}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label>Plate Number</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="plate_number"
                                        value={this.state.plate_number}
                                        onChange={this.onChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>{" "}
                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label>Fleet Number</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="fleet_number"
                                        value={this.state.fleet_number}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label>Manufacture Name</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="manufacture_name"
                                        value={this.state.manufacture_name}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label>Model</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="model"
                                        value={this.state.model}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </div>
                                </div>{" "}
                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label>Year</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="year"
                                        value={this.state.year}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label>Fuel Type</label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="fuel_type"
                                        onChange={this.onChange}
                                        value={this.state.fuel_type}
                                      >
                                        <option value=""> Select </option>
                                        <option value="Petrol">Petrol</option>
                                        <option value="Diesel">Diesel</option>
                                        <option value="Gas">Gas</option>
                                        <option value="Other">Other</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>{" "}
                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label>Engine</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="engine"
                                        value={this.state.engine}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </div>
                                </div>{" "}
                                <div className="col-md-4 col-12">
                                  <div className="form-group">
                                    <label>Tyre</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="tyre"
                                        value={this.state.tyre}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Sim</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="sim"
                                        value={this.state.sim}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Sim Provider</label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="sim_provider"
                                        onChange={this.onChange}
                                        value={this.state.sim_provider}
                                      >
                                        <option value=""> Select </option>
                                        <option value="Ooredoo">Ooredoo</option>
                                        <option value="Vodafone">
                                          Vodafone
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-actions right">
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                >
                                  <i className="ft-x" /> Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-check-square-o" /> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="card">
                      <div className="card-header">
                        <input
                          type="text"
                          className="card-search float-right"
                          placeholder="Search... "
                          onChange={this.searchHandler}
                        />
                        <h4 className="card-title"> List</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <div className="filterlist">
                            <ul>
                              {showDataRows.map(el => {
                                return (
                                  <VehicleItem
                                    key={el.autoid}
                                    itemObject={el}
                                    onDelete={this.handleOnDelete}
                                    onUpdate={this.handleOnUpdate}
                                    vehicleTypeDataSource={
                                      this.state.vehicleTypes
                                    }
                                  />
                                );
                              })}
                            </ul>
                            <center>
                              {showDataRows.length
                                ? ""
                                : "No records available"}
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default Vehicle;
