import React from "react";
import { POISelectBySubscriber,POIVehicleAdd,POIVehicleSelectBySubscriber,POIVehicleRemove } from "../businessjs/POIFn";

import jwt_decode from "jwt-decode";
import {
  AuthHelperAccessCheck,
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText
} from "../businessjs/AuthHelper";

import Navbar2 from "../compon-styled/Navbar";
import ToastAlert from "./ToastAlert";
import DriverVehicleSelect from "../compon-controls/DriverVehicleSelect";
import XButton from "../compon-controls/XButton";

class POIVehicleItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
      editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" },
      assignVehiclesBtn: {
        cssClass: "btn btn-default btn-sm",
        btnText: "Assign Vehicle"
      },
      editMode: false,
      selectedVehicles: [],
      subscriber_id: props.itemObject.subscriber_id,
      driver_name: props.itemObject.driver_name,
      employee_code: props.itemObject.employee_code,
      rfid_code: props.itemObject.rfid_code,
      errorText: ""
    };
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
    } else {
      this.props.history.push("/login");
    }
  }

  onAssignVehicleClick = e => {
    if (this.state.assignVehiclesBtn.btnText === "Cancel") {
      this.setState({
        editMode: false,
        assignVehiclesBtn: {
          cssClass: "btn btn-default btn-sm",
          btnText: "Assign Vehicle"
        }
      });
    } else {
      this.setState({
        editMode: "assign",
        assignVehiclesBtn: {
          cssClass: "btn btn-warning btn-sm",
          btnText: "Cancel"
        }
      });
    }
  };

  onAssignVehicleAddClick = e => {
    if (this.state.selectedVehicles.length > 0) {
      const formInputes = {
        subscriber_id: this.props.itemObject.subscriber_id,
        poi_id: this.props.itemObject.autoid,
        vehicles: this.state.selectedVehicles,
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId,
        updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
      };

      this.props.onVehicleAdd(formInputes);

      this.setState({ selectedVehicles: [] });
    }
  };

  handleOnDriverVehicleChange = selectedOptions => {
    this.setState({ selectedVehicles: selectedOptions });
  };

  handleXButtonVehicle = actionObject => {
    this.props.onVehicleRemove(actionObject);
  };

  formateVehicleList(vehciels) {
    return (
      <React.Fragment>
        <br />
        <div className="row">
          {vehciels.map(el => {
            return (
              <React.Fragment key={el.autoid}>
                <div className="col-md-3 col-12 p-2">
                  <div
                    className={
                      "border border-" +
                      (el.statuscode === 1 ? "primary" : "secondary") +
                      " w-100 p-2 rounded pb-3"
                    }
                  >
                    <XButton
                      valueObject={el}
                      onAction={this.handleXButtonVehicle}
                    />

                    {el.title}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  formateBody(mode) {
    if (mode === "assign") {
      return (
        <React.Fragment>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <span>
                  <small> POI Name </small>
                  <br />
                  {this.props.itemObject.name}
                </span>
              </div>
            </div>
            <div className="row border p-2">
              <div className="col-md-2 col-12">
                <label>Select Vehicles </label>
              </div>
              <div className="col-md-6 col-12">
                <DriverVehicleSelect
                  controlValue={this.state.selectedVehicles}
                  vehicleSelectValue={this.state.vehicleSelectValue}
                  onChange={this.handleOnDriverVehicleChange}
                  subscriber_id={this.state.subscriber_id}
                  addedVehicles={this.props.itemVehicles}
                />
              </div>
              <div className="col-md-4 col-12">
                <button
                  type="button"
                  onClick={this.onAssignVehicleAddClick}
                  className="btn btn-primary btn-sm"
                >
                  Add Vehicle
                </button>

                <button
                  type="button"
                  onClick={this.onAssignVehicleClick}
                  className={this.state.assignVehiclesBtn.cssClass}
                >
                  {this.state.assignVehiclesBtn.btnText}
                </button>
              </div>
            </div>
            {this.formateVehicleList(this.props.itemVehicles)}
          </div>
        </React.Fragment>
      );
    } else if (mode === false) {
      return (
        <React.Fragment>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <span>
                  <small>POI Name </small>
                  <br />
                  {this.props.itemObject.name}
                </span>
              </div>
            </div>
            {this.formateVehicleList(this.props.itemVehicles)}
          </div>
        </React.Fragment>
      );
    }
  }

  formateButtons(mode) {
    if (mode === "assign") {
      return <React.Fragment />;
    } else {
      return (
        <React.Fragment>
          <button
            type="button"
            onClick={this.onAssignVehicleClick}
            className={this.state.assignVehiclesBtn.cssClass}
          >
            {this.state.assignVehiclesBtn.btnText}
          </button>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <li>
        <div className="row ">
          <div className="w-100">
            <div className="btn-group float-right">
              {this.formateButtons(this.state.editMode)}
            </div>
          </div>
        </div>

        <div className="row">{this.formateBody(this.state.editMode)}</div>
      </li>
    );
  }
}

class POIVehicles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      driver_name: "",
      emp_code: "",
      rfid_code: "",
      vehicleSelectValue: {},
      allVehicleItems: [],
      allItems: [],
      showItems: [],
      toastItems: []
    };

    this.searchHandler = this.searchHandler.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      showItems = this.state.allItems.filter(el => {
        let searchValue = el.name.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({ showItems: showItems });
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];

      this.setState({
        subscriber_id: decodedUser.subscriber_id,
        account_type: decodedUser.account_type,
        company_name: decodedUser.company_name,
        email: decodedUser.email,
        role: decodedUser.role,
        roleText: AuthHelperGerSubscriberRoleText(decodedUser.role)
      });

      const regFormInputes = {
        subscriber_id: decodedUser.subscriber_id,
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId
      };

      POISelectBySubscriber(regFormInputes).then(res => {
        if (res) {
          if (Array.isArray(res)) {
            this.setState({ allItems: res });
            this.setState({ showItems: res });
          } else {
            AuthHelperAccessCheck(res);
          }
        }
      });

       
      POIVehicleSelectBySubscriber(regFormInputes).then(res => {
        if (res) {
          if (Array.isArray(res)) {
            this.setState({ allVehicleItems: res });
            console.log('allVehicleItems ', res );
          } else {
            AuthHelperAccessCheck(res);
          }
        }
      });
       
    }
  }

  componentWillUnmount() {}

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  handleOnVehicleAdd = formInputs => {

    console.log('formInputs', formInputs );
    
    POIVehicleAdd(formInputs).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);
         
        const regFormInputes = {
          subscriber_id: this.state.subscriber_id,
          user_token: localStorage.apiAuthAccessKey,
          user_token_id: localStorage.apiAuthAccessId
        };
    
        POIVehicleSelectBySubscriber(regFormInputes).then(res => {
          if (res) {
            if (Array.isArray(res)) {
              this.setState({ allVehicleItems: res });
            } else {
              AuthHelperAccessCheck(res);
            }
          }
        });
        

      }
    });
 
  };

  handleOnVehicleRemove = actionObject => {
     
    const formInputes = {
      subscriber_id: this.state.subscriber_id,
      autoid: actionObject.autoid,
      pointofininterest_id: actionObject.pointofininterest_id,
      vehicle_id: actionObject.vehicle_id,
      cancel_only: actionObject.statuscode === 1 ? 1 : 0 ,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    POIVehicleRemove(formInputes).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);
      }
    });

    const newFiltedItems = this.state.allVehicleItems.filter(
      c => c.autoid !== actionObject.autoid
    );

    this.setState({ allVehicleItems: newFiltedItems });
    
  };

  filterVehicles = poiId => {
    if (this.state.allVehicleItems.length > 0) {
      let searcjQery = poiId,
        filteredDataRows = this.state.allVehicleItems.filter(el => {
          let searchValue = el.pointofininterest_id;
          return searchValue === searcjQery;
        });

          
      return filteredDataRows;
    } else {
      return [];
    }
  };

  render() {
    // let contacts = this.state.showItems;

    return (
      <React.Fragment>
        <Navbar2 />

        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />

                  <div className=" col-12">
                    <div className="card">
                      <div className="card-header">
                        <input
                          type="text"
                          className="card-search float-right"
                          placeholder="POI Name"
                          onChange={this.searchHandler}
                        />
                        <h4 className="card-title">POI Vehicels </h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <div className="filterlist">
                            <ul>
                              {this.state.showItems.map(el => {
                                return (
                                  <POIVehicleItem
                                    key={el.autoid}
                                    itemObject={el}
                                    itemVehicles={this.filterVehicles(
                                      el.autoid
                                    )}
                                    onVehicleAdd={this.handleOnVehicleAdd}
                                    onVehicleRemove={this.handleOnVehicleRemove}
                                  />
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default POIVehicles;
