import React, { Component } from "react";
import Select from "react-select";
import { VehicleSelectBySubscriber } from "../businessjs/VehicleFn";
import jwt_decode from "jwt-decode";

class MultipleVehicleSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedOption: null, optionsArray: [] };
  }

  componentDidMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];

    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      subscriber_id: decodedUser.subscriber_id,
      executive_id: decodedUser.autoid
    };

    VehicleSelectBySubscriber(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({
            label: opt.title,
            value: opt.autoid,
            itemObject: opt
          }));
          this.setState({
            selectedOption: this.props.value,
            optionsArray: dbArray
          });
        }
      }
    });
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.props.onChange(selectedOption);
  };

  render() {
    let displayVehicleList = this.state.optionsArray;

    return (
      <React.Fragment>
        <ul className="list-group">
          <li className="list-group-item">
            <button
              className="btn btn-light"
              onClick={() => this.handleChange(displayVehicleList)}
            >
              Select All
            </button>

            <button
              className="btn btn-light"
              onClick={() => this.handleChange([])}
            >
              Clear
            </button>

          </li>
          <li className="list-group-item">
            <Select
              name={this.props.name}
              value={this.props.vehicleSelectedValues}
              isMulti={true}
              className={this.props.className}
              onChange={this.handleChange}
              options={displayVehicleList}
            />
          </li>
           
        </ul>
      </React.Fragment>
    );
  }
}

export default MultipleVehicleSelect;
