import React from "react";
import { SubscriptionSelectBySubscriber } from "../businessjs/SubscriptionFn";
import ToastAlert from "./ToastAlert";
import Navbar from "./Navbar";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import jwt_decode from "jwt-decode";

class SubscriptionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allDataRows: [],
      filteredDataRows: [],
      toastItems: [],
      subscriber_id: 0,
      user_role: 3
    };

    this.searchHandler = this.searchHandler.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      filteredDataRows = this.state.allDataRows.filter(el => {
        let searchValue = el.device_id.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      filteredDataRows: filteredDataRows
    });
  }

  componentDidMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({ subscriber_id: decodedUser.subscriber_id });

    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      subscriber_id: decodedUser.subscriber_id
    };

    SubscriptionSelectBySubscriber(fnInputes).then(res => {
      //console.log("subscription res ", res);
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allDataRows: res });
          this.setState({ filteredDataRows: res });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  }

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  formateImage() {}

  render() {
    let showDataRows = this.state.filteredDataRows;
    return (
      <React.Fragment>
        <Navbar />
        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <h1> Subscriptions </h1>
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />

                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <input
                          type="text"
                          className="card-search float-right"
                          placeholder="Search... "
                          onChange={this.searchHandler}
                        />
                        <h4 className="card-title"> List</h4>
                      </div>

                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Device ID</th>
                              <th>Title</th>
                              <th>Type</th>
                              <th>Code</th>
                              <th>Expiry Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {showDataRows.map((Item, i) => (
                              <React.Fragment key={i}>
                                <tr>
                                  <td>{Item.device_id}</td>
                                  <td>{Item.title}</td>
                                  <td>{Item.type}</td>
                                  <td>{Item.subscription_code}</td>
                                  <td>{Item.expiry_date}</td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                        <center>
                          {showDataRows.length ? "" : "No records available"}
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default SubscriptionList;
