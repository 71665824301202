import React, { Component } from "react";
import GoogleMap from "google-map-react";

import jwt_decode from "jwt-decode";

import Navbar from "../compon-styled/Navbar";
import MegaMapHelper from "../maphelpers/MegaMapHelper";
import MapDrawing from "../maphelpers/MapDrawing";
import { VehicleSelectBySubscriber } from "../businessjs/VehicleFn";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { LastupdateSelectBySubscriber } from "../businessjs/LastupdateFn";
import VehicleSelectForMap from "../compon-controls/VehicleSelectForMap";
import MyLocation from "../compon-map/MyLocation";

class MegaMap extends Component {
  constructor(props) {
    super(props);

    this.megeMapHelper = MegaMapHelper.init();
    this.mapDrawing = null;

    this.state = {
      selectedVehicle: undefined,
      vehicles: [],
      vehicles_combo: [],
      markers: [],
      subscriber_id: 0,
      locationControlVisible: false
    };
    this.intervalId = null;
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    this.megeMapHelper.distroy();
  }

  componentWillMount() {
    this.megeMapHelper = MegaMapHelper.init();

    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({ subscriber_id: decodedUser.subscriber_id });
  }
  componentDidMount() {}

  onVehicelSelectionChange = e => {
    this.megeMapHelper.setSelectedVehicle(e.target.value.value.autoid);
    this.setState({ selectedVehicle: e.target.value });
  };

  onMapLoaded(map, maps) {
    this.setState({
      mapsLoaded: true,
      map: map,
      maps: maps
    });

    this.megeMapHelper.setMap(map, maps);
    this.mapDrawing = MapDrawing.init(map, maps, this.OnMapDrawingComplated);
    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    };

    this.getVehicles(fnInputes);
    this.intervalId = setInterval(this.getLastUpdate, 2000);
  }

  OnMapDrawingComplated = (pathPoints)=>{
    this.setState({ locationControlVisible: true });
  }

  getVehicles = fnInputes => {
    VehicleSelectBySubscriber(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({
            label: opt.title,
            value: { autoid: opt.autoid },
            item: opt.autoid
          }));
          dbArray.splice(0, 0, {
            label: "None",
            item: -1,
            value: { autoid: -1 }
          });
          dbArray.splice(1, 1, { label: "All", item: 1, value: { autoid: 0 } });

          this.setState({ vehicles_combo: dbArray });
          this.setState({ vehicles: res });
          this.megeMapHelper.setVehicles(res);
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getLastUpdate = () => {
    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      subscriber_id: this.state.subscriber_id
    };
    LastupdateSelectBySubscriber(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.megeMapHelper.setLastUpdates(res);
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />

        <section className="manage-colom">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <VehicleSelectForMap
                  name="vehicle"
                  value={this.state.selectedVehicle}
                  onChange={this.onVehicelSelectionChange}
                  dataSource={this.state.vehicles_combo}
                />
              </div>
            </div>

            <MyLocation visibility={this.state.locationControlVisible} />

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row tile-colom">
                  <div style={{ height: "550px" }} className="w-100">
                    <GoogleMap
                      yesIWantToUseGoogleMapApiInternals={true}
                      bootstrapURLKeys={{
                        key: "AIzaSyAKjw_wuep7sJmjM6tKylxLr6Yo_bHUxnw",
                        libraries: ["drawing"].join(",")
                      }}
                      defaultCenter={[25.273217, 51.414257]}
                      defaultZoom={8}
                      options={{ mapTypeControl: true }}
                      onGoogleApiLoaded={({ map, maps }) => {
                        this.onMapLoaded(map, maps);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MegaMap;
