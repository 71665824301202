import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi;

export const AcclerationAlertCount = objItem => {
  return axios
    .post(proxyServer + "accelerationalert/getacclerationalertcount", {
      objItem
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const AcclerationAlerts = objItem => {
  return axios
    .post(proxyServer + "accelerationalert/getacclerationalerts", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const AcclerationAlertsList = objItem => {
  return axios
    .post(proxyServer + "accelerationalert/getacclerationalertslist", {
      objItem
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const BrakingAlertCount = objItem => {
  return axios
    .post(proxyServer + "brakingalert/getbrakingalertcount", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const BrakingAlerts = objItem => {
  return axios
    .post(proxyServer + "brakingalert/getbrakingalerts", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const BrakingAlertsList = objItem => {
  return axios
    .post(proxyServer + "brakingalert/getbrakingalertslist", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const CorneringAlertCount = objItem => {
  return axios
    .post(proxyServer + "corneringalert/getcorneringalertcount", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const CorneringAlerts = objItem => {
  return axios
    .post(proxyServer + "corneringalert/getcorneringalerts", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const CorneringAlertsList = objItem => {
  return axios
    .post(proxyServer + "corneringalert/getcorneringalertslist", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionAlertCount = objItem => {
  return axios
    .post(proxyServer + "ignitionalert/getignitionalertcount", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionAlerts = objItem => {
  return axios
    .post(proxyServer + "ignitionalert/getignitionalerts", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionAlertsList = objItem => {
  return axios
    .post(proxyServer + "ignitionalert/getignitionalertslist", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const OverSpeedAlertCount = objItem => {
  return axios
    .post(proxyServer + "overspeedalert/getoverspeedalertcount", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const OverSpeedAlerts = objItem => {
  return axios
    .post(proxyServer + "overspeedalert/getoverspeedalerts", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const OverSpeedAlertsList = objItem => {
  return axios
    .post(proxyServer + "overspeedalert/getoverspeedalertslist", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const POIAlertCount = objItem => {
  return axios
    .post(proxyServer + "poialert/getpoialertcount", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const POIAlerts = objItem => {
  return axios
    .post(proxyServer + "poialert/getpoialerts", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const POIAlertsList = objItem => {
  return axios
    .post(proxyServer + "poialert/getpoialertslist", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IdlingAlertCount = objItem => {
  return axios
    .post(proxyServer + "idlingalert/getidlingalertcount", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IdlingAlerts = objItem => {
  return axios
    .post(proxyServer + "idlingalert/getidlingalerts", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IdlingAlertsList = objItem => {
  return axios
    .post(proxyServer + "idlingalert/getidlingalertslist", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const WeeklyAlertCount = objItem => {
  return axios
    .post(proxyServer + "alert/getweeklyalertcount", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const DailyAlertSummaryCount = objItem => {
  return axios
    .post(proxyServer + "alert/getdailyalertsummarycount", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const DailyAlertSummary = objItem => {
  return axios
    .post(proxyServer + "alert/getdailyalertsummary", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const DailyAlertSummaryList = objItem => {
  return axios
    .post(proxyServer + "alert/getdailyalertsummarylist", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


    export const ReconnectAlertCount = objItem => {
      return axios
        .post(proxyServer + "reconnectalert/getreconnectalertcount", { objItem } )
        .then(res => {
          //localStorage.setItem('usertoken', res.data);
          return res.data;
        })
        .catch(err => {
          return err;
        });
    };
      


  export const ReconnectAlerts = objItem => {
  return axios
    .post(proxyServer + "reconnectalert/getreconnectalerts", { objItem } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
  };

  export const ReconnectAlertsList = objItem => {
  return axios
    .post(proxyServer + "reconnectalert/getreconnectalertslist", { objItem } )
    .then(res => {  
      return res.data;
    })
    .catch(err => {
      return err;
    });
  };


export const TimedPingAlertCount = objItem => {
  return axios
    .post(proxyServer + "timedpingalert/gettimedpingalertcount", { objItem } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


export const TimedPingAlerts = objItem => {
return axios
  .post(proxyServer + "timedpingalert/gettimedpingalerts", { objItem } )
  .then(res => {
    //localStorage.setItem('usertoken', res.data);
    return res.data;
  })
  .catch(err => {
    return err;
  });
};

export const TimedPingAlertsList = objItem => {
return axios
  .post(proxyServer + "timedpingalert/gettimedpingalertslist", { objItem } )
  .then(res => {  
    return res.data;
  })
  .catch(err => {
    return err;
  });
};



export const VehcileUtilizationCount = objItem => {
  return axios
    .post(proxyServer + "alert/getvehicleutilizationcount", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleUtilizationList = objItem => {
  return axios
    .post(proxyServer + "alert/getvehicleutilizationlist", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const VehicleUtilizationExportList = objItem => {
  return axios
    .post(proxyServer + "alert/getvehicleutilizationexportlist", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
