import React, { Component } from "react";
import Navbar from "../compon-styled/Navbar";
import {
  ExecutiveChangePassword   
} from "../businessjs/ChangePasswordFn";

import { ExecutiveSelectById } from "../businessjs/ExecutiveFn";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import ToastAlert from "./ToastAlert";
import { Route } from "react-router-dom";


class ChangePasswordByAdmin extends Component {
  state = {
    username: "",
    password: "",
    confirmPassword: "",
    updateby: "",
    email: "",
    isEmailVerified: 0,
    toastItems: [],
    errorMessage: "",
    name: "",
    id: this.props.location.state.itemObject
      ? this.props.location.state.itemObject.executive_id
      : localStorage.apiAuthAccessId
  };

  componentDidMount() {
    // let logintype = this.props.location.state.loginType;
    // if (logintype === "subscriber") {

    let executive_id = this.state.id;
    console.log("executive_id=", executive_id);

    ExecutiveSelectById({
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      executive_id: executive_id
    }).then(res => {
      console.log("res data =", res);
      if (res) {
        this.setState({
          username: res[0].email,
          email: res[0].email,
          isEmailVerified: res[0].is_email_verified,
          name: res[0].first_nam
            ? res[0].first_nam + " " + res[0].last_name
            : res[0].last_name
            //,errorMessage: res[0].is_email_verified === 0 ? "Email id not verified. Before changing the password, please verify your email id"  : ""
        });
      }
    });   
  }

  validate = () => {
    let result = true;    
    if (this.state.password !== this.state.confirmPassword)
    {
      this.setState({ errorMessage: "Password doesn't match" });   
      result = false; 
    }
    return result;
  };

  onSubmit = e => {
    e.preventDefault();

    if (this.validate()) {

      this.setState({ errorMessage: "" });
      let userCredentials = {
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId,
        executive_id :  this.state.id,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword
      };      

      this.changePassword(userCredentials);
    } 
  };

  changePassword = data => {  
    ExecutiveChangePassword(data).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);        
      }
    });   
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  formatCancelButton(itemObject) {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-warning mr-1"
            onClick={() => {
              history.goBack({
                pathname: "/vehicles"
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  }
  

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container mt-4">
          <div className="container">
            <div className=" col-md-8 offset-md-2">
              <div className="row">
                <ToastAlert
                  toastItems={this.state.toastItems}
                  onItemClose={this.handleToastItemClose}
                />
                <div className=" col-12">
                  <div className="card mb-1">
                    <div className="card-header">
                      <h4 className="card-title">Change Password </h4>
                    </div>
                    <div className="card-content collpase show">
                      <div className="card-body">
                        <form className="form" onSubmit={this.onSubmit}>
                          <div className="form-body">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-4">
                                  <div className="form-group">
                                    <label>User Name</label>
                                  </div>
                                </div>

                                <div className="col-6">
                                  <div className="form-group">
                                    <label>{this.state.username} </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-4">
                                  <div className="form-group">
                                    <label>Password</label>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        required
                                        value={this.state.password}
                                        onChange={this.onChange}
                                      />
                                      <div className="form-control-position">
                                        <i className="ft-user" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-4">
                                  <div className="form-group">
                                    <label>Confirm Password</label>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="password"
                                        className="form-control"
                                        name="confirmPassword"
                                        required
                                        value={this.state.confirmPassword}
                                        onChange={this.onChange}
                                      />
                                      <div className="form-control-position">
                                        <i className="ft-user" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <span className="text-danger">
                                  {this.state.errorMessage}
                                </span>
                              </div>
                              <div className="col-8">
                                <div className="form-actions float-right mb-1">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    <i className="fa fa-check-square-o" /> Save
                                  </button>
                                  {this.formatCancelButton(
                                    this.state.itemObject
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* <div className="form-actions float-right mb-1">
                              <button type="submit" className="btn btn-primary">
                                <i className="fa fa-check-square-o" /> Save
                              </button>
                              {this.formatCancelButton(this.state.itemObject)}
                            </div> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    
    );
  }
}

export default ChangePasswordByAdmin;
