import React from "react";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
import Datetime from "react-datetime";
import jwt_decode from "jwt-decode";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { VehicleForCombo } from "../businessjs/VehicleFn";
import { AppHelper_DateAndTimeToString} from "../businessjs/AppHelperFn";
import CmbSelect from "../compon-controls/CmbSelect";
import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/Navbar";
import { GetConsolidatedAlertsSummaryCount, GetConsolidatedAlertsSummary } from "../businessjs/ReportFn";
import { Route } from "react-router-dom";
import Pagination from "react-js-pagination";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class RptIgnitionConsolidatedAlertSummary extends React.Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    
        let startDay = new Date();
        let endDay = new Date();
        startDay.setDate(startDay.getDate() - 7);

        this.state = {
            subscriber_id: 0,
            executive_id: 0,
            vehicle: undefined,
            vehicles: [],
            startDate: startDay,
            endDate: endDay,
            loading: true,
            allDataRows: [],
            filteredDataRows: [],
            toastItems: [],
            allDrivers: [],

            activePage: undefined,
            itemsCountPerPage: 25,
            totalItemsCount: 0,
          };   
        
      }



  componentWillMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({
      subscriber_id: decodedUser.subscriber_id,
      executive_id: decodedUser.autoid     
    });
  }

  componentDidMount() {
    const getVehicleInput = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      executive_id: this.state.executive_id
    };
    this.getVehicles(getVehicleInput);
    this.getAlertSummaryCount();

  }

  getVehicles = getVehicleInput => {      
    VehicleForCombo(getVehicleInput).then(res => {
      if (res) {         
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({ label: opt.title, value: opt }));
          dbArray.splice(0, 0, { label: "All", value: { autoid: 0 } });

          let displayVehicleCombo = dbArray.filter(function(a) {
            if (!this[a.value.autoid]) {
              this[a.value.autoid] = true;
              return true;
            }
            return false;
          }, Object.create(null));
          this.setState({ vehicles: displayVehicleCombo });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getAlertSummaryCount = () => {
    this.setState({ loading: true });
    const { startDate, endDate, vehicle } = this.state;
    let vehicleId = 0;
    if (vehicle) {
      vehicleId = vehicle.value.autoid;
    }

    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      startDate: startDate,
      endDate: endDate,
      vehicleId: vehicleId,
      executive_id:this.state.executive_id
    };

    GetConsolidatedAlertsSummaryCount(fnInputes).then(res => {
      if (res) {          
        if (Array.isArray(res)) {
          let alertCount = res[0].alertcount;
          if (alertCount > 0) {
            this.setState({ totalItemsCount: alertCount, activePage: 1 });
            this.getAlertSummary(1);
          } else {
            this.setState({
              allDataRows: [],
              filteredDataRows: [],
              totalItemsCount: 0,
              activePage: 1,
              loading: false
            });
          }
        } else {
          this.setState({ loading: false });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    this.getAlertSummary(pageNumber);
  };


  getAlertSummary = (pageNumber) => {
    this.setState({ loading: true });
    const { startDate, endDate, vehicle, itemsCountPerPage } = this.state;
    let vehicleId = 0;
    if (vehicle) {
      vehicleId = vehicle.value.autoid;
    }
    let start = (pageNumber - 1) * this.state.itemsCountPerPage;

    const alertInputs = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      startDate: startDate,
      endDate: endDate,
      startLimit: start,
      endLimit: itemsCountPerPage,
      vehicleId: vehicleId,
      executive_id: this.state.executive_id
    };

    GetConsolidatedAlertsSummary(alertInputs).then(res => {
      if (res) {                    
        if (Array.isArray(res)) {
          this.setState({
            allDataRows: res,
            filteredDataRows: res,
            loading: false
          });
          if (!this.state.activePage) {
            this.setState({ totalItemsCount: res.length, activePage: 1 });
          } else {
          }
        } else {
          this.setState({ loading: false });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportToExceldButton() {
    const ButtonExport = () => (
      <Route
        render={({ history }) => (
          <React.Fragment>
            <button
              type="button"
              className="btn btn-primary ml-2 fa fa-file-excel-o"
              onClick={() => {
                history.push({
                  pathname: "/exporttoexcel",
                  state: {
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    vehicle: this.state.vehicle,
                    totalItemsCount: this.state.totalItemsCount,
                    name: "Consolidated Alery Summary",
                    type: "con_alert_smry"
                  }
                });
              }}
            />
          </React.Fragment>
        )}
      />
    );
    return <ButtonExport />;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onStartDateChange = e => {
    this.setState({ startDate: e._d });
  };

  onEndDateChange = e => {
    this.setState({ endDate: e._d });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    let showDataRows = this.state.filteredDataRows;
    return (
      <React.Fragment>
        <Navbar />
        <section className="manage-colom">
          <div className="container">
            <main role="main">
              <section>
                <div className="mt-4" style={{ width: "100%" }}>
                  <div>
                    <h2>Consolidated Alert Summary</h2>
                    <div className="row">
                      <ToastAlert
                        toastItems={this.state.toastItems}
                        onItemClose={this.handleToastItemClose}
                      />
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <div className="row">
                              <div className="col-3">
                                <div className="row">
                                  <label className="mr-3">Start :</label>
                                  <div style={{ width: "200px" }}>
                                    <Datetime
                                      defaultValue={this.state.startDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onStartDateChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="row">
                                  <label className="mr-3">End :</label>
                                  <div style={{ width: "200px" }}>
                                    <Datetime
                                      defaultValue={this.state.endDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onEndDateChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-5">
                                <div className="row">
                                  <label className="mr-3">Vehicle :</label>
                                  <div style={{ width: "200px" }}>
                                    <CmbSelect
                                      name="vehicle"
                                      value={this.state.vehicle}
                                      onChange={this.onChange}
                                      dataSource={this.state.vehicles}
                                    />
                                  </div>
                                  <div className="btn-group float-right">
                                    <button
                                      type="button"
                                      className="btn btn-primary ml-2 fa fa-search"
                                      onClick={this.getAlertSummaryCount}
                                    >
                                      <i className="fa" />
                                    </button>
                                    {this.exportToExceldButton()}
                                  </div>
                                  <div className="sweet-loading ml-2">
                                    <RiseLoader
                                      css={loaderCSS}
                                      sizeUnit={"px"}
                                      size={10}
                                      color={"#123abc"}
                                      loading={this.state.loading}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-striped" bouder="1">
                            <thead>
                                <tr>
                                  <th>Vehicle Title</th>
                                  <th>Plate No</th>
                                  
                                  <th>First Start</th>
                                  <th>Last End</th>
                                  <th colSpan="4">
                                    <center>
                                    Alerts Count
                                    </center>
                                    </th>
                                  <th>  Total Time </th>
                                  <th> Drivers </th>
                                </tr>
                              </thead>

                            <thead>
                                <tr>
                                  <th colSpan="4"></th>
                                   
                                  <th title="Acceleration" >Acceler... </th>
                                  <th title="Braking">Braking </th>
                                  <th title="Cornering">Cornering </th>
                                  <th title="Overspeed">Overspeed </th>
                                  <th> Overspeed</th>
                                  <th>   </th>
                                </tr>
                              </thead>
                               
                              <tbody>
                                {showDataRows.map((Item, i) => (
                                  <React.Fragment key={i}>
                                    <tr>
                                      <td>{Item.title}</td>
                                      <td>{Item.plate_number}</td>
                                      
                                      <td>{AppHelper_DateAndTimeToString(Item.first_start )}</td>
                                      <td>{AppHelper_DateAndTimeToString(Item.last_end )}</td>
                                      <td>{Item.ha_count_sum}</td>
                                      <td>{Item.hb_count_sum}</td>
                                      <td>{Item.hc_count_sum}</td>
                                      <td>{Item.os_count_sum}</td>
                                      <td>{Item.os_sec_sum}</td>
                                      <td>
                                        {Item.drivers}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                            <center>
                              {showDataRows.length
                                ? ""
                                : "No records available"}
                            </center>
                          </div>
                        </div>
                      
                        <div className="row">
                          <div
                            className="col-12"
                            style={{ textAlign: "right" }}
                          >
                            <Pagination
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.itemsCountPerPage}
                              totalItemsCount={this.state.totalItemsCount}
                              pageRangeDisplayed={10}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default RptIgnitionConsolidatedAlertSummary;
