import React, { Component } from "react";

import Navbar2 from "../compon-styled/Navbar";
import GoogleMap from "google-map-react";
import jwt_decode from "jwt-decode";
import POIItemAddNew from "../maphelpers/POIItemAddNew";
import POIShowItem from "../compon-controls/POIShowItem";
import { Link } from "react-router-dom";

import {
  POIAddNew,
  POISelectBySubscriber,
  POIDelete
} from "../businessjs/POIFn";
import {
  AuthHelperAccessCheck,
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText
} from "../businessjs/AuthHelper";
import ToastAlert from "./ToastAlert";

class PointOfIninterest extends Component {
  state = {
    map: null,
    maps: null,
    mapsLoaded: false,
    myMarkers: [],
    poiItem: null,
    toastItems: []
  };

  componentDidMount() {
    /*
    let _tempa = [
      {
        id: 1,
        rarius: 300,
        name: "name 1",
        latitude: 25.262754,
        longitude: 51.501264,
        mapobj: null
      },
      {
        id: 2,
        rarius: 500,
        name: "name 2",
        latitude: 25.28376,
        longitude: 51.438569,
        mapobj: null
      },
      {
        id: 3,
        rarius: 800,
        name: "name 3",
        latitude: 25.283206,
        longitude: 51.475046,
        mapobj: null
      }
    ];
    this.setState({ myMarkers: _tempa });
    */

    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];

      this.setState({
        subscriber_id: decodedUser.subscriber_id,
        account_type: decodedUser.account_type,
        company_name: decodedUser.company_name,
        email: decodedUser.email,
        role: decodedUser.role,
        roleText: AuthHelperGerSubscriberRoleText(decodedUser.role)
      });

      const regFormInputes = {
        subscriber_id: decodedUser.subscriber_id,
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId
      };

      POISelectBySubscriber(regFormInputes).then(res => {
        if (res) {
          if (Array.isArray(res)) {
            this.setState({ myMarkers: res });
          } else {
            AuthHelperAccessCheck(res);
          }
        }
      });
    }
  }

  onMapLoaded(map, maps) {
    

    this.setState({
      mapsLoaded: true,
      map: map,
      maps: maps
    });
  }

  onAddNewPOI = event => {
    if (this.state.poiItem) {
      this.state.poiItem.dismiss();
    }

    let _pi23 = POIItemAddNew.init(
      this.state.map,
      this.state.maps,
      this.POIItem_onAddNew,
      this.POIItem_onDissmiss
    );
    this.setState({ poiItem: _pi23 });
  };

  POIItem_onDissmiss = item => {
    //console.log('POIItem_onDissmiss called');
  };

  POIItem_onAddNew = (name, radius, geo_code) => {
     

    const regFormInputes = {
      subscriber_id: this.state.subscriber_id,
      name: name,
      geo_code: geo_code,
      radius: radius,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    POIAddNew(regFormInputes).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);

        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          const regFormInputes = {
            subscriber_id: this.state.subscriber_id,
            user_token: localStorage.apiAuthAccessKey,
            user_token_id: localStorage.apiAuthAccessId
          };

          POISelectBySubscriber(regFormInputes).then(res => {
            if (res) {
              if (Array.isArray(res)) {
                let _oldValues = this.state.myMarkers;
                let _newValues = res;
                let _ind = -1;
                _oldValues.forEach(element => {
                  _ind = _newValues.findIndex(x => x.autoid === element.autoid);
                  if (_ind > -1) {
                    _newValues[_ind].mapobj = element.mapobj;
                  }
                });

                this.setState({ myMarkers: _newValues });
              } else {
                AuthHelperAccessCheck(res);
              }
            }
          });
        }
      }
    });
  };

  renderMarker = (marker, length) => {
    if (this.state.map && this.state.maps && length) {
      if (marker.mapobj === undefined || marker.mapobj === null) {
        let map = this.state.map;
        let maps = this.state.maps;

        let geo_code = marker.geo_code.split(",");
        let possion = {
          lat: parseFloat(geo_code[0]),
          lng: parseFloat(geo_code[1])
        };

        let poiMarker = new maps.Marker({
          map: map,
          draggable: false,
          animation: maps.Animation.DROP,
          position: possion
        });

        let inf = new maps.InfoWindow({
          content:
            "<b>" +
            marker.name +
            "</b> <br / > <span>Radius: " +
            marker.radius +
            "</span> "
        });

        let cir = new maps.Circle({
          strokeColor: "#ff7575",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#ff7575",
          fillOpacity: 0.3,
          map: map,
          center: possion,
          radius: marker.radius
        });
        inf.open(map, poiMarker);

        poiMarker.addListener("click", function() {
          inf.open(map, poiMarker);
        });

        marker.mapobj = { poiMarker, inf, cir };
      }
    }
  };

  handleItemSelect = marker => {
    if (this.state.map) {
      let _map = this.state.map;

      let geo_code = marker.geo_code.split(",");

      let possion = {
        lat: parseFloat(geo_code[0]),
        lng: parseFloat(geo_code[1])
      };

     

      if (marker.mapobj) {
        if (marker.mapobj.inf) {
          marker.mapobj.inf.open(_map, marker.mapobj.poiMarker);
          _map.setCenter(possion);
        }
      } else {
        this.state.map.setCenter(possion);
        this.state.map.setZoom(12);

        let poiMarker = new this.state.maps.Marker({
          map: this.state.map,
          draggable: false,
          animation: this.state.maps.Animation.DROP,
          position: possion
        });

        let inf = new this.state.maps.InfoWindow({
          content:
            "<b>" +
            marker.name +
            "</b> <br / > <span>Radius: " +
            marker.radius +
            "</span> "
        });

        let cir = new this.state.maps.Circle({
          strokeColor: "#ff7575",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#ff7575",
          fillOpacity: 0.3,
          map: this.state.map,
          center: possion,
          radius: marker.radius
        });

        inf.open(this.state.map, poiMarker);

        poiMarker.addListener("click", function() {
          inf.open(_map, poiMarker);
        });
        let markerArray = this.state.myMarkers;
        let arrIndex = markerArray.indexOf(marker);
        marker.mapobj = { poiMarker, inf, cir };
        markerArray[arrIndex] = marker;
        this.setState({ myMarkers: markerArray });
      }
    }
  };

  handlePOIDelete = itemObject => {
    

    if (itemObject.mapobj) {
      itemObject.mapobj.inf.close();

      itemObject.mapobj.poiMarker.setMap(null);
      itemObject.mapobj.cir.setMap(null);
      itemObject.mapobj.inf = null;
    }
    const newAllItems = this.state.myMarkers.filter(
      c => c.autoid !== itemObject.autoid
    );

     
    this.setState({ myMarkers: newAllItems });

    let temObj = {};
    temObj.autoid = itemObject.autoid;
    temObj.subscriber_id = itemObject.subscriber_id;
    temObj.user_token = localStorage.apiAuthAccessKey;
    temObj.user_token_id = localStorage.apiAuthAccessId;

    POIDelete(temObj).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);
      }
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    return (
      <React.Fragment>
        <Navbar2 />
        <main role="main">
          <section>
            <div className="mt-4" style={{ width: "100%" }}>
              <div>
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />
                  <div className="col-md-12 col-12">
                    <div className="card mb-1">
                      <div className="card-header">
                        <h4 className="card-title">Point Of Ininterest(POI)</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <div className="form-body">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-3">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={this.onAddNewPOI}
                                  >
                                    Add New POI
                                  </button>

                              
 
                                  <Link to="/poivehicles" className="">
                                  Assign Vehicles
          </Link>

                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">POI</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.myMarkers.map(
                                        (marker, index) => (
                                          <POIShowItem
                                            index={index}
                                            key={marker.autoid}
                                            onSelect={this.handleItemSelect}
                                            mapObject={this.state.map}
                                            itemObject={marker}
                                            onDelete={this.handlePOIDelete}
                                          />
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div
                                  className="col-9"
                                  style={{ height: "700px" }}
                                >
                                  <GoogleMap
                                    yesIWantToUseGoogleMapApiInternals={true}
                                    bootstrapURLKeys={{
                                      key:
                                        "AIzaSyAKjw_wuep7sJmjM6tKylxLr6Yo_bHUxnw"
                                    }}
                                    defaultCenter={this.props.center}
                                    defaultZoom={this.props.zoom}
                                    onGoogleApiLoaded={({ map, maps }) => {
                                      this.onMapLoaded(map, maps);
                                    }}
                                  >
                                    {this.state.myMarkers.map(marker =>
                                      this.renderMarker(
                                        marker,
                                        this.state.myMarkers.length
                                      )
                                    )}
                                  </GoogleMap>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

PointOfIninterest.defaultProps = {
  center: [25.273217, 51.414257],
  zoom: 10
};

export default PointOfIninterest;
