import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "passengers";

export const PassengerAddNew = objItem => {
  return axios
    .post(proxyServer + "/new", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const PassengerUpdate = objItem => {
  return axios
    .post(proxyServer + "/update", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const PassengerDelete = objItem => {
  return axios
    .post(proxyServer + "/delete", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const PassengerUpdateStatus = objItem => {
  return axios
    .post(proxyServer + "/updatestatus", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const PassengerSelectBySubscriber = objItem => {
  return axios
    .post(proxyServer + "/getbysubscriber", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

// Passenger vehicles ========================================

export const PassengerVehicleAdd = objItem => {
  return axios
    .post(proxyServer + "/vehicle_add", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const PassengerVehicleSelectBySubscriber = objItem => {
  return axios
    .post(proxyServer + "/getvehiclesbysubscriber", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const PassengerVehicleRemove = objItem => {
  return axios
    .post(proxyServer + "/vehicle_remove", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
