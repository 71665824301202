import React, { Component } from "react";
import { RiseLoader } from "react-spinners";
import { ExecutiveVerifyEmail } from "../businessjs/UserEmailConfirmation";
import { css } from "@emotion/core";
import { AuthHelperLogout } from "../businessjs/AuthHelper";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default class VerifyEmail extends Component {
  state = {
    confirming: true,
    loading: false,
    status: "",
    message: ""
  };

  // http://localhost:3000/VerifyEmail/5c40d7607d259400989a9d42

  componentDidMount = () => {   
    this.setState({
      loading: true
    });
    const { id } = this.props.match.params;
    const regFormInputes = {
      autoid: id,
      email_verify: 1
    };

    ExecutiveVerifyEmail(regFormInputes)
      .then(res => {
        if (res) {       
          this.setState({
            status: res.status,         
            message: res.message,
            loading: false
          });
          if (Array.isArray(res)) {
            this.setState({ confirming: false });
          }
        }
      })
      .catch(err =>
        console.log("Error occured while getting executive :", err)
      );
  };

  formatBody = mode => {
    if (mode === "success") {
      return (
        <React.Fragment>
          <div
            className="row mt-4"
            style={{ fontSize: "25px", justifyContent: "center" }}
          >
            Successflly verified Your email address.
          </div>
          <div className="row mt-4">
            <RiseLoader
              css={loaderCSS}
              sizeUnit={"px"}
              size={10}
              color={"#123abc"}
              loading={this.state.loading}
            />
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onClickLogin}
            >
              Return to log in
            </button>
          </div>
        </React.Fragment>
      );
    } else if (mode === "failed") {
      return (
        <React.Fragment>
          <div
            className="row mt-4"
            style={{ fontSize: "25px", justifyContent: "center" }}
          >
            {this.state.message}
          </div>

          {/*<div
            className="row mt-4"
            style={{
              justifyContent: "center"
            }}
          >
            {this.state.message}
          </div>
           <div className="row mt-4" style={{ justifyContent: "center" }}>
            Please Log in to try again.
          </div> */}
          <div className="row" style={{ justifyContent: "center" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onClickLogin}
            >
              Log in
            </button>
          </div>
        </React.Fragment>
      );
    }
  };

  onClickLogin = e => {
    e.preventDefault();
    AuthHelperLogout("layoutsubscriber");

    this.setState({
      loginType: "general",
      userType: "guest"
    });
    this.props.history.push("/login");
  };

  render() {
    return (
      <React.Fragment>
        <div className="container mt-4">
          <div className=" col-md-8 offset-md-1" style={{ marginTop: "110px" }}>
            <div className="row">
              <div className="col-12 ">
                <div
                  className="row mt-4"
                  style={{ paddingLeft: "2em", justifyContent: "center" }}
                >
                  <img
                    src="/images/logo.png"
                    className="img-responsive"
                    alt="logo"
                  />
                </div>
                {this.formatBody(this.state.status)}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
