import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "verifyemail";

export const ExecutiveVerifyEmail = objItem => {
  return axios
    .post(proxyServer + "/confirmemail", { objItem })
    .then(res => {
      console.log("result in fn =", res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};