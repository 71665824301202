import React, { Component } from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportDataExcel extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {}

  render() {
    // console.log("name",this.props.name)
    let key =
      this.props.dataSource[0] === undefined
        ? ""
        : Object.keys(this.props.dataSource[0]);

    if (this.props.dataSource.length === 0) {
      return null;
    }

    
    return (
      <div style={{ marginLeft: "19em" }}>
        <ExcelFile
          element={
            <button className="btn btn-sm btn-primary"> Download</button>
          }
          filename={this.props.name}
        >
          <ExcelSheet data={this.props.dataSource} name={this.props.name}>
            {key.map((item, index) => {
              return <ExcelColumn key={index} label={item} value={item} />;
            })}
          </ExcelSheet>
        </ExcelFile>
      </div>
    );
  }
}

export default ExportDataExcel;
