import React, { Component } from "react";
import Navbar from "../compon-styled/Navbar";
import { Link } from "react-router-dom";
import UserRole from "../businessjs/Enum";

class MasterHome extends Component {
  state = {};

  executiveIcon() {
    return (
      <div className="col-lg-3">
        <Link to="/executives">
          <div className="tile-one">
            <div className="tile-icon">
              <h1>
                <img src="images/users.png" alt="icon" />
              </h1>
            </div>
            <div className="tile-head">
              <center>Users</center>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  driverIcon() {
    return (
      <div className="col-lg-3">
        <Link to="drivers">
          <div className="tile-one">
            <div className="tile-icon">
              <h1>
                <img src="images/drivers.png" alt="icon" />
              </h1>
            </div>
            <div className="tile-head">
              <center>Drivers</center>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  poiIcon() {
    return (
      <div className="col-lg-3">
        <Link to="poi">
          <div className="tile-one">
            <div className="tile-icon">
              <h1>
                <img src="images/pois.png" alt="icon" />
              </h1>
            </div>
            <div className="tile-head">
              <center>POIs</center>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  vehicleGroupIcon() {
    return (
      <div className="col-lg-3">
        <Link to="vehicle-groups">
          <div className="tile-one">
            <div className="tile-icon">
              <h1>
                <img src="images/vehicle-group.png" alt="icon" />
              </h1>
            </div>
            <div className="tile-head">
              <center>Vehicle Groups</center>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  passengerIcon() {
    return (
      <div className="col-lg-3">
        <Link to="passengers">
          <div className="tile-one">
            <div className="tile-icon">
              <h1>
                <img src="images/passengers.png" alt="icon" />
              </h1>
            </div>
            <div className="tile-head">
              <center>Passengers</center>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  vehicleListIcon() {
    return (
      <div className="col-lg-3">
        <Link to="vehicle-list">
          <div className="tile-one">
            <div className="tile-icon">
              <h1>
                <img src="images/vehicle_list.png" alt="icon" />
              </h1>
            </div>
            <div className="tile-head">
              <center>Vehicle List</center>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  executiveVehicleGroup() {
    return (
      <div className="col-lg-3">
        <Link to="executive-vehicle-group">
          <div className="tile-one">
            <div className="tile-icon">
              <h1>
                <img src="images/users.png" alt="icon" />
              </h1>
            </div>
            <div className="tile-head">
              <center>Executive Vehicle Groups</center>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  showIcons() {
    if (localStorage.apiAuthRole === UserRole.EXECUTIVE) {
      return;
    } else if (
      localStorage.apiAuthRole === UserRole.EXECUTIVE_ADMIN.toString()
    ) {
      return (
        <div>
          {this.executiveIcon()}
          {this.driverIcon()}
          {this.poiIcon()}
          {this.vehicleGroupIcon()}
          {this.passengerIcon()}
          {this.vehicleListIcon()}
          {this.executiveVehicleGroup()}

          <div className="col-lg-3">
            <Link to="multiple-vehicle-control">
              <div className="tile-one">
                <div className="tile-icon">
                  <h1>
                    <img src="images/vehicle-group.png" alt="icon" />
                  </h1>
                </div>
                <div className="tile-head">
                  <center>Multiple Vehicle Controls</center>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    } else if (
      localStorage.apiAuthRole === UserRole.EXECUTIVE_OWNER.toString()
    ) {
      return (
        <div>
          {this.executiveIcon()}
          {this.driverIcon()}
          {this.poiIcon()}
          {this.vehicleGroupIcon()}
          {this.passengerIcon()}
          {this.vehicleListIcon()}
          {this.executiveVehicleGroup()}

          <div className="col-lg-3">
            <Link to="multiple-vehicle-control">
              <div className="tile-one">
                <div className="tile-icon">
                  <h1>
                    <img src="images/vehicle-group.png" alt="icon" />
                  </h1>
                </div>
                <div className="tile-head">
                  <center>Multiple Vehicle Controls</center>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />

        <section className="manage-colom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1>Masters</h1>
                <div className="row tile-colom">
                  <div />
                  {this.showIcons()}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MasterHome;
