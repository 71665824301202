import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi;

export const GetDeviceStatusCount = objItem => {
    return axios
      .post(proxyServer + "devicereport/getdevicestatuscount", {
        objItem
      })
      .then(res => {
        //localStorage.setItem('usertoken', res.data);
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

export const GetDeviceStatus = objItem => {
  return axios
    .post(proxyServer + "devicereport/getdevicestatus", {
      objItem
    })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GetDeviceStatusExcel = objItem => {
    return axios
      .post(proxyServer + "devicereport/getdevicestatusexcel", {
        objItem
      })
      .then(res => {
        //localStorage.setItem('usertoken', res.data);
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };


  
  export const ConsolidatedAlertSummary = objItem => {
    return axios
      .post(proxyServer + "alert/get_consolidated_alert_summary", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };
  
  export const ConsolidatedUsageSummary = objItem => {
    return axios
      .post(proxyServer + "alert/get_consolidated_usage_summary", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };
 
   

  export const GetAlertsSummaryCount = objItem => {
    return axios
      .post(proxyServer + "report/getIgnitionAlertSummaryCount", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const GetAlertsSummary = objItem => {
    return axios
      .post(proxyServer + "report/getIgnitionAlertSummaryAlerts", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const GetAlertsSummaryExcel = objItem => {
    return axios
      .post(proxyServer + "report/getIgnitionAlertSummaryAlertExcel", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };


     

  export const GetUsageSummaryCount = objItem => {
    return axios
      .post(proxyServer + "report/getIgnitionUsageSummaryCount", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const GetUsageSummary = objItem => {
    return axios
      .post(proxyServer + "report/getIgnitionUsageSummaryAlerts", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const GetUsageSummaryExcel = objItem => {
    return axios
      .post(proxyServer + "report/getIgnitionUsageSummaryAlertExcel", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };


  export const GetConsolidatedAlertsSummaryCount = objItem => {
    return axios
      .post(proxyServer + "report/getConsolidatedIgnitionAlertSummaryCount", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const GetConsolidatedAlertsSummary = objItem => {
    return axios
      .post(proxyServer + "report/getConsolidatedIgnitionAlertSummary", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const GetConsolidatedAlertsSummaryExcel = objItem => {
    return axios
      .post(proxyServer + "report/getConsolidatedIgnitionAlertSummaryExcel", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };


  export const GetConsolidatedUsageSummaryCount = objItem => {
    return axios
      .post(proxyServer + "report/getConsolidatedIgnitionUsageSummaryCount", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const GetConsolidatedUsageSummary = objItem => {
    return axios
      .post(proxyServer + "report/getConsolidatedIgnitionUsageSummaryAlerts", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const GetConsolidatedUsageSummaryExcel = objItem => {
    return axios
      .post(proxyServer + "report/getConsolidatedIgnitionUsageSummaryAlertExcel", {
        objItem
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };
