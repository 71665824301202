import React from "react";
import {  
  AuthHelperValidateOverToken  
} from "../businessjs/AuthHelper";

import DriverVehicleSelect from "../compon-controls/DriverVehicleSelect";
import XButton from "../compon-controls/XButton";


class VehicleGroupItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
      editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" },
      assignVehiclesBtn: {
        cssClass: "btn btn-default btn-sm",
        btnText: "Assign Vehicle"
      },
      editMode: false,
      selectedVehicles: [],
      subscriber_id: props.itemObject.subscriber_id,
      group_name: props.itemObject.group_name,
      errorText: ""
    };

    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
    } else {
      this.props.history.push("/login");
    }
  }

  onDelete(e) {
    e.preventDefault();

    switch (this.state.deleteBtn.btnText) {
      case "Delete":
        this.setState({
          deleteBtn: {
            cssClass: "btn btn-danger btn-sm",
            btnText: "Confirm ?"
          },
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });
        break;
      case "Update":
        if (this.state.group_name === "") {
          this.setState({ errorText: "The name is mandatory " });
          return;
        }

        let temObj = this.props.itemObject;
        temObj.group_name = this.state.group_name;
        temObj.user_token = localStorage.apiAuthAccessKey;
        temObj.user_token_id = localStorage.apiAuthAccessId;

        this.props.onUpdate(temObj);

        this.setState({
          editMode: false,
          deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
          editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
        });

        break;
      case "Confirm ?":
        this.props.onDelete(this.props.itemObject);

        break;
      default:
        this.setState({
          deleteBtn: {
            cssClass: "btn btn-danger btn-sm",
            btnText: "Confirm ?"
          },
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });

        break;
    }
  }

  onEdit(e) {    
    if (this.state.editBtn.btnText === "Cancel") {
      this.setState({
        editMode: false,
        deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
        editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
      });
    } else if (this.state.editBtn.btnText === "Edit") {
      this.setState({
        editMode: true,
        deleteBtn: { cssClass: "btn btn-primary btn-sm", btnText: "Update" },
        editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" },
        group_name: this.props.itemObject.group_name        
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onAssignVehicleClick = e => {
    if (this.state.assignVehiclesBtn.btnText === "Cancel") {
      this.setState({
        editMode: false,
        assignVehiclesBtn: {
          cssClass: "btn btn-default btn-sm",
          btnText: "Assign Vehicle"
        }
      });
    } else {
      this.setState({
        editMode: "assign",
        assignVehiclesBtn: {
          cssClass: "btn btn-warning btn-sm",
          btnText: "Cancel"
        }
      });
    }
  };

  onAssignVehicleAddClick = e => {
    if (this.state.selectedVehicles.length > 0) {

      // let vehicleIds =""
      const formInputes = {
        subscriber_id: this.props.itemObject.subscriber_id,
        group_id: this.props.itemObject.autoid,
        vehicles: this.state.selectedVehicles,
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId,
        updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
      };
     

      this.props.onVehicleAdd(formInputes);

      this.setState({ selectedVehicles: [] });

    }
  };

  handleOnDriverVehicleChange = selectedOptions => {
    this.setState({ selectedVehicles: selectedOptions });
  };

  handleXButtonVehicle = actionObject => {
    // console.log('obj ', actionObject);
    this.props.onVehicleRemove(actionObject);
  };

  formateVehicleList(vehciels) {    
    return (
      <React.Fragment>
        <br />
        <div className="row">
          {vehciels.map(el => {
            return (
              <React.Fragment key={el.autoid}>
                <div className="col-md-3 col-12 p-2">
                  <div className="border border-secondary w-100 p-2 rounded pb-3">
                    <XButton
                      valueObject={el}
                      onAction={this.handleXButtonVehicle}
                    />

                    {el.title}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  formateBody(mode) {    
    if (mode === true) {
      return (
        <React.Fragment>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <span>
                  <small>Name </small>
                  <br />

                  <input
                    type="text"
                    className="form-control"
                    name="group_name"
                    onChange={this.onChange}
                    required
                    value={this.state.group_name}
                  />
                </span>
              </div>
              
            </div>
          </div>
          <label className="errorlabel"> {this.state.errorText}</label>
        </React.Fragment>
      );
    } else if (mode === "assign") {
      return (
        <React.Fragment>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <span>
                  <small>Name </small>
                  <br />
                  {this.props.itemObject.group_name}
                </span>
              </div>
              
            </div>
            <div className="row border p-2">
              <div className="col-md-2 col-12">
                <label>Select Vehicles </label>
              </div>
              <div className="col-md-6 col-12">
                <DriverVehicleSelect
                  controlValue={this.state.selectedVehicles}
                  vehicleSelectValue={this.state.vehicleSelectValue}
                  onChange={this.handleOnDriverVehicleChange}
                  subscriber_id={this.state.subscriber_id}
                  addedVehicles={this.props.itemVehicles}
                />
              </div>
              <div className="col-md-4 col-12">
                <button
                  type="button"
                  onClick={this.onAssignVehicleAddClick}
                  className="btn btn-primary btn-sm"
                >
                  Add Vehicle
                </button>

                <button
                  type="button"
                  onClick={this.onAssignVehicleClick}
                  className={this.state.assignVehiclesBtn.cssClass}
                >
                  {this.state.assignVehiclesBtn.btnText}
                </button>
              </div>
            </div>
            {this.formateVehicleList(this.props.itemVehicles)}
          </div>
        </React.Fragment>
      );
    } else if (mode === false) {
      return (
        <React.Fragment>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <span>
                  <small>Name </small>
                  <br />
                  {this.props.itemObject.group_name}
                </span>
              </div>             
              
            </div>
            {this.formateVehicleList(this.props.itemVehicles)}
          </div>
        </React.Fragment>
      );
    }
  }

  formateButtons(mode) {
    if (mode === "assign") {
      return <React.Fragment />;
    } else {
      return (
        <React.Fragment>
          <button
            type="button"
            onClick={this.onAssignVehicleClick}
            className={this.state.assignVehiclesBtn.cssClass}
          >
            {this.state.assignVehiclesBtn.btnText}
          </button>

          <button
            type="button"
            onClick={this.onEdit}
            className={this.state.editBtn.cssClass}
          >
            {this.state.editBtn.btnText}
          </button>
          <button
            type="button"
            onClick={this.onDelete}
            className={this.state.deleteBtn.cssClass}
          >
            {this.state.deleteBtn.btnText}
          </button>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <li>
        <div className="row ">
          <div className="w-100">
            <div className="btn-group float-right">
              {this.formateButtons(this.state.editMode)}
            </div>
          </div>
        </div>

        <div className="row">{this.formateBody(this.state.editMode)}</div>
      </li>
    );
  }
}

export default VehicleGroupItem;