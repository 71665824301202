import React from "react";
import Navbar from "../compon-styled/Navbar";
import {
  AuthHelperValidateOverToken,
  AuthHelperAccessCheck
} from "../businessjs/AuthHelper";
import jwt_decode from "jwt-decode";
import { ExecutiveSelectForGroupAssign } from "../businessjs/ExecutiveFn";
import {
  GroupSelectBySubscriber,
  ExecutiveVehicleGroupAdd,
  ExecutiveVehicleGroupSelectById,
  ExecutiveVehicleGrouprDelete
} from "../businessjs/VehicleGroupFn";
import CmbSelect from "../compon-controls/CmbSelect";
import ToastAlert from "./ToastAlert";
import XButton from "../compon-controls/XButton";

class ExecutiveVehicleGroupItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assignVehiclesBtn: {
        cssClass: "btn btn-default btn-sm",
        btnText: "Assign Vehicle Group"
      },
      editMode: false,
      selectedVehicles: [],
      subscriber_id: props.itemObject.subscriber_id,
      executive_name:
        props.itemObject.first_name + " " + this.props.itemObject.last_name,
      errorText: "",
      groupId: 0
    };
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
    } else {
      this.props.history.push("/login");
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onAssignVehicleGroupClick = e => {
    if (this.state.assignVehiclesBtn.btnText === "Cancel") {
      this.setState({
        editMode: false,
        assignVehiclesBtn: {
          cssClass: "btn btn-default btn-sm",
          btnText: "Assign Vehicle Group"
        }
      });
    } else {
      this.setState({
        editMode: "assign",
        assignVehiclesBtn: {
          cssClass: "btn btn-warning btn-sm",
          btnText: "Cancel"
        }
      });
    }
  };

  onAssignVehicleGroupAddClick = e => {    
    if (this.state.groupId !== 0) {
      const formInputes = {
        subscriber_id: this.props.itemObject.subscriber_id,
        executive_id: this.props.itemObject.autoid,
        groupId: this.state.groupId,
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId
      };
      this.props.onVehicleAdd(formInputes);
    }
  };

  handleXButtonVehicle = actionObject => {
    this.props.onVehicleRemove(actionObject);
  };

  formateVehicleList(vehicles) {
    return (
      <React.Fragment>
        <br />
        <div className="row">
          {vehicles.map(el => {
            return (
              <React.Fragment key={el.autoid}>
                <div className="col-md-5 col-12 p-2">
                  <div
                   style={{height:"35px",textAlign:"center"}}
                    className={
                      "border border-" +
                      (el.statuscode === 1 ? "primary" : "secondary") +
                      " w-100 p-2 rounded pb-3 "
                    }
                  >
                  <div style={{margin:"-7px",height:"10px"}}>
                    <XButton                  
                      valueObject={el}
                      onAction={this.handleXButtonVehicle}
                    />
                    </div>
                    {el.group_name}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  groupChange = e => {
    this.setState({ groupId: e.target.value.value.autoid });
  };

  formateBody(mode) {
    if (mode === true) {
      return (
        <React.Fragment>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <span>
                  <small>Name </small>
                  <br />

                  <input
                    type="text"
                    className="form-control"
                    name="executive_name"
                    onChange={this.onChange}
                    required
                    value={this.state.executive_name}
                  />
                </span>
              </div>
            </div>
          </div>
          <label className="errorlabel"> {this.state.errorText}</label>
        </React.Fragment>
      );
    } else if (mode === "assign") {
      return (
        <React.Fragment>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <span>
                  <small>Name </small>
                  <br />
                  {this.props.itemObject.first_name +
                    " " +
                    this.props.itemObject.last_name}
                </span>
              </div>
            </div>
            <div className="row border p-2">
              <div className="col-md-3 col-12">
                <label>Select Group</label>
              </div>
              <div className="col-md-4 col-12">
                <CmbSelect
                  name="group_id"
                  value={this.state.group_id}
                  onChange={this.groupChange}
                  dataSource={this.props.vehicleGroupData}
                />
              </div>
              <div className="col-md-5 col-12">
                <button
                  type="button"
                  onClick={this.onAssignVehicleGroupAddClick}
                  className="btn btn-primary btn-sm"
                >
                  Add Vehicle Group
                </button>

                <button
                  type="button"
                  onClick={this.onAssignVehicleGroupClick}
                  className={this.state.assignVehiclesBtn.cssClass}
                >
                  {this.state.assignVehiclesBtn.btnText}
                </button>
              </div>
            </div>
            {this.formateVehicleList(this.props.itemVehicles)}
          </div>
        </React.Fragment>
      );
    } else if (mode === false) {
      return (
        <React.Fragment>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <span>
                  <small>Name </small>
                  <br />
                  {this.props.itemObject.first_name +
                    " " +
                    this.props.itemObject.last_name}
                </span>
              </div>
            </div>
            {this.formateVehicleList(this.props.itemVehicles)}
          </div>
        </React.Fragment>
      );
    }
  }

  formateButtons(mode) {
    if (mode === "assign") {
      return <React.Fragment />;
    } else {
      return (
        <button
          type="button btn-sm"
          onClick={this.onAssignVehicleGroupClick}
          className={this.state.assignVehiclesBtn.cssClass}
        >
          {this.state.assignVehiclesBtn.btnText}
        </button>
      );
    }
  }

  render() {
    return (
      <li>
        <div className="row ">
          <div className="w-100">
            <div className="btn-group float-right">
              {this.formateButtons(this.state.editMode)}
            </div>
          </div>
        </div>

        <div className="row">{this.formateBody(this.state.editMode)}</div>
      </li>
    );
  }
}

class ExecutiveVehicleGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passenger_name: "",
      emp_code: "",
      rfid_code: "",
      vehicleSelectValue: {},
      allVehicleGroupItems: [],
      allItems: [],
      showItems: [],
      toastItems: [],
      vehicleGroupData: []
    };
  }

  searchHandler = event => {
    let searcjQery = event.target.value.toLowerCase(),
      showItems = this.state.allItems.filter(el => {
        let searchValue = el.first_name.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      showItems: showItems
    });
  };

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];
      this.setState({
        subscriber_id: decodedUser.subscriber_id
      });

      const regFormInputes = {
        subscriber_id: decodedUser.subscriber_id,
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId
      };

      GroupSelectBySubscriber(regFormInputes).then(res => {
        if (res) {
          if (Array.isArray(res)) {
            let dbArray = res.map(opt => ({
              label: opt.group_name,
              value: opt
            }));
            dbArray.splice(0, 0, {
              label: "Select Group",
              value: { autoid: 0 }
            });
            this.setState({ vehicleGroupData: dbArray });
          } else {
            AuthHelperAccessCheck(res);
          }
        }
      });

      ExecutiveSelectForGroupAssign(regFormInputes).then(res => {
        if (res) {
          if (Array.isArray(res)) {
            this.setState({ allItems: res, showItems: res });
          } else {
            AuthHelperAccessCheck(res);
          }
        }
      });

      ExecutiveVehicleGroupSelectById(regFormInputes).then(res => {
        if (res) {
          if (Array.isArray(res)) {
            this.setState({ allVehicleGroupItems: res });
          } else {
            AuthHelperAccessCheck(res);
          }
        }
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  handleOnVehicleAdd = formInputs => {    
    ExecutiveVehicleGroupAdd(formInputs).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);

        ExecutiveVehicleGroupSelectById(formInputs).then(res => {
          if (res) {
            if (Array.isArray(res)) {
              this.setState({ allVehicleGroupItems: res });
            } else {
              AuthHelperAccessCheck(res);
            }
          }
        });
      }
    });
  };

  filterVehicles = executiveId => {
    if (this.state.allVehicleGroupItems.length > 0) {
      let searcjQery = executiveId,
        filteredDataRows = this.state.allVehicleGroupItems.filter(el => {
          let searchValue = el.executive_id;
          return searchValue === searcjQery;
        });
      return filteredDataRows;
    } else {
      return [];
    }
  };

  handleOnVehicleRemove = actionObject => {
    const formInputes = {
      autoid: actionObject.autoid,
      executive_id: actionObject.executive_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3
    };

    ExecutiveVehicleGrouprDelete(formInputes).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);
      }
    });
    const newFiltedItems = this.state.allVehicleGroupItems.filter(
      c => c.autoid !== actionObject.autoid
    );

    this.setState({ allVehicleGroupItems: newFiltedItems });
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />

        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <div className=" col-md-8 offset-md-2">
                  <div className="row">
                    <ToastAlert
                      toastItems={this.state.toastItems}
                      onItemClose={this.handleToastItemClose}
                    />

                    <div className=" col-12">
                      <div className="card">
                        <div className="card-header">
                          <input
                            type="text"
                            className="card-search float-right"
                            placeholder="Search... "
                            onChange={this.searchHandler}
                          />
                          <h4 className="card-title">Executive Vehicle Groups</h4>
                        </div>
                        <div className="card-content collpase show">
                          <div className="card-body">
                            <div className="filterlist">
                              <ul>
                                {this.state.showItems.map(el => {
                                  return (
                                    <ExecutiveVehicleGroupItem
                                      key={el.autoid}
                                      itemObject={el}
                                      itemVehicles={this.filterVehicles(
                                        el.autoid
                                      )}
                                      onVehicleAdd={this.handleOnVehicleAdd}
                                      onVehicleRemove={
                                        this.handleOnVehicleRemove
                                      }
                                      vehicleGroupData={
                                        this.state.vehicleGroupData
                                      }
                                    />
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default ExecutiveVehicleGroup;
