import React, { Component } from "react";
import Navbar from "../compon-styled/Navbar";
import { Route } from "react-router-dom";
import { ExecutiveSelectById } from "../businessjs/ExecutiveFn";
import { ExecutiveChangeEmail } from "../businessjs/ChangeEmailFn";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import ToastAlert from "./ToastAlert";
// import { EmailVerificationTokenAdd } from "../businessjs/EmailVerifyFn";
// import { SendMail } from "../businessjs/CommunicationFn";

class ChangeEmail extends Component {
  state = {
    old_email: "",
    isEmailVerified: 0,
    new_email: "",
    toastItems: [],
    executive_id:0,
    subscriber_id:0
  };

  componentDidMount() {
    ExecutiveSelectById({
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        this.setState({
          old_email: res[0].email,
          isEmailVerified: res[0].is_email_verified,
          executive_id:res[0].autoid,
          subscriber_id:res[0].subscriber_id
        });
      }
    });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    let changeEmailData = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      new_email:this.state.new_email,
      executive_id:this.state.executive_id,
      subscriber_id:this.state.subscriber_id   
    };
    ExecutiveChangeEmail(changeEmailData).then(res => {
        if (res) {
          AuthHelperAccessCheck(res);
          this.onToastItemAdd(res.status, res.message);
        }
      });
  };

  formatCancelButton(itemObject) {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-warning mr-1"
            onClick={() => {
              history.goBack({
                pathname: "/vehicles"
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  }

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container mt-4">
          <div className="container">
            <div className=" col-md-8 offset-md-2">
              <div className="row">
                <ToastAlert
                  toastItems={this.state.toastItems}
                  onItemClose={this.handleToastItemClose}
                />
                <div className=" col-12">
                  <div className="card mb-1">
                    <div className="card-header">
                      <h4 className="card-title">Change E-mail </h4>
                    </div>
                    <div className="card-content collpase show">
                      <div className="card-body">
                        <form className="form" onSubmit={this.onSubmit}>
                          <div className="form-body">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-4">
                                  <div className="form-group">
                                    <label>Old Email</label>
                                  </div>
                                </div>

                                <div className="col-6">
                                  <div className="form-group">
                                    <label>{this.state.old_email} </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-4">
                                  <div className="form-group">
                                    <label>New E-mail</label>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="new_email"
                                        required
                                        value={this.state.new_email}
                                        onChange={this.onChange}
                                      />
                                      <div className="form-control-position">
                                        <i className="ft-user" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <span className="text-danger">
                                  {this.state.errorMessage}
                                </span>
                              </div>
                              <div className="col-8">
                                <div className="form-actions float-right mb-1">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    {/* <i className="fa fa-check-square-o" />  */}
                                    Save
                                  </button>
                                  {this.formatCancelButton()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChangeEmail;
