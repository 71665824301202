import React, { Component } from "react";

class XButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      btnText: "X",
      btnCss: "btn btn-outline-secondary btn-sm float-right",
      btnCancelCss: "btn btn-outline-warning btn-sm float-right d-none"
    };
  }

  handleChange = selectedOption => {
    if (this.state.btnText === "X") {
      this.setState({
        btnText: "Confirm ?",
        btnCss: "btn btn-outline-danger btn-sm float-right",
        btnCancelCss: "btn btn-outline-warning btn-sm float-right"
      });
    } else {
      this.props.onAction(this.props.valueObject);
    }
  };

  handleCancel = selectedOption => {
    this.setState({
      btnText: "X",
      btnCss: "btn btn-outline-secondary btn-sm float-right",
      btnCancelCss: "btn btn-outline-warning btn-sm float-right d-none"
    });
  };

  render() {
    return (
      <React.Fragment>
        <button
          type="button"
          onClick={this.handleChange}
          className={this.state.btnCss}
        >
          {this.state.btnText}
        </button>
        <button
          type="button"
          onClick={this.handleCancel}
          className={this.state.btnCancelCss}
        >
          Cancel
        </button>
      </React.Fragment>
    );
  }
}

export default XButton;
