
// --- Remove

import React from "react";
import VehicleTypeSelect from "../compon-controls/VehicleTypeSelect";
class VehicleItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
      editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" },
      editMode: false,

      subscriber_id: 0,
      title: "",
      plate_number: "",
      fleet_number: "",
      manufacture_name: "",
      model: "",
      year: "",
      fuel_type: "",
      engine: "",
      tyre: "",
      toastItems: [],
      vehicle_type: undefined,
      sim_provider: "",
      sim: ""
    };

    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getVehicleType(value) {
    if (this.props.vehicleTypeDataSource) {
      let filteredDataRows = this.props.vehicleTypeDataSource.filter(el => {
        return el.value.autoid === value;
      })[0];

      return filteredDataRows;
    }
  }
  onDelete(e) {
    switch (this.state.deleteBtn.btnText) {
      case "Delete":
        this.setState({
          deleteBtn: { cssClass: "btn btn-danger btn-sm", btnText: "Confirm ?" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });
        break;
      case "Update":
        let temObj = this.props.itemObject;

        temObj.title = this.state.title;
        temObj.plate_number = this.state.plate_number;
        temObj.fleet_number = this.state.fleet_number;
        temObj.manufacture_name = this.state.manufacture_name;
        temObj.model = this.state.model;
        temObj.year = this.state.year;
        temObj.fuel_type = this.state.fuel_type;
        temObj.engine = this.state.engine;
        temObj.tyre = this.state.tyre;
        temObj.vehicle_type = this.state.vehicle_type.value.autoid;
        temObj.user_token = localStorage.apiAuthAccessKey;
        temObj.user_token_id = localStorage.apiAuthAccessId;
        temObj.sim_provider = this.state.sim_provider;
        temObj.sim = this.state.sim;

        this.props.onUpdate(temObj);
        this.setState({ editMode: false });
        this.setState({
          deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
        });

        break;
      case "Confirm ?":
        let statusObj = this.props.itemObject;
        statusObj.status = 0;

        this.props.onDelete(statusObj);

        break;
      default:
        this.setState({
          deleteBtn: { cssClass: "btn btn-danger btn-sm", btnText: "Confirm ?" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });
        break;
    }
  }

  onEdit(e) {
    if (this.state.editBtn.btnText === "Cancel") {
      this.setState({ editMode: false });
      this.setState({
        deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
      });
    } else if (this.state.editBtn.btnText === "Edit") {
      this.setState({ editMode: true });
      this.setState({
        deleteBtn: { cssClass: "btn btn-primary btn-sm", btnText: "Update" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
      });

      this.setState({ subscriber_id: this.props.itemObject.subscriber_id });
      this.setState({ title: this.props.itemObject.title });
      this.setState({ plate_number: this.props.itemObject.plate_number });
      this.setState({ fleet_number: this.props.itemObject.fleet_number });
      this.setState({
        manufacture_name: this.props.itemObject.manufacture_name
      });
      this.setState({ model: this.props.itemObject.model });
      this.setState({ year: this.props.itemObject.year });
      this.setState({ fuel_type: this.props.itemObject.fuel_type });
      this.setState({ engine: this.props.itemObject.engine });

      this.setState({ tyre: this.props.itemObject.tyre });
      this.setState({
        vehicle_type: this.getVehicleType(this.props.itemObject.vehicle_type)
      });
      this.setState({ sim_provider: this.props.itemObject.simprovider });
      this.setState({ sim: this.props.itemObject.sim });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  subscriberChange = selected => {
    this.setState({ subscriber_id: selected.target.value });
  };

  formateBody(mode) {
    if (mode) {
      return (
        <React.Fragment>
          <div className="mb-2 mt-2">
            <div className="form-body">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Title</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={this.state.title}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Type</label>
                    <div className="position-relative has-icon-left">
                      <VehicleTypeSelect
                        name="vehicle_type"
                        value={this.state.vehicle_type}
                        onChange={this.onChange}
                        dataSource={this.props.vehicleTypeDataSource}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>Plate Number</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="plate_number"
                        value={this.state.plate_number}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>Fleet Number</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="fleet_number"
                        value={this.state.fleet_number}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label>Manufacture </label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="manufacture_name"
                        value={this.state.manufacture_name}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label>Model</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="model"
                        value={this.state.model}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label>Year</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="year"
                        value={this.state.year}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label>Fuel Type</label>
                    <div className="position-relative has-icon-left">
                      <select
                        className="form-control"
                        name="fuel_type"
                        onChange={this.onChange}
                        value={this.state.fuel_type}
                      >
                        <option value=""> Select </option>
                        <option value="Petrol">Petrol</option>
                        <option value="Diesel">Diesel</option>
                        <option value="Gas">Gas</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label>Engine</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="engine"
                        value={this.state.engine}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label>Tyre</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="tyre"
                        value={this.state.tyre}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Sim</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="sim"
                        value={this.state.sim}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Sim Provider</label>
                    <div className="position-relative has-icon-left">
                      <select
                        className="form-control"
                        name="sim_provider"
                        onChange={this.onChange}
                        value={this.state.sim_provider}
                      >
                        <option value=""> Select </option>
                        <option value="Ooredoo">Ooredoo</option>
                        <option value="Vodafone">Vodafone</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span>
            {this.props.itemObject.plate_number} {this.props.itemObject.title}
          </span>
          <span className="phone">{this.props.itemObject.model}</span>
          <span className="phone">{this.props.itemObject.plate_number}</span>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <li>
        {this.formateBody(this.state.editMode)}
        <div className="btn-group float-right">
          <button
            type="button"
            onClick={this.onEdit}
            className={this.state.editBtn.cssClass}
          >
            {this.state.editBtn.btnText}
          </button>
          <button
            type="button"
            onClick={this.onDelete}
            className={this.state.deleteBtn.cssClass}
          >
            {this.state.deleteBtn.btnText}
          </button>
        </div>
      </li>
    );
  }
}

export default VehicleItem;
