
import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "country";

export const CountryAddNew = objItem => {
    return axios
      .post(proxyServer + "/new", { objItem })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const CountrySelect = objItem => {   
    return axios
      .post(proxyServer + "/getAllCountries", { objItem })
      .then(res => {
        return res.data;
       
      })
      .catch(err => {
        console.log("error!!!!",err)
        return err;
      });
  };



