import React, { Component } from "react";
import XButton from "./XButton";

class POIShowItem extends Component {
  constructor(props) {
    super(props);
    console.log(" hi ");
  }

  onSelect = () => {
    this.props.onSelect(this.props.itemObject);
  };

  handleDismiss = (valueObject) => {
    // valueObject
    
    this.props.onDelete(valueObject);
  }

  render() {
    return (
      <React.Fragment>
        <tr >
          <th onClick={this.onSelect} scope="row">{this.props.index + 1}</th>
          <td onClick={this.onSelect}> {this.props.itemObject.name} 
           
          <XButton valueObject={this.props.itemObject} onAction={this.handleDismiss} /> 
            

            </td>
           
        </tr>
      </React.Fragment>
    );
  }
}

export default POIShowItem;
