import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText,
  AuthHelperLogout
} from "../businessjs/AuthHelper";
import { AuthHelperGerSubscriberTypeText } from "../businessjs/AuthHelper";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_mobile: false,
      loginType: "general",
      userType: "guest",
      selectedmodule: "country"
    };

    this.onClickMenuItem = this.onClickMenuItem.bind(this);
    this.onClickMenuItem_LogOut = this.onClickMenuItem_LogOut.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;

      this.updateState(token);
    } else {
      this.props.history.push(`/login`);
    }
  }

  /*
  componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.token && AuthHelperValidateOverToken("layoutsubscriber")) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.token !== this.state.token) {
          this.updateState(nextProps.token);
        }
      } else {
        this.props.history.push(`/login`);
      }
    } catch (e) {
      console.log(" Navbar WillReceiveProps exception: ",e);
    }
    
  }
  */

  updateState(token) {
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    //console.log(" role ", decodedUser.role);
    //console.log(" new tocken decoded accountType ", decoded.accessType);
    this.setState({
      token: token,
      subscriber_id: decodedUser.subscriber_id,
      first_name: decodedUser.first_name,
      last_name: decodedUser.last_name,
      email: decodedUser.email,
      account_type: decodedUser.account_type,
      company_name: decodedUser.company_name,
      loginType: decoded.accessType,
      userType: decoded.accessType,
      role: decodedUser.role,     
      home_url:
        decoded.accessType === "subscriber"
          ? "/subscriberhome"
          : "/supportcenter",
      roleText: AuthHelperGerSubscriberRoleText(decodedUser.role)
    });
  }

  onClickMenuItem_LogOut(e) {
    e.preventDefault();
    AuthHelperLogout("layoutsubscriber");

    this.setState({
      loginType: "general",
      userType: "guest"
    });
    this.props.history.push("/login");
  }

  onClickMenuItem(e) {
    e.preventDefault();

    if (e.target.innerHTML.toLowerCase().includes("currency")) {
      this.setState({ selectedmodule: "currency" });
    } else if (e.target.innerHTML.toLowerCase().includes("country")) {
      this.setState({ selectedmodule: "country" });
    } else if (e.target.innerHTML.toLowerCase().includes("members")) {
      this.setState({ selectedmodule: "members" });
    } else if (e.target.innerHTML.toLowerCase().includes("varity")) {
      this.setState({ selectedmodule: "varity" });
    } else if (e.target.innerHTML.toLowerCase().includes("commodity")) {
      this.setState({ selectedmodule: "commodity" });
    }
  }

  toggleClass() {
    console.log("toggleClass clicked");
    let currentState = this.state.is_mobile;
    this.setState({ is_mobile: !currentState });
  }

  formatUserRole(menutype) {
    if (menutype === "subscriber") {
      return (
        <strong className="d-block">
          {this.state.roleText} of{" "}
          {AuthHelperGerSubscriberTypeText(this.state.account_type)} Account
        </strong>
      );
    } else if (menutype === "supportcenter") {
      return <strong className="d-block">Support Center Admin </strong>;
    } else {
      return <strong className="d-block"> </strong>;
    }
  }

  formateUserMenu(menutype) {
    const item2 = (
      <React.Fragment>
        <li className="nav-item btn-group">
          <Link to="/subscriberprofile" className="nav-link btn btn-link">
            {this.state.company_name}
            <small className="d-block">
              {" "}
              {AuthHelperGerSubscriberTypeText(
                this.state.account_type
              )} Account{" "}
            </small>
          </Link>
        </li>
        <li className="border-right"> </li>
      </React.Fragment>
    );

    if (menutype === "supportcenter" || menutype === "subscriber") {
      return (
        <ul className="navbar-nav navbar-right">
          {menutype === "subscriber" ? item2 : ""}

          <li className="nav-item btn-group show-on-hover">
            <button className="nav-link btn btn-link">
              Logged in as : {this.state.first_name} {this.state.last_name}
              <small className="d-block">{this.formatUserRole(menutype)}</small>
            </button>
            <ul className="dropdown-menu" role="menu">
              <li className="dropdown-item">
                <button
                  className="nav-link btn btn-link fontcolor-green"
                  onClick={this.onClickMenuItem_LogOut}
                >
                  Log Out
                </button>
              </li>
            </ul>
          </li>
        </ul>
      );
    } else {
      return <span> Hi, Guest </span>;
    }
  }

  formateMenuItems(menutype) {
    const loginRegLink = (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <Link to="/login" className="nav-link">
            Login
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/register" className="nav-link">
            Register
          </Link>
        </li>
      </ul>
    );

    const subscriberLinks = (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <Link to="/subscriberprofile" className="nav-link">
            Profile
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/executive" className="nav-link">
            Executive
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/vehicles" className="nav-link">
            Vehicles
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/subscriptionlist" className="nav-link">
            Subscriptions
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/drivers" className="nav-link">
            Drivers
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/poi" className="nav-link">
            POI
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/vehicle-groups" className="nav-link">
            Vehicle Groups
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/acceleration-alerts" className="nav-link">
            Acceleration Report
          </Link>
        </li>
      </ul>
    );

    const supportcenterLinks = (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <Link to="/supportcenter/member" className="nav-link">
            Members
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/supportcenter/subscriberlist" className="nav-link">
            Subscribers
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/vehicle" className="nav-link">
            Vehicles
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/subscription" className="nav-link">
            Subscriptions
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/subscriptionwithvehicle" className="nav-link">
            SubscriptionWithVehicle
          </Link>
        </li>

        <li className="nav-item">
          <Link to={`/change-password/${this.state.loginType}`} className="nav-link">
            Change Password
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/supportcentervehicle" className="nav-link">
            Vehicle List
          </Link>
        </li>
        
      </ul>
    );

    if (menutype === "supportcenter") {
      return supportcenterLinks;
    } else if (menutype === "subscriber") {
      return subscriberLinks;
    } else {
      return loginRegLink;
    }
  }

  render() {
    return (
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-gradient-green">
        <a className="navbar-brand" href={this.state.home_url}>
          Brand Name
        </a>

        <button
          className="navbar-toggler btn btn-sm btn-outline-secondary"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={e => this.toggleClass()}
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div
          className={
            this.state.is_mobile
              ? "collapse navbar-collapse show"
              : "collapse navbar-collapse"
          }
        >
          {this.formateMenuItems(this.state.loginType)}
          {this.formateUserMenu(this.state.userType)}
        </div>
      </nav>
    );
  }
}

export default withRouter(Navbar);
