import React from "react";
import {
  GroupAddNew,
  GroupUpdate,
  GroupDelete,
  GroupVehicleAdd,
  GroupSelectBySubscriber,
  GroupVehicleSelectBySubscriber,
  GroupVehicleRemove
} from "../businessjs/VehicleGroupFn";

import jwt_decode from "jwt-decode";
import {
  AuthHelperAccessCheck,
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText
} from "../businessjs/AuthHelper";

import Navbar2 from "../compon-styled/Navbar";
import ToastAlert from "./ToastAlert";
import VehicleGroupItem from './VehicleGroupItem'

class VehicleGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group_name: "",     
      vehicleSelectValue: {},
      allVehicleItems: [],
      allItems: [],
      showItems: [],
      toastItems: []
    };

    this.searchHandler = this.searchHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      showItems = this.state.allItems.filter(el => {
        let searchValue = el.group_name.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      showItems: showItems
    });
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];

      this.setState({
        subscriber_id: decodedUser.subscriber_id,
        account_type: decodedUser.account_type,
        company_name: decodedUser.company_name,
        email: decodedUser.email,
        role: decodedUser.role,
        roleText: AuthHelperGerSubscriberRoleText(decodedUser.role),
        executive_id:decodedUser.autoid
      });

      const regFormInputes = {
        subscriber_id: decodedUser.subscriber_id,
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId,
        executive_id:decodedUser.autoid
      };

      GroupSelectBySubscriber(regFormInputes).then(res => {       
        if (res) {
          if (Array.isArray(res)) {
            this.setState({ allItems: res });
            this.setState({ showItems: res });
          } else {
            AuthHelperAccessCheck(res);
          }
        }
      });

      GroupVehicleSelectBySubscriber(regFormInputes).then(res => {
        if (res) {
          if (Array.isArray(res)) {            
            this.setState({ allVehicleItems: res });
          } else {
            AuthHelperAccessCheck(res);
          }
        }
      });
    }
  }

  componentWillUnmount() {}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const regFormInputes = {
      subscriber_id: this.state.subscriber_id,
      group_name: this.state.group_name,  
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    GroupAddNew(regFormInputes).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);

        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          const regFormInputes = {
            subscriber_id: this.state.subscriber_id,
            user_token: localStorage.apiAuthAccessKey,
            user_token_id: localStorage.apiAuthAccessId
          };

          GroupSelectBySubscriber(regFormInputes).then(res => {
            if (res) {
              if (Array.isArray(res)) {
                this.setState({
                  group_name: "",                 
                  allItems: res,
                  showItems: res
                });
              } else {
                AuthHelperAccessCheck(res);
              }
            }
          });
        }
      }
    });
  }

  handleOnDelete = itemObject => {
    const newFiltedItems = this.state.showItems.filter(
      c => c.autoid !== itemObject.autoid
    );

    const newAllItems = this.state.allItems.filter(
      c => c.autoid !== itemObject.autoid
    );

    this.setState({ allItems: newAllItems });
    this.setState({ showItems: newFiltedItems });

    let temObj = {};
    temObj.autoid = itemObject.autoid;
    temObj.subscriber_id = itemObject.subscriber_id;
    temObj.user_token = localStorage.apiAuthAccessKey;
    temObj.user_token_id = localStorage.apiAuthAccessId;

    GroupDelete(temObj).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);
      }
    });
  };

  handleOnUpdate = itemObject => {
    GroupUpdate(itemObject).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          const regFormInputes = {
            subscriber_id: this.state.subscriber_id,
            user_token: localStorage.apiAuthAccessKey,
            user_token_id: localStorage.apiAuthAccessId
          };

          GroupSelectBySubscriber(regFormInputes).then(res => {
            if (res) {
              this.setState({ allItems: res });
              this.setState({ showItems: res });
            }
          });
        }
      }
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  handleOnVehicleAdd = formInputs => {
    GroupVehicleAdd(formInputs).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);

        const regFormInputes = {
          subscriber_id: this.state.subscriber_id,
          user_token: localStorage.apiAuthAccessKey,
          user_token_id: localStorage.apiAuthAccessId
        };
    
        GroupVehicleSelectBySubscriber(regFormInputes).then(res => {          
          if (res) {
            if (Array.isArray(res)) {
              this.setState({ allVehicleItems: res });
            } else {
              AuthHelperAccessCheck(res);
            }
          }
        });

      }
    });

    
  };

  handleOnVehicleRemove = actionObject => {
    //console.log('actionObject ', actionObject);
    const formInputes = {
      subscriber_id: this.state.subscriber_id,
      autoid: actionObject.autoid,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    GroupVehicleRemove(formInputes).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);
      }
    });

    const newFiltedItems = this.state.allVehicleItems.filter(
      c => c.autoid !== actionObject.autoid
    );

    this.setState({ allVehicleItems: newFiltedItems });
  };

  filterVehicles = groupId => {        
    if (this.state.allVehicleItems.length > 0) {
      let searcjQery = groupId,
        filteredDataRows = this.state.allVehicleItems.filter(el => {
          let searchValue = el.group_id;
          return searchValue === searcjQery;
        });

      return filteredDataRows;
    } else {
      return [];
    }
  };

  render() {
    // let contacts = this.state.showItems;

    return (
      <React.Fragment>
        <Navbar2 />

        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />
                  <div className=" col-12">
                    <div className="card mb-1">
                      <div className="card-header">
                        <h4 className="card-title">Add Group </h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <form className="form" onSubmit={this.onSubmit}>
                            <div className="form-body">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>Group Name</label>
                                      <div className="position-relative has-icon-left">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="group_name"
                                          required
                                          onChange={this.onChange}
                                          value={this.state.group_name}
                                        />
                                        <div className="form-control-position">
                                          <i className="ft-user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>                                
                                </div>
                              </div>

                              <div className="form-actions float-right mb-1">
                                <button
                                  type="button"
                                  className="btn btn-warning mr-1"
                                >
                                  <i className="ft-x" /> Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-check-square-o" /> Add New
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-12">
                    <div className="card">
                      <div className="card-header">
                        <input
                          type="text"
                          className="card-search float-right"
                          placeholder="Search... "
                          onChange={this.searchHandler}
                        />
                        <h4 className="card-title">Group List</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <div className="filterlist">
                            <ul>
                              {this.state.showItems.map(el => {
                                return (
                                  <VehicleGroupItem
                                    key={el.autoid}
                                    itemObject={el}
                                    itemVehicles={this.filterVehicles(
                                      el.autoid
                                    )}
                                    onDelete={this.handleOnDelete}
                                    onUpdate={this.handleOnUpdate}
                                    onVehicleAdd={this.handleOnVehicleAdd}
                                    onVehicleRemove={this.handleOnVehicleRemove}
                                  />
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default VehicleGroup;
