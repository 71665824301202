import React from "react";
import { SubscriberSelectActive } from "../businessjs/SubscribersFn";
import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";
import {
  AuthHelperAccessCheck,
  AuthHelperGerSubscriberTypeText
} from "../businessjs/AuthHelper";
import Api from "../businessjs/APIConfig";

const proxyServer = Api.serverapi;

class SubscriberList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allDataRows: [],
      filteredDataRows: [],
      toastItems: []
    };

    this.searchHandler = this.searchHandler.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      filteredDataRows = this.state.allDataRows.filter(el => {
        let searchValue = el.company_name.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      filteredDataRows: filteredDataRows
    });
  }

  componentDidMount() {
    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    };

    SubscriberSelectActive(fnInputes).then(res => {    
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allDataRows: res });
          this.setState({ filteredDataRows: res });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  }

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  formateImage() {}

  checkIsEmailVerified = () => {
    return (
      <button type="button" className="btn btn-default btn-sm">
        Resend
      </button>
    );
  };

  render() {
    let showDataRows = this.state.filteredDataRows;

    return (
      <React.Fragment>
        <Navbar />
        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <h1> Subscribers </h1>
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />

                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <input
                          type="text"
                          className="card-search float-right"
                          placeholder="Search... "
                          onChange={this.searchHandler}
                        />
                        <h4 className="card-title"> List</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          {showDataRows.map((item, i) => (
                            <React.Fragment key={i}>
                              <div className="row">
                                <div className="col-12">
                                  <img
                                    className="subscriberlist_img mr-2"
                                    src={
                                      item.logo_image
                                        ? item.logo_image
                                        : proxyServer +
                                          "images/subscriber/no_image.jpg"
                                    }
                                    alt="Card cap"
                                  />
                                  <b className="d-block">
                                    {" "}
                                    {item.company_name}{" "}
                                  </b>
                                  <span className="d-block">
                                    {" "}
                                    Type :{" "}
                                    <b>
                                      {AuthHelperGerSubscriberTypeText(
                                        item.account_type
                                      )}
                                    </b>
                                  </span>

                                  <div className="btn-group float-right">
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                    >
                                      Edit
                                    </button>

                                    {item.verified === 0
                                      ? this.checkIsEmailVerified()
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                          <center>
                            {showDataRows.length ? "" : "No records available"}
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default SubscriberList;
