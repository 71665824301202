import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "executive";

export const ExecutiveAddNew = objItem => {
  return axios
    .post(proxyServer + "/new", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const ExecutiveUpdate = objItem => {
  return axios
    .post(proxyServer + "/update", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const ExecutiveUpdateStatus = objItem => {
  return axios
    .post(proxyServer + "/updatestatus", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const ExecutiveSelectBySubscriber = objItem => {
  return axios
    .post(proxyServer + "/getbysubscriber", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const ExecutiveSelectForGroupAssign = objItem => {
  return axios
    .post(proxyServer + "/getbysubscriberforgroupassign", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


export const ExecutiveSelectById = objItem => {
  return axios
    .post(proxyServer + "/getbyexecutive", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};