

import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "logmessages";

export const LogMessagesSelectAll = objItem => {
    return axios
      .post(proxyServer + "/getall", { objItem })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };