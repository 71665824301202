import React from "react";
import {
  DriverAddNew,
  DriverUpdate,
  DriverDelete,
  DriverVehicleAdd,
  DriverSelectBySubscriber,
  DriverVehicleSelectBySubscriber,
  DriverVehicleRemove
} from "../businessjs/DriverFn";

import jwt_decode from "jwt-decode";
import {
  AuthHelperAccessCheck,
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText
} from "../businessjs/AuthHelper";

import Navbar2 from "../compon-styled/Navbar";
import ToastAlert from "./ToastAlert";
import DriverVehicleSelect from "../compon-controls/DriverVehicleSelect";
import XButton from "../compon-controls/XButton";

class DriverItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
      editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" },
      assignVehiclesBtn: {
        cssClass: "btn btn-default btn-sm",
        btnText: "Assign Vehicle"
      },
      editMode: false,
      selectedVehicles: [],
      subscriber_id: props.itemObject.subscriber_id,
      driver_name: props.itemObject.driver_name,
      employee_code: props.itemObject.employee_code,
      rfid_code: props.itemObject.rfid_code,
      errorText: ""
    };

    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
    } else {
      this.props.history.push("/login");
    }
  }

  onDelete(e) {
    e.preventDefault();

    switch (this.state.deleteBtn.btnText) {
      case "Delete":
        this.setState({
          deleteBtn: {
            cssClass: "btn btn-danger btn-sm",
            btnText: "Confirm ?"
          },
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });
        break;
      case "Update":
        if (this.state.driver_name === "") {
          this.setState({ errorText: "The name is mandatory " });
          return;
        }

        let temObj = this.props.itemObject;
        temObj.driver_name = this.state.driver_name;
        temObj.employee_code = this.state.employee_code;
        temObj.rfid_code = this.state.rfid_code;
        temObj.user_token = localStorage.apiAuthAccessKey;
        temObj.user_token_id = localStorage.apiAuthAccessId;

        this.props.onUpdate(temObj);

        this.setState({
          editMode: false,
          deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
          editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
        });

        break;
      case "Confirm ?":
        this.props.onDelete(this.props.itemObject);

        break;
      default:
        this.setState({
          deleteBtn: {
            cssClass: "btn btn-danger btn-sm",
            btnText: "Confirm ?"
          },
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });

        break;
    }
  }

  onEdit(e) {
    if (this.state.editBtn.btnText === "Cancel") {
      this.setState({
        editMode: false,
        deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
        editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
      });
    } else if (this.state.editBtn.btnText === "Edit") {
      this.setState({
        editMode: true,
        deleteBtn: { cssClass: "btn btn-primary btn-sm", btnText: "Update" },
        editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" },
        driver_name: this.props.itemObject.driver_name,
        employee_code: this.props.itemObject.employee_code,
        rfid_code: this.props.itemObject.rfid_code
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onAssignVehicleClick = e => {
    if (this.state.assignVehiclesBtn.btnText === "Cancel") {
      this.setState({
        editMode: false,
        assignVehiclesBtn: {
          cssClass: "btn btn-default btn-sm",
          btnText: "Assign Vehicle"
        }
      });
    } else {
      this.setState({
        editMode: "assign",
        assignVehiclesBtn: {
          cssClass: "btn btn-warning btn-sm",
          btnText: "Cancel"
        }
      });
    }
  };

  onAssignVehicleAddClick = e => {
    if (this.state.selectedVehicles.length > 0) {
      const formInputes = {
        subscriber_id: this.props.itemObject.subscriber_id,
        driver_id: this.props.itemObject.autoid,
        vehicles: this.state.selectedVehicles,
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId,
        updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
      };

      this.props.onVehicleAdd(formInputes);

      this.setState({ selectedVehicles: [] });

    }
  };

  handleOnDriverVehicleChange = selectedOptions => {
    this.setState({ selectedVehicles: selectedOptions });
  };

  handleXButtonVehicle = actionObject => {
    this.props.onVehicleRemove(actionObject);
  };

  formateVehicleList(vehciels) {
    return (
      <React.Fragment>
        <br />
        <div className="row">
          {vehciels.map(el => {
            return (
              <React.Fragment key={el.autoid}>
                <div className="col-md-3 col-12 p-2">
                  <div className={"border border-"+ (el.statuscode ===1? "primary" : "secondary") +" w-100 p-2 rounded pb-3"}>
                    <XButton
                      valueObject={el}
                      onAction={this.handleXButtonVehicle}
                    />

                    {el.title}  
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  formateBody(mode) {
    if (mode === true) {
      return (
        <React.Fragment>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <span>
                  <small>Name </small>
                  <br />

                  <input
                    type="text"
                    className="form-control"
                    name="driver_name"
                    onChange={this.onChange}
                    required
                    value={this.state.driver_name}
                  />
                </span>
              </div>

              <div className="col-md-3 col-12">
                <span className="phone">
                  <small>Emp Code </small>
                  <br />

                  <input
                    type="text"
                    className="form-control"
                    name="employee_code"
                    onChange={this.onChange}
                    required
                    value={this.state.employee_code}
                  />
                </span>
              </div>

              <div className="col-md-3 col-12">
                <span className="phone">
                  <small>RFID </small>
                  <br />
                  {this.props.itemObject.rfid_code}
                </span>
              </div>
            </div>
          </div>
          <label className="errorlabel"> {this.state.errorText}</label>
        </React.Fragment>
      );
    } else if (mode === "assign") {
      return (
        <React.Fragment>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <span>
                  <small>Name </small>
                  <br />
                  {this.props.itemObject.driver_name}
                </span>
              </div>

              <div className="col-md-3 col-12">
                <span className="phone">
                  <small>Emp Code </small>
                  <br />
                  {this.props.itemObject.employee_code}
                </span>
              </div>

              <div className="col-md-3 col-12">
                <span className="phone">
                  <small>RFID </small>
                  <br />
                  {this.props.itemObject.rfid_code}
                </span>
              </div>
            </div>
            <div className="row border p-2">
              <div className="col-md-2 col-12">
                <label>Select Vehicles </label>
              </div>
              <div className="col-md-6 col-12">
                <DriverVehicleSelect
                controlValue={this.state.selectedVehicles}
                  vehicleSelectValue={this.state.vehicleSelectValue}
                  onChange={this.handleOnDriverVehicleChange}
                  subscriber_id={this.state.subscriber_id}
                  addedVehicles={this.props.itemVehicles}
                />
              </div>
              <div className="col-md-4 col-12">
                <button
                  type="button"
                  onClick={this.onAssignVehicleAddClick}
                  className="btn btn-primary btn-sm"
                >
                  Add Vehicle
                </button>

                <button
                  type="button"
                  onClick={this.onAssignVehicleClick}
                  className={this.state.assignVehiclesBtn.cssClass}
                >
                  {this.state.assignVehiclesBtn.btnText}
                </button>
              </div>
            </div>
            {this.formateVehicleList(this.props.itemVehicles)}
          </div>
        </React.Fragment>
      );
    } else if (mode === false) {
      return (
        <React.Fragment>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <span>
                  <small>Name </small>
                  <br />
                  {this.props.itemObject.driver_name}
                </span>
              </div>

              <div className="col-md-3 col-12">
                <span className="phone">
                  <small>Emp Code </small>
                  <br />
                  {this.props.itemObject.employee_code}
                </span>
              </div>

              <div className="col-md-3 col-12">
                <span className="phone">
                  <small>RFID </small>
                  <br />
                  {this.props.itemObject.rfid_code}
                </span>
              </div>
            </div>
            {this.formateVehicleList(this.props.itemVehicles)}
          </div>
        </React.Fragment>
      );
    }
  }

  formateButtons(mode) {
    if (mode === "assign") {
      return <React.Fragment />;
    } else {
      return (
        <React.Fragment>
          <button
            type="button"
            onClick={this.onAssignVehicleClick}
            className={this.state.assignVehiclesBtn.cssClass}
          >
            {this.state.assignVehiclesBtn.btnText}
          </button>

          <button
            type="button"
            onClick={this.onEdit}
            className={this.state.editBtn.cssClass}
          >
            {this.state.editBtn.btnText}
          </button>
          <button
            type="button"
            onClick={this.onDelete}
            className={this.state.deleteBtn.cssClass}
          >
            {this.state.deleteBtn.btnText}
          </button>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <li>
        <div className="row ">
          <div className="w-100">
            <div className="btn-group float-right">
              {this.formateButtons(this.state.editMode)}
            </div>
          </div>
        </div>

        <div className="row">{this.formateBody(this.state.editMode)}</div>
      </li>
    );
  }
}

class Driver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      driver_name: "",
      emp_code: "",
      rfid_code: "",
      vehicleSelectValue: {},
      allVehicleItems: [],
      allItems: [],
      showItems: [],
      toastItems: [],
      readertype: "5"
    };

    this.searchHandler = this.searchHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      showItems = this.state.allItems.filter(el => {
        let searchValue = el.driver_name.toLowerCase();
        let searchValue2 = el.employee_code.toLowerCase();
        return (searchValue.indexOf(searcjQery) !== -1 || searchValue2.indexOf(searcjQery) !== -1 ) ;
      });
    this.setState({
      showItems: showItems
    });
  }

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];

      this.setState({
        subscriber_id: decodedUser.subscriber_id,
        account_type: decodedUser.account_type,
        company_name: decodedUser.company_name,
        email: decodedUser.email,
        role: decodedUser.role,
        roleText: AuthHelperGerSubscriberRoleText(decodedUser.role)
      });

      const regFormInputes = {
        subscriber_id: decodedUser.subscriber_id,
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId
      };

      DriverSelectBySubscriber(regFormInputes).then(res => {
        if (res) {
          if (Array.isArray(res)) {
            this.setState({ allItems: res });
            this.setState({ showItems: res });
          } else {
            AuthHelperAccessCheck(res);
          }
        }
      });

      DriverVehicleSelectBySubscriber(regFormInputes).then(res => {
        if (res) {
          if (Array.isArray(res)) {
            this.setState({ allVehicleItems: res });
          } else {
            AuthHelperAccessCheck(res);
          }
        }
      });
      
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const rfidcodewithlimit = "00000000" + this.state.rfid_code;

    let new_code = rfidcodewithlimit.substring(rfidcodewithlimit.length - parseInt( this.state.readertype, 10 ) );

    this.setState({  rfid_code: new_code  });
    
    const regFormInputes = {
      subscriber_id: this.state.subscriber_id,
      driver_name: this.state.driver_name,
      employee_code: this.state.emp_code,
      rfid_code: new_code,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    DriverAddNew(regFormInputes).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);

        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          const regFormInputes = {
            subscriber_id: this.state.subscriber_id,
            user_token: localStorage.apiAuthAccessKey,
            user_token_id: localStorage.apiAuthAccessId
          };

          DriverSelectBySubscriber(regFormInputes).then(res => {
            if (res) {
              if (Array.isArray(res)) {
                this.setState({
                  driver_name: "",
                  emp_code: "",
                  rfid_code: "",
                  allItems: res,
                  showItems: res
                });
              } else {
                AuthHelperAccessCheck(res);
              }
            }
          });
        }
      }
    });
  }

  handleOnDelete = itemObject => {
    const newFiltedItems = this.state.showItems.filter(
      c => c.autoid !== itemObject.autoid
    );

    const newAllItems = this.state.allItems.filter(
      c => c.autoid !== itemObject.autoid
    );

    this.setState({ allItems: newAllItems });
    this.setState({ showItems: newFiltedItems });

    let temObj = {};
    temObj.autoid = itemObject.autoid;
    temObj.subscriber_id = itemObject.subscriber_id;
    temObj.user_token = localStorage.apiAuthAccessKey;
    temObj.user_token_id = localStorage.apiAuthAccessId;

    DriverDelete(temObj).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);
      }
    });
  };

  handleOnUpdate = itemObject => {
    DriverUpdate(itemObject).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          const regFormInputes = {
            subscriber_id: this.state.subscriber_id,
            user_token: localStorage.apiAuthAccessKey,
            user_token_id: localStorage.apiAuthAccessId
          };

          DriverSelectBySubscriber(regFormInputes).then(res => {
            if (res) {
              this.setState({ allItems: res });
              this.setState({ showItems: res });
            }
          });
        }
      }
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  handleOnVehicleAdd = formInputs => {
    DriverVehicleAdd(formInputs).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);

        const regFormInputes = {
          subscriber_id: this.state.subscriber_id,
          user_token: localStorage.apiAuthAccessKey,
          user_token_id: localStorage.apiAuthAccessId
        };
    
        DriverVehicleSelectBySubscriber(regFormInputes).then(res => {
          if (res) {
            if (Array.isArray(res)) {
              this.setState({ allVehicleItems: res });
            } else {
              AuthHelperAccessCheck(res);
            }
          }
        });

      }
    });

  };

  handleOnVehicleRemove = actionObject => {  
    const formInputes = {
      subscriber_id: this.state.subscriber_id,
      autoid: actionObject.autoid,
      driver_id: actionObject.driver_id,
      vehicle_id: actionObject.vehicle_id,
      device_type: actionObject.device_type,
      cancel_only: actionObject.statuscode === 1 ? 1 : 0 ,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    DriverVehicleRemove(formInputes).then(res => {
      if (res) {
       
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);
      }
    });

    const newFiltedItems = this.state.allVehicleItems.filter(
      c => c.autoid !== actionObject.autoid
    );

    this.setState({ allVehicleItems: newFiltedItems });
  };

  filterVehicles = driverId => {
    if (this.state.allVehicleItems.length > 0) {
      let searcjQery = driverId,
        filteredDataRows = this.state.allVehicleItems.filter(el => {
          let searchValue = el.driver_id;
          return searchValue === searcjQery;
        });

      return filteredDataRows;
    } else {
      return [];
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navbar2 />

        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />
                  <div className=" col-12">
                    <div className="card mb-1">
                      <div className="card-header">
                        <h4 className="card-title">Add Driver </h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <form className="form" onSubmit={this.onSubmit}>
                            <div className="form-body">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>Driver Name</label>
                                      <div className="position-relative has-icon-left">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="driver_name"
                                          required
                                          onChange={this.onChange}
                                          value={this.state.driver_name}
                                        />
                                        <div className="form-control-position">
                                          <i className="ft-user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-4">
                                    <div className="form-group">
                                      <label>Emp Code </label>
                                      <div className="position-relative has-icon-left">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="emp_code"
                                          onChange={this.onChange}
                                          value={this.state.emp_code}
                                          required
                                        />
                                        <div className="form-control-position">
                                          <i className="ft-user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-2">
                                    <div className="form-group">
                                      <label>RFID Code </label>
                                      <div className="position-relative has-icon-left">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="rfid_code"
                                          onChange={this.onChange}
                                          value={this.state.rfid_code}
                                          required
                                        />
                                        <small className="text danger" >{this.state.readertype} digit only  </small>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="col-2">
                                    <div className="form-group">
                                      <label> Reader Type </label>
                                      <div className="position-relative has-icon-left">
                                         <select className="form-control" 
                                         name="readertype"
                                         onChange={this.onChange}
                                         value={this.state.readertype} >
                                           <option value="5" >iClass(5digit)</option>
                                           <option value="8">Regular(8digit)</option>
                                         </select>
                                        <div className="form-control-position">
                                          <i className="ft-user" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>

                              <div className="form-actions float-right mb-1">
                                <button
                                  type="button"
                                  className="btn btn-warning mr-1"
                                >
                                  <i className="ft-x" /> Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-check-square-o" /> Add New
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-12">
                    <div className="card">
                      <div className="card-header">
                        <input
                          type="text"
                          className="card-search float-right"
                          placeholder="Search... "
                          onChange={this.searchHandler}
                        />
                        <h4 className="card-title">Driver List</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <div className="filterlist">
                            <ul>
                              {this.state.showItems.map(el => {
                                return (
                                  <DriverItem
                                    key={el.autoid}
                                    itemObject={el}
                                    itemVehicles={this.filterVehicles(
                                      el.autoid
                                    )}
                                    onDelete={this.handleOnDelete}
                                    onUpdate={this.handleOnUpdate}
                                    onVehicleAdd={this.handleOnVehicleAdd}
                                    onVehicleRemove={this.handleOnVehicleRemove}
                                  />
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default Driver;
