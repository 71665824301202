import axios from "axios";
import Api from "./APIConfig";

let proxyServer = Api.serverapi;

export const SupportMemberAddNew = objItem => {
  return axios
    .post(proxyServer + "supportmember/new", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log("error", err);
      return err;
    });
};

export const SupportMemberGetAll = objItem => {
  return axios
    .post(proxyServer + "supportmember/getall", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const SupportMemberUpdate = objItem => {
  //console.log("update", objItem);
  return axios
    .post(proxyServer + "supportmember/update", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const SupportMemberDelete = objItem => {
  return axios
    .post(proxyServer + "supportmember/delete", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const SupportMemberAuth = objItem => {
  return axios
    .post(proxyServer + "prontologin/supportauth", {
      loginemail: objItem.loginemail,
      loginpassword: objItem.loginpassword
    })
    .then(res => {
      localStorage.setItem("apiAuthAccessKey", res.data.accessToken);
      localStorage.setItem("apiAuthAccessId", res.data.userdata_id);

      return res.data;
    })
    .catch(err => {
      console.log(err);
    });
};
