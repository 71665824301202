
import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "driver";

export const DriverAddNew = objItem => {
    return axios
      .post(proxyServer + "/new", { objItem })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };


export const DriverUpdate = objItem => {
  return axios
    .post(proxyServer + "/update", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


export const DriverDelete = objItem => {
  return axios
    .post(proxyServer + "/delete", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


export const DriverUpdateStatus = objItem => {
  return axios
    .post(proxyServer + "/updatestatus", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
  

export const DriverSelectBySubscriber = objItem => {
  return axios
    .post(proxyServer + "/getbysubscriber", { objItem } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

// Driver vehicles ========================================


export const DriverVehicleAdd = objItem => {
  return axios
    .post(proxyServer + "/vehicle_add", { objItem } )
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


export const DriverVehicleSelectBySubscriber = objItem => {
  return axios
    .post(proxyServer + "/getvehiclesbysubscriber", { objItem } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


export const DriverVehicleRemove = objItem => {
  return axios
    .post(proxyServer + "/vehicle_remove", { objItem } )
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};