import axios from "axios";
import Api from "./APIConfig";

let proxyServer = Api.serverapi;



export const SubscribersChangeImage = objItem => {
  
  return axios.post(proxyServer + 'subscriber/changeimage', objItem , {
    onUploadProgress: progressEvent => {
      console.log('imageuploading... '+ (progressEvent.loaded / progressEvent.total) );
    }
  })
   .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
 

 
export const SubscribersRegistration = objItem => {
  return axios
    .post(proxyServer + "prontologin/registration", {
        account_type: objItem.account_type,
        country: objItem.country,
        first_name: objItem.first_name,
        last_name: objItem.last_name,
        email: objItem.email,
        password: objItem.password
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const SubscribersAuth = objItem => {

   

  return axios
    .post(proxyServer + "prontologin/subscriberauth", {
      loginemail: objItem.loginemail,
      loginpassword: objItem.loginpassword
    })
    .then(res => {
     
      localStorage.setItem("apiAuthAccessKey", res.data.accessToken);
      localStorage.setItem("apiAuthAccessId", res.data.userdata_id);
      localStorage.setItem("apiAuthRole", res.data.userdata.role)
      
      return res.data;
    })
    .catch(err => {
      console.log(err);
    });
};

export const SubscriberGet = objItem => {
  return axios
    .post(proxyServer + "subscriber/get", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      //console.log(err);
      return err;
    });
};

export const SubscriberGetAll=objItem =>{
  console.log('SubscriberGetAll url ', (proxyServer + "subscriber/getAll"));
  return axios.get(proxyServer + "subscriber/getAll",{
    user_token_id: objItem.user_token_id,
      user_token: objItem.user_token
  })
  .then(res=>{
    return res.data;
  })
  .catch(err => {
      //console.log(err);
      return err;
  })
}

export const SubscriberUpdate = objItem => {
   
  /*
      autoid: this.state.autoid,
      company_name: this.state.company_name,
      license_number: this.state.license_number,
      default_currency: this.state.default_currency,
      country: this.state.country,
      shipment_preference: this.state.shipment_preference,
      registration_type: this.state.registration_type,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
  */

  return axios
    .post(proxyServer + "subscriber/updateprofile", {objItem,
      autoid: objItem.autoid,
      company_name: objItem.company_name,
      license_number: objItem.license_number,
      default_currency: objItem.default_currency,
      country: objItem.country,
      shipment_preference: objItem.shipment_preference,
      registration_type: objItem.registration_type,
      user_token: objItem.user_token,
      user_token_id: objItem.user_token_id
    } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      //console.log(err);
      return err;
    });
};


export const SubscriberSelectActive = objItem => {
  return axios
    .post(proxyServer + "subscriber/getactive", { objItem } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

