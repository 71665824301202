import React, { Component } from "react";
import { SubscribersAuth } from "../businessjs/SubscribersFn";

import { AuthHelperValidateOverToken } from "../businessjs/AuthHelper";

import { AppAuthTokenCheck } from "../businessjs/AppAuthTokenFn";
import { Redirect } from "react-router-dom";

class GuestLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginemail: "",
      loginpassword: "",
      errorText: "",
      btnSignInText: "Sign In",
      redirectValue: 0
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const token = localStorage.apiAuthAccessKey;

    try {
      if (AuthHelperValidateOverToken("layoutsubscriber")) {
        const regFormInputes = {
          user_token: localStorage.apiAuthAccessKey,
          user_token_id: localStorage.apiAuthAccessId
        };

        AppAuthTokenCheck(regFormInputes).then(res => {
          if (res) {
            if (res.status === "success") {
              this.setState({
                redirectValue: 1
              });
              //this.props.history.push(`/subscriberhome`);              
            }
          }
        });
      }
    } catch (e) {
      console.log(" identity not valid", token);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    // Router.push('/supportcenter');

    const regFormInputes = {
      loginemail: this.state.loginemail,
      loginpassword: this.state.loginpassword
    };

    this.setState({ errorText: "", btnSignInText: "Progressing..." });

    SubscribersAuth(regFormInputes).then(res => {
      this.setState({ btnSignInText: "Sign In" });
      if (res) {
        //this.props.history.push(`/profile`);
        //console.log("on submit data", res);

        if (res.status === "success") {
          //this.props.onLoginSuccess(res);
          //this.props.history.push(`/vehiclelist`);
          this.setState({
            redirectValue: 2
          });
        } else {
          console.log("login error", res);
          if (res.status) {
            this.setState({
              errorText: res.status.toUpperCase() + ": " + res.message
            });
          }
        }
      }
    });
  }

  render() {
    const { redirectValue } = this.state;
    
    if (redirectValue === 1) return <Redirect to="/subscriberhome" />;
    else if (redirectValue === 2) return <Redirect to="/vehicles" />;
    else {
      return (
        <React.Fragment>
          <div className="page-wrap">
            <div className="header-signup cf">
              <div className="logo-signup">
                <a href="/">
                  <span className="logotext">TFMS </span>
                </a>
              </div>
              <div className="rgt-phone">
                <a href="https://www.cloudtel.qa/">
                  <i>
                    <img src="/images/phone-icon.png" alt="icon" />
                  </i>
                  Contact Us
                </a>
              </div>
            </div>
            <div className="signup-form-sec cf">
              <div className="signup-form-outer">
                <div className="lft-form-bx signin-bx">
                  <h1>Sign In</h1>
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group cf">
                      <input
                        type="email"
                        className="img-set email-icon"
                        placeholder="Email"
                        name="loginemail"
                        onChange={this.onChange}
                        value={this.state.loginemail}
                        required
                      />
                    </div>

                    <div className="form-group cf">
                      <input
                        type="password"
                        className="img-set pass-icon"
                        placeholder="Password"
                        name="loginpassword"
                        value={this.state.loginpassword}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <div className="form-group fg-bx cf" />
                    <div className="form-group reg-btn cf">
                      <button type="submit" className="btn btn-success btn-lg">
                        <i>
                          <img src="/images/signin-icon.png" alt="icon" />
                        </i>
                        {this.state.btnSignInText}
                      </button>
                    </div>

                    {this.state.succesText}
                    {this.state.errorText}

                    <div className="form-group or-txt">
                      <em>OR</em>
                    </div>

                    <div className="form-group already-link cf">
                      <small>
                        Don’t have account?
                        <a href="https://www.cloudtel.qa/"> Contact Us </a>
                      </small>
                      <p>By Signing In, you agree to our Terms & Policy </p>
                    </div>
                  </form>
                </div>
                <div className="rgt-signup-txts">
                  <small>An Online Place for </small>
                  <h2>Fleet Monitoring!</h2>
                </div>
              </div>
            </div>
            <footer className="footer-signup  cf">
              <p> Powered by Cloud Telematics</p>
            </footer>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default GuestLogin;
