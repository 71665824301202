import React, { Component } from "react";

class ToastItem extends React.Component {
  render() {
    return (
      <div 
      className={this.formatAlertClass()}
      role="alert">
        <button
          type="button"
          onClick={() => this.props.onClose(this.props.itemObject)}
          className="btn btn-secondary btn-sm float-right mr-1"
        >
          Close
        </button>
        <div>{this.props.itemObject.message}</div>
      </div>
    );
  }

  formatAlertClass()
  {
    return this.props.itemObject.type === 'success'? 'alert alert-success' : 'alert alert-danger';
  }

}

class ToastAlert extends Component {
  constructor(props) {
    super(props);

    this.state = {
        allItems: []
    }
  }

  /*
  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(c => c.id !== itemObject.id);
    this.setState({toastItems});
  };
*/

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    /*if (nextProps.startTime !== this.state.startTime) {
      this.setState({ startTime: nextProps.startTime });
    }*/

    if(nextProps.toastItems !== this.state.allItems)
    {
        this.setState({ allItems : nextProps.toastItems });
    }
     
  }

  render() {
    let items = this.props.toastItems ;
    return (
      <div className="toastnotification-container">
        {items.map((el, index) => (
          <ToastItem
            key={el.id}
            onClose={() => this.props.onItemClose(el)}
            itemObject={el}
          />
        ))}
 
   

      </div>
    );
  }

  testTost()
  {
    console.log('test tost 789');
  }
}

export default ToastAlert;
