import React from "react";
import {
  SubscriberTypes,
  VehicleTypes,
  VehicleAddNew,
  VehicleUpdate
} from "../businessjs/VehicleFn";
import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import VehicleTypeSelect from "../compon-controls/VehicleTypeSelect";
import { Route } from "react-router-dom";

class VehicleAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriber_id: 0,
      title: "",
      plate_number: "",
      fleet_number: "",
      manufacture_name: "",
      model: "",
      year: "",
      fuel_type: "",
      engine: "",
      tyre: "",
      toastItems: [],
      allDataRows: [],
      filteredDataRows: [],
      sim_provider: "",
      sim: "",
      vehicleTypes: [],
      subscriberTypes: [],
      vehicle_type: 0,
      autoid: 0
    };
  }

  componentDidMount() {
    //   console.log("vehicle", this.props.location.state.item);
    if (this.props.location.state.type === "edit") {
      this.setState({
        subscriber_id: this.props.location.state.item.subscriber_id,
        vehicle_type: this.props.location.state.item.vehicle_type,
        title: this.props.location.state.item.title,
        plate_number: this.props.location.state.item.plate_number,
        fleet_number: this.props.location.state.item.fleet_number,
        manufacture_name: this.props.location.state.item.manufacture_name,
        model: this.props.location.state.item.model,
        year: this.props.location.state.item.year,
        fuel_type: this.props.location.state.item.fuel_type,
        engine: this.props.location.state.item.engine,
        tyre: this.props.location.state.item.tyre,
        sim_provider: this.props.location.state.item.simprovider,
        sim: this.props.location.state.item.sim,
        autoid: this.props.location.state.item.autoid
      });
    }
    SubscriberTypes({
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({
            label: opt.company_name,
            value: opt
          }));
          this.setState({ subscriberTypes: dbArray });
          //  console.log(" sub type==", this.state.subscriberTypes);
          this.setState({
            subscriber_id: this.getSubscriberType(this.state.subscriber_id)
          });
        }
      }
    });

    VehicleTypes({
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({ label: opt.title, value: opt }));
          this.setState({ vehicleTypes: dbArray });
          //  console.log("type==",this.state.vehicleTypes)
          this.setState({
            vehicle_type: this.getVehicleType(this.state.vehicle_type)
          });
        }
      }
    });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const regFormInputes = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      vehicle_type: this.state.vehicle_type.value.autoid,
      title: this.state.title,
      plate_number: this.state.plate_number,
      fleet_number: this.state.fleet_number,
      manufacture_name: this.state.manufacture_name,
      model: this.state.model,
      year: this.state.year,
      fuel_type: this.state.fuel_type,
      engine: this.state.engine,
      tyre: this.state.tyre,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      sim_provider: this.state.sim_provider,
      sim: this.state.sim,
      autoid: this.state.autoid,
      updateby: localStorage.apiAuthAccessId
    };

    if (this.props.location.state.type === "add") {
      VehicleAddNew(regFormInputes).then(res => {
        if (res.status === "success") {
          this.onToastItemAdd(res.status, res.message);
        }
      });
    } else if (this.props.location.state.type === "edit") {
      console.log("in edit", regFormInputes);
      VehicleUpdate(regFormInputes).then(res => {
        if (res) {
          if (res.status === "success") {
            this.onToastItemAdd(res.status, res.message);
          }
        }
      });
    }
  };

  getVehicleType(value) {
    if (this.state.vehicleTypes) {
      let filteredDataRows = this.state.vehicleTypes.filter(el => {
        return el.value.autoid === value;
      })[0];

      return filteredDataRows;
    }
  }

  getSubscriberType(value) {
    if (this.state.subscriberTypes) {
      let filteredDataRows = this.state.subscriberTypes.filter(el => {
        return el.value.autoid === value;
      })[0];

      return filteredDataRows;
    }
  }

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  cancelButton = () => {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="reset"
            className="btn btn-warning mr-1"
            onClick={() => {
              history.goBack({
                pathname: "/supportcentervehicle"
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  };

  render() {
    //  let displayVehicle = this.props.location.state.item;

    return (
      <React.Fragment>
        <Navbar />
        <main role="main">
          <section>
            <div className="container mt-4">
              {/* <div className="container"> */}
              <div className="row">
                <ToastAlert
                  toastItems={this.state.toastItems}
                  onItemClose={this.handleToastItemClose}
                />
                <div className=" col-md-8 offset-md-2">
                  <div className="card mb-1">
                    <div className="card-header">
                      <h4 className="card-title"> Form</h4>
                    </div>
                    <div className="card-content collpase show">
                      <div className="card-body">
                        <form className="form" onSubmit={this.onSubmit}>
                          <div className="form-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label>Subscriber</label>
                                  <div className="position-relative has-icon-left">
                                    <SubscriberSelect
                                      name="subscriber_id"
                                      value={this.state.subscriber_id}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Title</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="title"
                                      value={this.state.title}
                                      onChange={this.onChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Type</label>
                                  <div className="position-relative has-icon-left">
                                    <VehicleTypeSelect
                                      name="vehicle_type"
                                      value={this.state.vehicle_type}
                                      onChange={this.onChange}
                                      dataSource={this.state.vehicleTypes}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <div className="form-group">
                                  <label>Plate Number</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="plate_number"
                                      value={this.state.plate_number}
                                      onChange={this.onChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="col-md-6 col-12">
                                <div className="form-group">
                                  <label>Fleet Number</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="fleet_number"
                                      value={this.state.fleet_number}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Manufacture Name</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="manufacture_name"
                                      value={this.state.manufacture_name}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Model</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="model"
                                      value={this.state.model}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Year</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="year"
                                      value={this.state.year}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Fuel Type</label>
                                  <div className="position-relative has-icon-left">
                                    <select
                                      className="form-control"
                                      name="fuel_type"
                                      onChange={this.onChange}
                                      value={this.state.fuel_type}
                                    >
                                      <option value=""> Select </option>
                                      <option value="Petrol">Petrol</option>
                                      <option value="Diesel">Diesel</option>
                                      <option value="Gas">Gas</option>
                                      <option value="Other">Other</option>
                                    </select>
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Engine</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="engine"
                                      value={this.state.engine}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="col-md-4 col-12">
                                <div className="form-group">
                                  <label>Tyre</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tyre"
                                      value={this.state.tyre}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Sim</label>
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="sim"
                                      value={this.state.sim}
                                      onChange={this.onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Sim Provider</label>
                                  <div className="position-relative has-icon-left">
                                    <select
                                      className="form-control"
                                      name="sim_provider"
                                      onChange={this.onChange}
                                      value={this.state.sim_provider}
                                    >
                                      <option value=""> Select </option>
                                      <option value="Ooredoo">Ooredoo</option>
                                      <option value="Vodafone">Vodafone</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-actions right">
                              {this.cancelButton()}
                              <button type="submit" className="btn btn-primary">
                                <i className="fa fa-check-square-o" /> Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default VehicleAdd;
