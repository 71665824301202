
const proxyServer = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_SERVER_PROD : process.env.REACT_APP_SERVER_LOCAL

//const proxyServer = "http://localhost:5007/";
// const proxyServer = "https://api.tfmsworld.com/";
// const proxyServer = "https://apidev.tfmsworld.com/";
// const proxyServer = "http://apitfmsworldcom-env.4vwcpim3g2.eu-central-1.elasticbeanstalk.com/";

const config = {}

config.serverapi = proxyServer ;

module.exports = config; 

