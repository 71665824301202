import React, { Component } from "react";
import Select from "react-select";
import { VehicleSelectBySubscriber } from "../businessjs/VehicleFn";
import jwt_decode from "jwt-decode";

class DriverVehicleSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedOption: null, optionsArray: [] };
  }

  componentDidMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];

    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      subscriber_id: decodedUser.subscriber_id,
      executive_id: decodedUser.autoid
    };

    VehicleSelectBySubscriber(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let _tempaA = this.filteredVehicles(res);
          let dbArray = _tempaA.map(opt => ({
            label: opt.title,
            value: opt.autoid,
            itemObject: opt
          }));
          this.setState({
            selectedOption: this.props.value,
            optionsArray: dbArray
          });
        }
      }
    });
  }

  filteredVehicles = res => {
    let allValues = res;
    this.props.addedVehicles.forEach(element => {
      let tempNewArray = allValues.filter(function(el) {
        return el.autoid !== element.vehicle_id;
      });
      allValues = tempNewArray;
    });

    return allValues;
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.props.onChange(selectedOption);
  };

  render() {
    let displayVehicleList = this.state.optionsArray.filter(function(a) {
      if (!this[a.itemObject.plate_number]) {
        this[a.itemObject.plate_number] = true;
        return true;
      }
      return false;
    }, Object.create(null));

    return (
      <React.Fragment>
        {displayVehicleList.length ? (
          <button
            className="btn btn-light btn-sm"
            onClick={() => this.handleChange(displayVehicleList)}
          >
            Select All
          </button>
        ) : (
          ""
        )}

        <Select
          name={this.props.name}
          value={this.props.controlValue}
          isMulti={true}
          onChange={this.handleChange}
          options={displayVehicleList}
        />
      </React.Fragment>
    );
  }
}

export default DriverVehicleSelect;
