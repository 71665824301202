import React from "react";
import { BrakingAlertCount, BrakingAlerts } from "../businessjs/AlertFn";
import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/Navbar";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import jwt_decode from "jwt-decode";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";

import Pagination from "react-js-pagination";

import Datetime from "react-datetime";
import { VehicleForCombo } from "../businessjs/VehicleFn";
import CmbSelect from "../compon-controls/CmbSelect";
import { Route } from "react-router-dom";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class RptBrakingAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    let startDay = new Date();
    let endDay = new Date();

    //if it comes from vehicle list view
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.itemObject
    ) {
      startDay = this.props.location.state.itemObject.startDate;
      endDay = this.props.location.state.itemObject.endDate;
      this.vehicleId = this.props.location.state.itemObject.vehicle_id;
      this.subscriber_id = this.props.location.state.itemObject.subscriber_id;
    } else {
      startDay.setDate(startDay.getDate() - 7);
    }

    this.state = {
      allDataRows: [],
      filteredDataRows: [],
      toastItems: [],
      subscriber_id: 0,
      user_role: 3,
      start: 1,
      end: 10,
      activePage: undefined,

      itemsCountPerPage: 25,
      totalItemsCount: 0,
      vehicle: undefined,
      vehicles: [],
      startDate: startDay,
      endDate: endDay,
      loading: true
    };
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({ subscriber_id: decodedUser.subscriber_id,executive_id:decodedUser.autoid });
  }

  componentDidMount() {
    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      executive_id:this.state.executive_id
    };
    this.getVehicles(fnInputes);
    this.getBrakingAlertCount();
  }

  getVehicles = fnInputes => {
    VehicleForCombo(fnInputes).then(res => {
      if (res) {       
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({ label: opt.title, value: opt }));
          dbArray.splice(0, 0, { label: "All", value: { autoid: 0 } });

          let displayVehicleCombo = dbArray.filter(function(a) {
            if (!this[a.value.autoid]) {
              this[a.value.autoid] = true;
              return true;
            }
            return false;
          }, Object.create(null));
      
          //if it comes from vehicle list view
          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.itemObject
          ) {
            let filteredDataRows = displayVehicleCombo.filter(el => {
              return el.value.autoid === this.vehicleId;
            });

            this.setState({
              vehicle: filteredDataRows[0],
              vehicles: displayVehicleCombo
            });
          } else {
            this.setState({
              vehicles: displayVehicleCombo
            });
          }
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getBrakingAlertCount = () => {
    this.setState({ loading: true });
    const { startDate, endDate, vehicle } = this.state;
    let vehicleId = 0;
    if (vehicle) {
      vehicleId = vehicle.value.autoid;
    }

    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      startDate: startDate,
      endDate: endDate,
      vehicleId: vehicleId,
      executive_id:this.state.executive_id
    };

    BrakingAlertCount(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let alertCount = res[0].alertcount;
          if (alertCount > 0) {
            this.setState({ totalItemsCount: alertCount, activePage: 1 });
            this.getBrakingAlerts(1);
          } else {
            this.setState({
              allDataRows: [],
              filteredDataRows: [],
              totalItemsCount: 0,
              activePage: 1,
              loading: false
            });
          }
        } else {
          this.setState({ loading: false });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getBrakingAlerts = pageNumber => {
    const { startDate, endDate, vehicle, itemsCountPerPage } = this.state;
    let vehicleId = 0;
    if (vehicle) {
      vehicleId = vehicle.value.autoid;
    }

    let start = (pageNumber - 1) * this.state.itemsCountPerPage;
    //let end = start + this.state.itemsCountPerPage;
    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      startDate: startDate,
      endDate: endDate,
      vehicleId: vehicleId,
      startLimit: start,
      endLimit: itemsCountPerPage,
      executive_id:this.state.executive_id
    };

    BrakingAlerts(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          console.log("braking ", res);
          this.setState({ allDataRows: res });
          this.setState({ filteredDataRows: res, loading: false });

          if (!this.state.activePage) {
            this.setState({ totalItemsCount: res.length, activePage: 1 });
          } else {
          }
        } else {
          this.setState({ loading: false });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    this.getBrakingAlerts(pageNumber);
  };

  onStartDateChange = e => {
    this.setState({ startDate: e._d });
  };

  onEndDateChange = e => {
    this.setState({ endDate: e._d });
  };

  exportToExceldButton() {
    const ButtonExport = () => (
      <Route
        render={({ history }) => (
          <React.Fragment>
            <button
              type="button"
              className="btn btn-primary ml-2 fa fa-file-excel-o"
              onClick={() => {
                history.push({
                  pathname: "/exporttoexcel",
                  state: {
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    vehicle: this.state.vehicle,
                    totalItemsCount: this.state.totalItemsCount,
                    name: "Braking Alerts",
                    type: "braking"
                  }
                });
              }}
            />
          </React.Fragment>
        )}
      />
    );
    return <ButtonExport />;
  }

  render() {
    let showDataRows = this.state.filteredDataRows;  
    return (
      <React.Fragment>
        <Navbar />

        <section className="manage-colom">
          <div className="container">
            <main role="main">
              <section>
                <div className="mt-4" style={{ width: "100%" }}>
                  <div>
                    <h1> Braking Alerts </h1>
                    <div className="row">
                      <ToastAlert
                        toastItems={this.state.toastItems}
                        onItemClose={this.handleToastItemClose}
                      />

                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <div className="row">
                              <div className="col-3">
                                <div className="row">
                                  <label className="mr-3">Start :</label>
                                  <div style={{ width: "200px" }}>
                                    <Datetime
                                      defaultValue={this.state.startDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onStartDateChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="row">
                                  <label className="mr-3">End :</label>
                                  <div style={{ width: "200px" }}>
                                    <Datetime
                                      defaultValue={this.state.endDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onEndDateChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-5">
                                <div className="row">
                                  <label className="mr-3">Vehicle :</label>
                                  <div style={{ width: "200px" }}>
                                    <CmbSelect
                                      name="vehicle"
                                      value={this.state.vehicle}
                                      onChange={this.onChange}
                                      dataSource={this.state.vehicles}
                                    />
                                  </div>
                                  <div className="btn-group float-right">
                                    <button
                                      type="button"
                                      className="btn btn-primary ml-2 fa fa-search"
                                      onClick={this.getBrakingAlertCount}
                                    >
                                      <i className="fa" />
                                    </button>
                                    {this.exportToExceldButton()}
                                  </div>
                                  <div className="sweet-loading ml-2">
                                    <RiseLoader
                                      css={loaderCSS}
                                      sizeUnit={"px"}
                                      size={10}
                                      color={"#123abc"}
                                      loading={this.state.loading}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Vehicle Title</th>
                                  <th>Plate No</th>
                                  <th>Place</th>
                                  <th>Location</th>
                                  <th>Time</th>                                
                                  <th>Start Speed</th>
                                  <th>End Speed</th>
                                  <th>Duration</th> 
                                  <th>Rate</th>
                                  <th>Driver</th>
                                </tr>
                              </thead>
                              <tbody>
                                {showDataRows.map((Item, i) => (
                                  <React.Fragment key={i}>
                                    <tr>
                                      <td>{Item.title}</td>
                                      <td>{Item.plate_number}</td>
                                      <td>
                                        <a
                                          rel="noopener noreferrer"
                                          target="_blank"
                                          href={`https://www.google.com/maps/place/${
                                            Item.place
                                          }`}
                                        >
                                          {Item.place}
                                        </a>
                                      </td>
                                      <td>
                                        <a
                                          rel="noopener noreferrer"
                                          target="_blank"
                                          href={`https://www.google.com/maps/place/${
                                            Item.geo
                                          }`}
                                        >
                                          {Item.geo}
                                        </a>
                                      </td>
                                      <td>
                                        {AppHelper_DateAndTimeToString(
                                          Item.event_time
                                        )}
                                      </td>                                     
                                      <td>{Item.startspeed}</td>
                                      <td>{Item.endspeed}</td>

                                      { <td>{Item.duration}</td> }
                                      <td>{Item.rate}</td>
                                      <td>{Item.driver_name}</td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                            <center>
                              {showDataRows.length
                                ? ""
                                : "No records available"}
                            </center>
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className="col-12"
                            style={{ textAlign: "right" }}
                          >
                            <Pagination
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.itemsCountPerPage}
                              totalItemsCount={this.state.totalItemsCount}
                              pageRangeDisplayed={10}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default RptBrakingAlerts;
