import React, { Component } from "react";
//import Navbar from "./Navbar";
import Navbar from "../compon-styled/Navbar";
import GoogleMap from "google-map-react";
import { HistoryTrakingDataByVehicle } from "../businessjs/TrackingFn";

import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment-timezone";

import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

import { css } from "@emotion/core";
import { RiseLoader } from "react-spinners";
import Datetime from "react-datetime";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class HistoryTracking extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.earthRadius = 6371;

    let today = new Date();
    let endday = new Date();
    endday.setDate(today.getDate() - 1);

    this.dIndex = 0;

    let paramStart = undefined;
    let paramEnd = undefined;

    if (this.props.location.state.itemObject.start) {
      paramStart = new Date(this.props.location.state.itemObject.start);
      paramEnd = new Date(this.props.location.state.itemObject.end);
    }

    this.playButtonClass = "btn btn-sm btn-primary ml-2 fa fa-play";
    this.pauseButtonClass = "btn btn-sm btn-primary ml-2 fa fa-pause";
    this.state = {
      startDate: paramStart ? paramStart : endday,
      endDate: paramEnd ? paramEnd : today,

      map: null,
      maps: null,
      mapsLoaded: false,
      tripData: [], // full data from server
      historyCoordincates: [], // full line cordinates
      directionCoordinates: [], // sliced data

      playButtonClass: this.playButtonClass,
      speedText: "",
      kmText: "",
      tripText: "",
      errorAlertClass: "alert alert-danger invisible d-none",
      startMarker: undefined,
      endMarker: undefined,
      staticPolyLine: undefined,
      dynamicPolyLine: undefined,
      sliderValue: { min: 0, max: 1 },
      sliderMin: 0,
      sliderMax: 1,
      loading: true,
      // locale: {
      //   format: 'DD/MM/YYYY'
      // }

      singleSliderValue: 0,
      singleSliderMin: 0,
      singleSliderMax: 1,
      itemObject: this.props.location.state.itemObject
    };
  }

  resetState = () => {
    this.dIndex = 0;
    this.setState({
      tripData: [],
      historyCoordincates: [],
      directionCoordinates: [],
      //dIndex: 0,
      playButtonClass: this.playButtonClass,
      speedText: "",
      kmText: "",
      tripText: "",
      startMarker: undefined,
      endMarker: undefined,
      staticPolyLine: undefined,
      dynamicPolyLine: undefined,

      sliderValue: { min: 0, max: 1 },
      sliderMin: 0,
      sliderMax: 1,
      loading: true,

      singleSliderValue: 0,
      singleSliderMin: 0,
      singleSliderMax: 1
    });
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  // fitBounds(map, maps) {
  //   var bounds = new maps.LatLngBounds();
  //   for (let marker of this.props.markers) {
  //     bounds.extend(new maps.LatLng(marker.lat, marker.lng));
  //   }
  //   map.fitBounds(bounds);
  // }

  onMapLoaded(map, maps) {
    //this.fitBounds(map, maps);
    this.setState({
      // ...this.state,
      mapsLoaded: true,
      map: map,
      maps: maps
    });

    this.refreshData();
  }

  calculateDistance = (lat1, lng1, lat2, lng2) => {
    var lat = lat2 - lat1; // Difference of latitude
    var lon = lng2 - lng1; // Difference of longitude

    var disLat = (lat * Math.PI * this.earthRadius) / 180; // Vertical distance
    var disLon = (lon * Math.PI * this.earthRadius) / 180; // Horizontal distance

    var ret = Math.pow(disLat, 2) + Math.pow(disLon, 2);
    ret = Math.sqrt(ret); // Total distance (calculated by Pythagore: a^2 + b^2 = c^2)

    //ret = Math.round(ret);
    return ret;
    // Now you have the total distance in the variable ret
  };

  getValueWithZero = inte_val => {
    var result_text_value = "";
    if (inte_val === 0) {
      result_text_value = "00" + inte_val.toString();
    } else {
      if (inte_val < 10) {
        result_text_value = "00" + inte_val.toString();
      } else if (inte_val < 100) {
        result_text_value = "0" + inte_val.toString();
      } else {
        result_text_value = inte_val.toString();
      }
    }
    return result_text_value;
  };

  getDateTime = date => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    //let hours = date.getHours();
    //let minus = date.getMinutes();
    //let sec = date.getSeconds(); // getMilliseconds()

    return year + "-" + month + "-" + day; //+ " " + hours + ":" + minus + ":" + sec;
  };

  formatAMPM = date => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  getHistoryData = () => {
    const { startDate, endDate } = this.state;

    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      vehicle_id: this.state.itemObject.autoid, //this.props.autoid,
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString()
    };

    HistoryTrakingDataByVehicle(fnInputes).then(res => {
      if (res && res.length > 0) {
        let count = res.length;
        let lineCoordinates = [];
        let current_km = 0;

        for (let i = 0; i < res.length; i++) {
          let lat = Number(res[i].lat);
          let lng = Number(res[i].log);
          lineCoordinates.push({ lat: lat, lng: lng });

          res[i].lat = lat;
          res[i].log = lng;
          if (i > 0) {
            current_km += this.calculateDistance(
              res[i - 1].lat,
              res[i - 1].log,
              res[i].lat,
              res[i].log
            );
          }
          res[i].km = this.getValueWithZero(Math.round(current_km)) + "km";
        }

        let startPosition = {
          lat: lineCoordinates[0].lat,
          lng: lineCoordinates[0].lng
        };
        let endPosition = {
          lat: lineCoordinates[count - 1].lat,
          lng: lineCoordinates[count - 1].lng
        };

        this.state.map.setCenter(startPosition);
        this.renderMarkers(startPosition, endPosition);

        let length = lineCoordinates.length;

        this.setState({
          historyCoordincates: lineCoordinates,
          tripData: res,
          errorAlertClass: "alert alert-danger d-none",
          sliderMax: length,
          sliderValue: { min: 0, max: length },
          loading: false,
          singleSliderMax: length,
          singleSliderMin: 0,
          singleSliderValue: 0
        });

        //this.renderStaticPolyLine();
      } else {
        this.setState({
          errorAlertClass: "alert alert-danger d-block",
          loading: false
        });
      }
    });
  };

  clearStartMarker = () => {
    if (this.state.startMarker) this.state.startMarker.setMap(null);
  };

  clearEndMarker = () => {
    if (this.state.endMarker) this.state.endMarker.setMap(null);
  };

  renderMarkers(startPosition, endPosition) {
    const { map, maps } = this.state;
    let startMarker = new maps.Marker({
      position: startPosition,
      map,
      title: ""
    });

    let endMarker = new maps.Marker({
      position: endPosition,
      map,
      title: ""
    });

    this.setState({
      startMarker: startMarker,
      endMarker: endMarker
    });
  }

  setMarkerPosition(startPosition, endPosition) {
    const { startMarker, endMarker } = this.state;
    if (startMarker) {
      startMarker.setPosition(startPosition);
    }

    if (endMarker) endMarker.setPosition(endPosition);
  }

  clearStaticPolyLine = () => {
    const { staticPolyLine } = this.state;
    if (staticPolyLine) {
      staticPolyLine.setMap(null);
      this.setState({ staticPolyLine: null });
    }
  };

  clearDynamicPolyLine = () => {
    const { dynamicPolyLine } = this.state;
    if (dynamicPolyLine) {
      dynamicPolyLine.setMap(null);
      this.setState({ dynamicPolyLine: null });
    }
  };

  renderStaticPolyLine = () => {
    const { historyCoordincates, map, maps, staticPolyLine } = this.state;
    if (staticPolyLine) {
      staticPolyLine.setPath(historyCoordincates);
    } else {
      let polyline = new maps.Polyline({
        path: historyCoordincates,
        geodesic: true,
        strokeColor: "e55c5a",
        strokeOpacity: 1.0,
        strokeWeight: 4,
        icons: [
          {
            icon: {
              //path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 5,
              strokeColor: "e55c5a"
            },
            offset: "100%"
          }
        ],

        map: map
      });

      this.setState({ staticPolyLine: polyline });
    }
  };

  renderDynamicPolyline = () => {
    const { directionCoordinates, map, maps, dynamicPolyLine } = this.state;

    if (dynamicPolyLine) {
      dynamicPolyLine.setPath(directionCoordinates);
    } else {
      let polyline = new maps.Polyline({
        path: directionCoordinates,
        geodesic: true,
        strokeColor: "green",
        strokeOpacity: 1.0,
        strokeWeight: 4,
        icons: [
          {
            icon: {
              path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 5,
              strokeColor: "green"
            },
            offset: "100%"
          }
        ],
        map: map
      });

      this.setState({ dynamicPolyLine: polyline });
    }
  };

  clearTime = () => {
    clearInterval(this.intervalId);
  };

  timer = () => {
    let index = this.dIndex;
    this.setState({ singleSliderValue: this.dIndex });
    this.playData();

    index++;
    this.dIndex = index;
  };

  playData = () => {
    if (
      this.state.historyCoordincates &&
      this.state.historyCoordincates.length > 0
    ) {
      let array = [];
      let count = this.state.sliderValue.max; //this.state.historyCoordincates.length;

      if (this.dIndex >= count) {
        this.tstate = 4;
        this.clearTime();
        this.setState({
          playButtonClass: this.playButtonClass
        });
      } else {
        let index = this.dIndex;

        array = this.state.historyCoordincates.slice(
          this.state.sliderValue.min,
          index + 1
        );
        let row = this.state.tripData[index];

        let tripText = this.setTripText(row.updatetime, row.speed, 0);
        let kmm = this.speedText ? " | " + row.km : row.km;

        this.setState({
          directionCoordinates: array,
          kmText: kmm,
          tripText: tripText
          //sliderValue: {min:this.dIndex, max: count}
        });
        this.renderDynamicPolyline();
      }
      //console.log("new array =", this.state.directionCoordinates);
    } else {
      //alert("No data");
    }
  };

  initTimer = () => {
    this.intervalId = 0;
    this.startTime = new Date();
    this.remaining = 0;
    this.tstate = 0; //  0 = idle, 1 = running, 2 = paused, 3= resumed
    this.speed = 500;
  };

  play = () => {
    if (this.tstate === 0) {
      if (
        this.state.historyCoordincates &&
        this.state.historyCoordincates.length > 0
      ) {
        // first play
        this.tstate = 1;
        this.intervalId = setInterval(this.timer, this.speed);

        this.setState({
          playButtonClass: this.pauseButtonClass
        });
      }
    } else if (this.tstate === 1) {
      // pause
      this.tstate = 0;
      clearInterval(this.intervalId);
      //delete this.intervalId
      this.setState({
        playButtonClass: this.playButtonClass
      });
    } else if (this.tstate === 4) {
      //replay
      this.clearDynamicPolyLine();

      this.initTimer();
      this.tstate = 1;
      this.dIndex = 0;
      this.setState({
        directionCoordinates: [], // sliced data
        playButtonTitle: "Pause",
        speedText: "",
        kmText: "",
        tripText: ""
      });

      this.intervalId = setInterval(this.timer, this.speed);
    }
  };

  pause = () => {
    this.tstate = 0;
    clearInterval(this.intervalId);
  };

  center = () => {
    const { historyCoordincates, directionCoordinates } = this.state;

    if (directionCoordinates != null && directionCoordinates.length > 0) {
      this.state.map.setCenter(
        directionCoordinates[directionCoordinates.length - 1]
      );
    } else if (historyCoordincates != null && historyCoordincates.length > 0) {
      this.state.map.setCenter(historyCoordincates[0]);
    }
  };

  slower = () => {
    if (!this.intervalId) return;

    this.pause();

    this.speed = this.speed + 50;
    if (this.speed > 500) {
      this.speed = 500;
    }
    this.setSpeedDisplay();
    this.play();
  };

  faster = () => {
    if (!this.intervalId) return;

    this.pause();

    this.speed = this.speed - 50;
    if (this.speed < 1) {
      this.speed = 50;
    }

    this.setSpeedDisplay();
    this.play();
  };

  setSpeedDisplay = () => {
    var spd = "";
    switch (this.speed) {
      case 500:
        spd = "0";
        break;

      case 450:
        spd = "1";
        break;

      case 400:
        spd = "2";
        break;

      case 350:
        spd = "3";
        break;

      case 300:
        spd = "4";
        break;

      case 250:
        spd = "5";
        break;

      case 200:
        spd = "6";
        break;

      case 150:
        spd = "7";
        break;

      case 100:
        spd = "8";
        break;

      case 50:
        spd = "9";
        break;
      default:
        break;
    }
    this.speedText = spd;
    this.setState({ speedText: " Speed x " + spd });
  };

  setTripText = (last_update, speed, fuel) => {
    var tripText = "";

    //speed = speed * 1.852;
    speed = Math.round(speed);
    tripText += "Speed: " + speed.toString() + "kmph, ";
    tripText +=
      "Time: " +
      moment(last_update)
        .local()
        //.tz("Asia/Qatar")
        .format("MMM DD YYYY, h:mm:ss a");

    return tripText;
  };

  refreshData = () => {
    this.setState({
      loading: true
    });
    this.clearStartMarker();
    this.clearEndMarker();
    this.clearDynamicPolyLine();
    this.clearStaticPolyLine();
    this.clearTime();
    this.resetState();
    this.initTimer();
    this.getHistoryData();
  };

  sliderChange = () => {
    const { sliderValue, historyCoordincates } = this.state;
    this.clearDynamicPolyLine();
    this.clearTime();
    this.initTimer();

    this.dIndex = this.state.sliderValue.min;

    this.setState({
      directionCoordinates: [],
      playButtonClass: this.playButtonClass,
      speedText: "",
      kmText: "",
      tripText: "",
      singleSliderMax: sliderValue.max,
      singleSliderMin: sliderValue.min,
      singleSliderValue: sliderValue.min
    });

    if (historyCoordincates && historyCoordincates.length > 0) {
      let start = historyCoordincates[sliderValue.min];
      let end = historyCoordincates[sliderValue.max - 1];
      this.setMarkerPosition(start, end);
    }
  };

  singleSliderChange = value => {
    this.setState({
      singleSliderValue: value
    });

    this.dIndex = value;
    this.playData();
  };

  formatSliderLabel = value => {
    let result = "";
    if (
      this.state.historyCoordincates &&
      this.state.historyCoordincates.length > 0
    )
      result = this.state.historyCoordincates[value];
    return result;
  };
  onStartDateChange = e => {
    this.setState({ startDate: e._d });
  };

  onEndDateChange = e => {
    this.setState({ endDate: e._d });
  };

  formatLabel = value => {
    let val = value >= this.state.tripData.length ? value - 1 : value;
    let dtValue =
      this.state.tripData[val] !== undefined
        ? `${this.state.tripData[val].updatetime}`
        : "";
    //let dt = new Date(dtValue).toLocaleString();
    let dt =
      dtValue === ""
        ? ""
        : moment(dtValue)
            .local()
            .format("MMM DD YYYY, h:mm:ss a");
    return dt;
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <main role="main">
          <section className="manage-colom">
            <div className="mt-4" style={{ width: "100%" }}>
              <div className="row">
                <div className="col-md-12 col-12">
                  <div className="card ">
                    <div className="card-header">
                      <h3 className="card-title">
                        Vehicle History - {this.state.itemObject.title}
                      </h3>
                    </div>
                    <div className="card-content collpase show">
                      <div className="card-body">
                        <div className="form-body">
                          <div className="col-12">
                            {/* <div className="alert alert-primary" role="alert">
                                <strong>Info!</strong> Review of Tracking Data
                                available for 2 years.
                              </div> */}
                            <div
                              className={this.state.errorAlertClass}
                              role="alert"
                            >
                              <strong>Alert!</strong> Tracking data not
                              available.
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <div className="row mt-1">
                                  <label className="ml-3 mr-3">Start :</label>
                                  <div style={{ width: "180px" }}>
                                    <Datetime
                                      defaultValue={this.state.startDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onStartDateChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-4">
                                <div className="row">
                                  <label className="mr-3 mt-1">End :</label>
                                  <div
                                    className="mt-1"
                                    style={{ width: "180px" }}
                                  >
                                    <Datetime
                                      defaultValue={this.state.endDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onEndDateChange}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-primary ml-2 fa fa-search"
                                      onClick={this.refreshData}
                                      title="Search"
                                    />

                                    <button
                                      className={this.state.playButtonClass}
                                      onClick={this.play}
                                      title={
                                        this.state.playButtonClass ===
                                        "btn btn-sm btn-primary ml-2 fa fa-play"
                                          ? "Play"
                                          : "Pause"
                                      }
                                    />

                                    <button
                                      className="btn btn-sm btn-primary ml-2 fa fa-dot-circle-o"
                                      onClick={this.center}
                                      title="Center"
                                    />

                                    <button
                                      className="btn btn-sm btn-primary ml-2 fa fa-minus"
                                      onClick={this.slower}
                                      title="Slower"
                                    />

                                    <button
                                      className="btn btn-sm btn-primary ml-2 fa fa-plus"
                                      onClick={this.faster}
                                      title="Faster"
                                    />
                                  </div>
                                  <div className="sweet-loading ml-2">
                                    <RiseLoader
                                      css={loaderCSS}
                                      sizeUnit={"px"}
                                      size={10}
                                      color={"#123abc"}
                                      loading={this.state.loading}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-5 float-right">
                                <div className="row">
                                  <div className="mt-2 text-left">
                                    <h6>
                                      <span>
                                        {this.state.speedText}
                                        {this.state.kmText}
                                      </span>
                                    </h6>
                                  </div>

                                  <div className="mt-2 ml-5">
                                    <h6>
                                      <span>{this.state.tripText}</span>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row ">
                              <div className="col-1" />
                              <div className="col-4">
                                <div
                                  className="mt-2"
                                  style={{ alignContent: "center" }}
                                >
                                  <InputRange
                                    maxValue={this.state.sliderMax}
                                    minValue={this.state.sliderMin}
                                    value={this.state.sliderValue}
                                    onChange={value => {
                                      this.setState({ sliderValue: value });
                                    }}
                                    onChangeComplete={v => {
                                      this.sliderChange();
                                    }}
                                    formatLabel={value =>
                                      this.formatLabel(value)
                                    }
                                    allowSameValues={true}
                                  />
                                </div>
                              </div>
                              <div className="col-2" />
                              <div className="col-4">
                                <div
                                  className="mt-2"
                                  style={{ alignContent: "center" }}
                                >
                                  <InputRange
                                    maxValue={this.state.singleSliderMax}
                                    minValue={this.state.singleSliderMin}
                                    value={this.state.singleSliderValue}
                                    onChange={value =>
                                      this.singleSliderChange(value)
                                    }
                                    formatLabel={value =>
                                      this.formatLabel(value)
                                    }
                                    allowSameValues={true}
                                  />
                                </div>
                              </div>
                              <div className="col-1" />
                            </div>
                          </div>
                          <div className="col-12 mb-4" />
                          <div className="col-12">
                            <div className="row">
                              <div
                                className="col-12"
                                style={{ height: "700px" }}
                              >
                                <GoogleMap
                                  yesIWantToUseGoogleMapApiInternals={true}
                                  bootstrapURLKeys={{
                                    key:
                                      "AIzaSyAKjw_wuep7sJmjM6tKylxLr6Yo_bHUxnw"
                                  }}
                                  defaultCenter={this.props.center}
                                  defaultZoom={this.props.zoom}
                                  options={{ mapTypeControl: true }}
                                  onGoogleApiLoaded={({ map, maps }) => {
                                    this.onMapLoaded(map, maps);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

HistoryTracking.defaultProps = {
  markers: [{ lat: 10.42718, lng: 76.210682 }],
  center: [25.273217, 51.414257],
  zoom: 8
};

export default HistoryTracking;
