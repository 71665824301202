import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/Navbar";
import MultipleVehicleSelect from "../compon-controls/MultipleVehicleSelect";
import {
  AuthHelperAccessCheck,
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText
} from "../businessjs/AuthHelper";

import { VehicleControlUpdateControlMuiltiple } from "../businessjs/VehicleFn";

class MultipleVehicleControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastItems: [],
      selectedVehicles: [],
      subscriber_id: 0,
      txtOverSpeed: 120,
      txtImmobilization: "OFF",
      txtPheripheral: 0
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  ChangeOverspeed = e => {
    e.preventDefault();
    let newValue = this.state.txtOverSpeed;

    const regFormInputes = {
      type_code: 2, // over speed
      new_value: newValue,
      command: "$SET,OV," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.updateControls(regFormInputes);
  };

  ChangeImmobilization = e => {
    e.preventDefault();
    let newValue = this.state.txtImmobilization;

    const regFormInputes = {
      type_code: 1, // Immobilization
      new_value: newValue,
      command: "$SET,IMM," + newValue + ",#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    this.updateControls(regFormInputes);
  };

  ChangePheripheral = e => {
    e.preventDefault();
    let newValue = this.state.txtPheripheral;

    const regFormInputes = {
      type_code: 13, // Immobilization
      new_value: newValue === '1' ? "RFID Driver" : "NONE",
      command: "$SET,PORT,1," + newValue + ",9600,#",
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    // console.log('rec ', regFormInputes);
     this.updateControls(regFormInputes);
  };

  updateControls = params => {
    if (this.state.selectedVehicles && this.state.selectedVehicles.length) {
      let param = params;
      param.vehicles = this.state.selectedVehicles;
      console.log("vehi ", param);

      VehicleControlUpdateControlMuiltiple(params).then(res => {
        if (res) {
          AuthHelperAccessCheck(res);
          this.onToastItemAdd(res.status, res.message);
          this.setState({ selectedVehicles: [] });
        }
      });
    } else {
      this.onToastItemAdd("error", "please select vehicle");
    }
  };

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];

      this.setState({
        subscriber_id: decodedUser.subscriber_id,
        account_type: decodedUser.account_type,
        company_name: decodedUser.company_name,
        email: decodedUser.email,
        role: decodedUser.role,
        roleText: AuthHelperGerSubscriberRoleText(decodedUser.role)
      });
    }
  }

  handleOnSelectedVehicleChange = selectedOptions => {
    this.setState({ selectedVehicles: selectedOptions });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />

        <section className="manage-colom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <ToastAlert
                  toastItems={this.state.toastItems}
                  onItemClose={this.handleToastItemClose}
                />
                <h1>Multiple Vehicle Controls </h1>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                
                <MultipleVehicleSelect
                  vehicleSelectedValues={this.state.selectedVehicles}
                  onChange={this.handleOnSelectedVehicleChange}
                  subscriber_id={this.state.subscriber_id}
                />
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row tile-colom">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Type</th>

                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Overspeed</td>

                        <td>
                          <div className="btn-group">
                            <input
                              type="text"
                              name="txtOverSpeed"
                              onChange={this.onChange}
                              value={this.state.txtOverSpeed}
                              style={{ width: "70px" }}
                            />
                            <button
                              onClick={this.ChangeOverspeed}
                              className="btn btn-outline-secondary btn-sm"
                            >
                              Update
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Immobilization</td>

                        <td>
                          <div className="btn-group">
                            <select
                              name="txtImmobilization"
                              className="btn btn-outline-secondary btn-sm"
                              onChange={this.onChange}
                              value={this.state.txtImmobilization}
                            >
                              <option value="OFF">OFF</option>
                              <option value="ON">ON</option>
                            </select>
                            <button
                              onClick={this.ChangeImmobilization}
                              className="btn btn-outline-secondary btn-sm"
                            >
                              Update
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Assign pheripheral AUX </td>

                        <td>
                          <div className="btn-group">
                            <select
                              name="txtPheripheral"
                              className="btn btn-outline-secondary btn-sm"
                              onChange={this.onChange}
                              value={this.state.txtPheripheral}
                            >
                              <option value="0">NONE</option>
                              <option value="1">Driver RFID</option>
                            </select>
                            <button
                              onClick={this.ChangePheripheral}
                              className="btn btn-outline-secondary btn-sm"
                            >
                              Update
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MultipleVehicleControl;
