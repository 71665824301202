import React, { Component } from "react";
import Navbar from "../compon-styled/Navbar";
import { AuthHelperValidateOverToken } from "../businessjs/AuthHelper";
import jwt_decode from "jwt-decode";
import { Route } from "react-router-dom";
// import { Link } from "react-router-dom";

class SettingsHome extends Component {
  state = {};

  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsubscriber")) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];
      this.setState({
        role: decodedUser.role,
        loginType: decoded.accessType
      });
    }
  }

  formatChangePasswordButton() {
    const ButtonChangePassword = () => (
      <Route
        render={({ history }) => (
          <React.Fragment>
            <div className="col-lg-3">
              <button
                type="button"
                className="nobutton w-100"
                onClick={() => {
                  history.push({
                    pathname: "/change-password",
                    state: { loginType: this.state.loginType }
                  });
                }}
              >
                <div className="tile-one">
                  <div className="tile-icon">
                    <h1>
                      <img src="images/pass-icon.png" alt="icon" />
                    </h1>
                  </div>
                  <div className="tile-head">
                    <center>Change Password</center>
                  </div>
                </div>
              </button>
            </div>
          </React.Fragment>
        )}
      />
    );
    return <ButtonChangePassword />;
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />

        <section className="manage-colom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1>Settings</h1>
                <div className="row tile-colom">
                  {this.formatChangePasswordButton()}

                  {/* <div className="col-lg-3">
                    <Link to="/changeemail">
                      <div className="tile-one">
                        <div className="tile-icon">
                          <h1>
                            <img src="images/email-ico-form.png" alt="icon" />
                          </h1>
                        </div>
                        <div className="tile-head" style={{color:"black"}}>
                          <center>Change E-mail</center>
                        </div>
                      </div>
                    </Link>
                  </div> */}
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default SettingsHome;
