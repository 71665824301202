import React, { Component } from "react";
import { SupportMemberAuth } from "../businessjs/SupportMemberFn";
// import jwt_decode from "jwt-decode";

class SupportCenterLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginemail: "",
      loginpassword: "",
      errorText: "",
      btnSignInText: "Sign In"
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    /*
    const token = localStorage.apiAuthAccessKey;

    try {
      if (typeof token !== "undefined") {
        const decoded = jwt_decode(token);
        if (decoded.accessType === "supportcenter") {
          this.props.history.push("/supportcenter");
        } else if (decoded.accessType === "subscriber") {
          this.props.history.push("/subscriberhome");
        }
      }
    } catch (e) {
      console.log(" token not valid", token);
    }
    */
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    // Router.push('/supportcenter');

    const regFormInputes = {
      loginemail: this.state.loginemail,
      loginpassword: this.state.loginpassword
    };

    this.setState({ errorText: "", btnSignInText: "Progressing..." });

    SupportMemberAuth(regFormInputes).then(res => {
      this.setState({ btnSignInText: "Sign In" });
      if (res) {
        //this.props.history.push(`/profile`);
        if (res.status === "success") {
          this.props.history.push("/supportcenter");
        } else {
          if (res.status) {
            this.setState({
              errorText: res.status.toUpperCase() + ": " + res.message
            });
          }
        }
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-wrap">
          <div className="header-signup cf">
            <div className="logo-signup">
              <a href="/">
                <span className="logotext">TFMS </span>
              </a>
            </div>
            <div className="rgt-phone">
              <a href="https://www.cloudtel.qa/">
                <i>
                  <img src="/images/phone-icon.png" alt="icon" />
                </i>
                Contact Us
              </a>
            </div>
          </div>
          <div className="signup-form-sec cf">
            <div className="signup-form-outer">
              <div className="lft-form-bx signin-bx">
                <h1>Sign In</h1>
                <form onSubmit={this.onSubmit}>
                  <div className="form-group cf">
                    <input
                      type="email"
                      className="img-set email-icon"
                      placeholder="Email"
                      name="loginemail"
                      onChange={this.onChange}
                      value={this.state.loginemail}
                      required
                    />
                  </div>

                  <div className="form-group cf">
                    <input
                      type="password"
                      className="img-set pass-icon"
                      placeholder="Password"
                      name="loginpassword"
                      value={this.state.loginpassword}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                  <div className="form-group fg-bx cf" />
                  <div className="form-group reg-btn cf">
                    <button type="submit" className="btn btn-success btn-lg">
                      <i>
                        <img src="/images/signin-icon.png" alt="icon" />
                      </i>
                      {this.state.btnSignInText}
                    </button>
                  </div>

                  {this.state.succesText}
                  {this.state.errorText}

                  <div className="form-group or-txt">
                    <em>OR</em>
                  </div>

                  <div className="form-group already-link cf">
                    <small>
                      Don’t have account?
                      <a href="https://www.cloudtel.qa/"> Contact Us </a>
                    </small>
                    <p>By Signing In, you agree to our Terms & Policy </p>
                  </div>
                </form>
              </div>
              <div className="rgt-signup-txts">
                <small>An Online Place for </small>
                <h2>Fleet Monitoring!</h2>
              </div>
            </div>
          </div>
          <footer className="footer-signup  cf">
            <p> Powered by Cloud Telematics</p>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}
export default SupportCenterLogin;
