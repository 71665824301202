import React, { Component } from "react";
import Navbar from "../compon-styled/SupportCenterNavBar";
import { VehicleGetAll, VehicleStatusUpdate } from "../businessjs/VehicleFn";
import { Route } from "react-router-dom";
import ToastAlert from "./ToastAlert";


class SupportCenterVehicleList extends Component {
  state = {
    toastItems: [],
    allDataRows: []
  };

  componentDidMount() {
    VehicleGetAll({
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        this.setState({ allDataRows: res });
      }
    });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  checkStaus = item => {
    if (item.status === 0) {
      const selectedItem = {
        subscriber_id: item.subscriber_id,
        autoid: item.autoid,
        status: 1
      };
      return (
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => this.updateVehicleStatus(selectedItem)}
        >
          Activate
        </button>
      );
    } else if (item.status === 1 || item.status === 2) {
      const selectedItem = {
        subscriber_id: item.subscriber_id,
        autoid: item.autoid,
        status: 0
      };
      return (
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => this.updateVehicleStatus(selectedItem)}
        >
          De-activate
        </button>
      );
    }
  };

  updateVehicleStatus = selectedItem => {
    const regFormInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      subscriber_id: selectedItem.subscriber_id,
      autoid: selectedItem.autoid,
      status: selectedItem.status
    };

    VehicleStatusUpdate(regFormInputes).then(res => {
      this.onToastItemAdd(res.status, res.message);
      if (res.status === "success") {
        VehicleGetAll({
          user_token: localStorage.apiAuthAccessKey,
          user_token_id: localStorage.apiAuthAccessId
        }).then(res => {
          if (res) {
            this.setState({ allDataRows: res });
          }
        });
      }
    });
  };

  vehicleAdd = () => {
    const ButtonAdd = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-primary btn-sm fa fa-plus"
            onClick={() => {
              history.push({
                pathname: "/vehicleadd",
                state: {
                  type: "add"
                  // item: vehicle
                }
              });
            }}
          />
        )}
      />
    );
    return <ButtonAdd />;
  };

  vehicleEdit = Item => {
    const ButtonEdit = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => {
              history.push({
                pathname: "/vehicleadd",
                state: {
                  item: Item,
                  type: "edit"
                }
              });
            }}
          >
            Edit
          </button>
        )}
      />
    );
    return <ButtonEdit />;
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  formateBody = () => {
    let showDataRows = this.state.allDataRows;
    return (
      <React.Fragment>
        <Navbar />
        <section className="manage-colom">
          <div className="container">
            <ToastAlert
              toastItems={this.state.toastItems}
              onItemClose={this.handleToastItemClose}
            />
            <main role="main">
              <section>
                <div className="mt-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-11">
                            {" "}
                            <h2> Vehicle List </h2>
                          </div>

                          <div className="col">{this.vehicleAdd()}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2" style={{ width: "100%" }}>
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Plate Number </th>
                              <th>Fleet Number</th>
                              <th>Manufacture</th>
                              <th> Model</th>
                              <th>Fuel</th>
                            </tr>
                          </thead>
                          <tbody>
                            {showDataRows.map((Item, i) => (
                              <React.Fragment key={i}>
                                <tr>
                                  <td>{Item.title}</td>
                                  <td>{Item.plate_number}</td>
                                  <td>{Item.fleet_number}</td>
                                  <td>{Item.manufacture_name}</td>
                                  <td>{Item.model}</td>
                                  <td>{Item.fuel_type}</td>

                                  <td>
                                    <div className="btn-group float-right">
                                      {this.vehicleEdit(Item)}
                                      {this.checkStaus(Item)}
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                        <center>
                          {showDataRows.length ? "" : "No vehicles available"}
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </section>
      </React.Fragment>
    );
  };

  render() {
    return <li>{this.formateBody()}</li>;
  }
}

export default SupportCenterVehicleList;
