import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "vehiclegroup";

export const GroupAddNew = objItem => {
  return axios
    .post(proxyServer + "/new", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupUpdate = objItem => {
  return axios
    .post(proxyServer + "/update", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupDelete = objItem => {
  return axios
    .post(proxyServer + "/delete", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupUpdateStatus = objItem => {
  return axios
    .post(proxyServer + "/updatestatus", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupSelectBySubscriber = objItem => {
  return axios
    .post(proxyServer + "/getbysubscriber", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

// group vehicles ========================================

export const GroupVehicleAdd = objItem => {
  return axios
    .post(proxyServer + "/vehicle_add", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupVehicleSelectBySubscriber = objItem => {
  return axios
    .post(proxyServer + "/getvehiclesbysubscriber", { objItem })
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupVehicleRemove = objItem => {
  return axios
    .post(proxyServer + "/vehicle_remove", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const ExecutiveVehicleGroupAdd = objItem => {
  return axios
    .post(proxyServer + "/executive_vehiclegroup_add", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const ExecutiveVehicleGroupSelectById = objItem => {
  return axios
    .post(proxyServer + "/getexecutive_vehiclegroup", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const ExecutiveVehicleGrouprDelete = objItem => {
  return axios
    .post(proxyServer + "/executive_vehiclegroup_delete", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
