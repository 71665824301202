import React, { Component } from "react";
import {Redirect} from 'react-router-dom'

class Landing extends Component {

  componentDidMount() {
   // this.props.history.push(`/login`);
  }

  soketWebsoket= () =>{
    // console.log('heloo ');

    try{
      const url = 'ws://localhost:22000';
      const connection = new WebSocket(url)

      connection.onopen = () => {
        connection.send('my mess '); 
      }

      connection.onerror = (error) => {
        console.log(`WebSocket error: ${error}`);
      }

      connection.onmessage = (e) => {
        console.log( "res> "+ e.data);
      }

    }catch(ex){
      console.log('exe ', ex);
    }

  }

  render() {
    console.log('Redirecting to login page')
    return <Redirect to ='/login'></Redirect>

    // return (
    //   <div className="container">
    //     <div>
    //       <div className="col-sm-8 mx-auto">
    //         <h1 className="text-center">WELCOME</h1>

    //         <div
    //           className="btn-group btn-group-lg"
    //           role="group"
    //           aria-label="Basic example"
    //         >
    //           <a href="/login" className="btn btn-unique btn-lg">
    //             Login
    //           </a>
    //           <a href="/register" className="btn btn-unique btn-lg">
    //             Register
    //           </a>
    //           <a href="/supportcenter/login" className="btn btn-unique btn-lg">
    //             Support Center Login
    //           </a>
    //         </div>
    //         <button onClick={this.soketWebsoket} >Click Me </button>
    //       </div>

    //     </div>
    //   </div>
    // );
  }
}

export default Landing;
