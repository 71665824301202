import React, { Component } from "react";
import Navbar from "../compon-styled/Navbar";
import jwt_decode from "jwt-decode";
import Pagination from "react-js-pagination";
import { GetVehicleListById, VehicleCountById } from "../businessjs/VehicleFn";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import { Route } from "react-router-dom";

class VehicleListByTitle extends Component {
  state = {
    allDataRows: [],
    //filteredDataRows: [],
    toastItems: [],
    subscriber_id: 0,
    activePage: undefined,
    itemsCountPerPage: 25,
    totalItemsCount: 0,
    searchTex: ""
  };

  componentWillMount() {
    this.getCount();
  }

  getCount = () => {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    const fnInputes = {
      subscriber_id: decodedUser.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      searchTex: this.state.searchTex,
      executive_id: decodedUser.autoid
    };

    VehicleCountById(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let vehiclecount = res[0].vehiclecount;
          if (vehiclecount > 0) {
            this.setState({ totalItemsCount: vehiclecount, activePage: 1 });
            this.getVehicleList(1);
          } else {
            this.setState({
              allDataRows: [],
              totalItemsCount: 0,
              activePage: 1
            });
          }
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getVehicleList = pageNumber => {
    let start = (pageNumber - 1) * this.state.itemsCountPerPage;
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    const fnInputes = {
      subscriber_id: decodedUser.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      searchTex: this.state.searchTex,
      startLimit: start,
      endLimit: this.state.itemsCountPerPage,
      executive_id: decodedUser.autoid
    };

    GetVehicleListById(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allDataRows: res });
          if (!this.state.activePage) {
            this.setState({ totalItemsCount: res.length, activePage: 1 });
          }
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    this.getVehicleList(pageNumber);
  };

  exportToExceldButton = () => {
    const ButtonExport = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-primary ml-2 fa fa-file-excel-o"
            onClick={() => {
              history.push({
                pathname: "/exporttoexcel",
                state: {
                  searchTex: this.state.searchTex,
                  totalItemsCount: this.state.totalItemsCount,
                  name: "Vehicle List",
                  type: "vehiclelist"
                }
              });
            }}
          />
        )}
      />
    );
    return <ButtonExport />;
  };

  render() {
    let showDataRows = this.state.allDataRows;

    let displayVehicleList = showDataRows.filter(function(a) {
      if (!this[a.plate_number]) {
        this[a.plate_number] = true;
        return true;
      }
      
      return false;

    }, Object.create(null));
    return (
      <React.Fragment>
        <Navbar />
        <section className="manage-colom">
          {/* <div className="container"> */}
          <main role="main">
            <section>
              <div className="mt-4" style={{ width: "100%" }}>
                <div>
                  <div className="row" />
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-8">
                            {" "}
                            <h2> Vehicle List </h2>
                          </div>

                          <div className="col-4">
                            <div className="row">
                              <input
                                type="text"
                                name="searchTex"
                                placeholder="Title"
                                onChange={this.onChange}
                                value={this.state.searchTex}
                                style={{ height: "36px", width: "200px" }}
                              />

                              <div className="btn-group float-right">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary ml-2 fa fa-search"
                                  onClick={this.getCount}
                                  style={{ width: "37px", height: "38px" }}
                                />
                                {this.exportToExceldButton()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Title</th>
                              <th>Plate No</th>                            
                              <th>Manufacture</th>
                              <th> Model</th>
                              <th> Year</th>
                              <th>Fuel</th>
                              <th>Status</th>
                              <th>Subscription</th>
                              <th> Device Id</th>
                              <th> Time zone</th>
                              <th>Vehicle Type</th>
                              <th>Sim</th>
                            </tr>
                          </thead>
                          <tbody>
                            {displayVehicleList.map((Item, i) => (
                              <React.Fragment key={i}>
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{Item.title}</td>
                                  <td>{Item.plate_number}</td>                               
                                  <td>{Item.manufacture_name}</td>
                                  <td>{Item.model}</td>
                                  <td>{Item.year}</td>
                                  <td>{Item.fuel_type}</td>
                                  <td>{Item.status}</td>
                                  <td>{Item.subtitle}</td>
                                  <td>{Item.device_id}</td>
                                  <td>{Item.timezone}</td>
                                  <td>{Item.vehicletitle}</td>
                                  <td>{Item.sim}</td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                        <center>
                          {displayVehicleList.length
                            ? ""
                            : "No vehicles available"}
                        </center>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          {/* </div> */}
        </section>
      </React.Fragment>
    );
  }
}

export default VehicleListByTitle;
