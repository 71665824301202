import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "communication";

export const SendMail = objItem => {
  return axios
    .post(proxyServer + "/sendmail", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};