import React, { Component } from "react";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";

import {
  AppHelper_DateToDBString
} from "../businessjs/AppHelperFn";
import { LogMessagesSelectAll } from "../businessjs/LogMessagesFn";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import queryString from "query-string";

class LogList extends Component {
  constructor(props) {
    super(props);
    const startTime = moment(new Date()).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    const endTime = moment(new Date());

    let selectedrange =
      AppHelper_DateAndTimeToString(startTime.toDate()) +
      " to " +
      AppHelper_DateAndTimeToString(endTime.toDate());

    this.state = {
      vehicle: -1,
      displayedItemsArray: [],
      allItems: [],
      selectedrange: selectedrange,
      btnRefreshText: "Go",
      startTime: startTime,
      endTime: endTime
    };
  }

  componentDidMount() {
    // this.onRefresh("load");
    const values = queryString.parse(this.props.location.search);

    const param1 = values.param1 || -1 ;

    console.log('the param1 ', param1);

    this.setState({ vehicle: param1 });

    setTimeout(() => {
      this.onRefresh("load");
    }, 10);
  }

  onRefresh = e => {
    if(this.state.vehicle < 1){
      return ;
    }

    this.setState({ btnRefreshText: "Progressing .." });

    // console.log("dateStart ", AppHelper_DateToDBString(this.state.dateStart));
    // console.log("dateEnd ", AppHelper_DateToDBString(this.state.dateEnd));

    

    const regFormInputes = {
      vehicle: this.state.vehicle,
      starttime: AppHelper_DateToDBString(this.state.startTime.toDate()),
      endtime: AppHelper_DateToDBString(this.state.endTime.toDate())
    };

    LogMessagesSelectAll(regFormInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ displayedItemsArray: res, allItems: res });
        }

        if (this.state.searchText) {
          let searcjQery = this.state.searchText,
            showItems = this.state.allItems.filter(el => {
              let searchValue = el.message.toLowerCase();
              return searchValue.indexOf(searcjQery) !== -1;
            });
          this.setState({
            displayedItemsArray: showItems
          });
        }
      }
      this.setState({ btnRefreshText: "Go" });
    });
  };

  validateAndUpdate = (startTime, endTime) => {
    
    this.handleDateRangePickerEvent(null, {startDate:startTime, endDate: endTime   } );
    
  };

  handleDateRangePickerEvent = (event, picker) => {
    // console.log(" DateRangePicker ", picker.startDate.toDate());
    // console.log(" DateRangePicker ", picker.endDate.toDate());

    this.setState({
      selectedrange:
      AppHelper_DateAndTimeToString(picker.startDate.toDate()) +
        " - " +
        AppHelper_DateAndTimeToString(picker.endDate.toDate()),
      startTime: picker.startDate,
      endTime: picker.endDate
    });

    setTimeout(() => {
      this.onRefresh("load");
    }, 10);

  };

  searchHandler = event => {
    let searcjQery = event.target.value.toLowerCase(),
      showItems = this.state.allItems.filter(el => {
        let searchValue = el.message.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      searchText: event.target.value.toLowerCase(),
      displayedItemsArray: showItems
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  eventMessage = message => {
    const header = message.split(",");
    const time = new Date(parseInt(header[5], 16) * 1000);

    switch (header[2]) {
      case "X10":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :Server disconnection`;

      case "X11":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :Server Connection`;

      case "X12":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :SIM EJECT`;

      case "X13":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :Module Turned OFF`;

      case "X14":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :GNSS fail`;

      case "X15":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :GNSS Thread Exited`;

      case "X16":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :GSMRxexited`;

      case "X17":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :Handshake Timer Exited`;

      case "X18":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :GSM Init Fail`;

      case "X19":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :Main Power Supplied`;

      case "X20":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :unsolicitated`;

      case "X21":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :Sim Fail`;

      case "X22":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :Error In Message Transfer Thread`;

      case "X23":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :Fuel RX Thread Started`;

      case "X24":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :Ignition VolTage detected`;

      case "X25":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :Ignition Voltage lost`;

      case "X26":
        return `${message} --> ${AppHelper_DateAndTimeToString(
          time
        )} :detected RFID's`;

      case "X27":
        return `${message} -->  ${AppHelper_DateAndTimeToString(
          time
        )} :Fule Thread Exited`;

      case "X28":
        return `${message} -->  ${AppHelper_DateAndTimeToString(
          time
        )} :No SIm PIN`;

      case "X29":
        return `${message} -->  ${AppHelper_DateAndTimeToString(
          time
        )} :Unknow Error On sim PIN`;

      case "X30":
        return `${message} -->  ${AppHelper_DateAndTimeToString(
          time
        )} :Always GPRS Thread Exited`;

      case "X31":
        return `${message} -->  ${AppHelper_DateAndTimeToString(
          time
        )} :Fuel sensor Disconnected`;

      default:
        return message;
    }
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card mb-1">
              <div className="card-header">
                <button
                  type="button"
                  onClick={this.onRefresh}
                  className="btn btn-primary float-right"
                >
                  {this.state.btnRefreshText}
                </button>

                <input
                  type="text"
                  name="vehicle"
                  value={this.state.vehicle}
                  className="card-search float-right"
                  placeholder="Vehicle ID "
                  onChange={this.onChange}
                />

                <h4 className="card-title">Device TCP Logs</h4>

                <p> {this.state.selectedrange} </p>
                <DateRangePicker
                  startDate={this.state.startTime.toDate()}
                  endDate={this.state.endTime.toDate()}
                  onApply={this.handleDateRangePickerEvent}
                >
                  <button type="button" className="btn btn-min">
                    {this.state.selectedrange}
                  </button>
                </DateRangePicker>

                <div className="btn-group float-right">
                <button
                    className="btn btn-info btn-xs"
                    onClick={() => {
                      this.validateAndUpdate(
                        moment(new Date()).set({
                          hour: 0,
                          minute: 0,
                          second: 0,
                          millisecond: 0
                        }),
                        moment()
                      );
                    }}
                  >
                    Today
                  </button>

                  <button
                    className="btn btn-info btn-xs"
                    onClick={() => {
                      this.validateAndUpdate(
                        this.state.startTime.add(-24, "hours"),
                        this.state.endTime.add(-24, "hours")
                      );
                    }}
                  >
                    {"<<24h"}
                  </button>
                  <button
                    className="btn btn-info btn-xs"
                    onClick={() => {
                      this.validateAndUpdate(
                        this.state.startTime.add(-12, "hours"),
                        this.state.endTime.add(-12, "hours")
                      );
                    }}
                  >
                    {"<<12h"}
                  </button>

                  <button
                    className="btn btn-info btn-xs"
                    onClick={() => {
                      this.validateAndUpdate(
                        this.state.startTime.add(12, "hours"),
                        this.state.endTime.add(12, "hours")
                      );
                    }}
                  >
                    {">>12h"}
                  </button>

                  <button
                    className="btn btn-info btn-xs"
                    onClick={() => {
                      this.validateAndUpdate(
                        this.state.startTime.add(24, "hours"),
                        this.state.endTime.add(24, "hours")
                      );
                    }}
                  >
                    {">>24h"}
                  </button>

                  <button
                    className="btn btn-info btn-xs"
                    onClick={() => {
                      this.validateAndUpdate(
                        this.state.startTime,
                        this.state.endTime.set({
                          hour: 23,
                          minute: 59,
                          second: 59,
                          millisecond: 999
                        })
                      );
                    }}
                  >
                   11:59 PM
                  </button>
                </div>
              </div>
              <div className="card-content collpase show">
                <div className="card-body">
                  <input
                    type="text"
                    className="card-search float-right"
                    placeholder="Search... "
                    onChange={this.searchHandler}
                  />

                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Vehicle</th>
                        <th scope="col">Time</th>
                        <th scope="col">Text</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.displayedItemsArray.map((Item, i) => (
                        <React.Fragment key={i}>
                          <tr>
                            <td>{i + 1}</td>
                            <td>{Item.vehicle_id}</td>
                            <td>
                              {AppHelper_DateAndTimeToString(Item.event_on)}
                            </td>
                            <td>{this.eventMessage(Item.message)}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogList;
