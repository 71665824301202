
import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "ignitionalert";

  
export const IgnitionAlerts = objItem => {
  return axios
    .post(proxyServer + "/getignitionalerts", { objItem } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionAlertsCount = objItem => {  
  return axios
    .post(proxyServer + "/getignitionalertcount", { objItem } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

