import React, { Component } from "react";


class MyLocation extends Component {
  state = {};
  render() {
      if(!this.props.visibility){
          return(<React.Fragment></React.Fragment>);
      }
    return (
      <React.Fragment>
        

        <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12" />
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="row p-2">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      name="driver_name"
                      onChange={this.onChange}
                      placeholder="Location Name"
                      value={this.state.driver_name}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      name="driver_name"
                      onChange={this.onChange}
                      placeholder="Speed Limit"
                      value={this.state.driver_name}
                    />
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12">
                    <button
                      type="button"
                      onClick={this.onAssignVehicleAddClick}
                      className="btn btn-primary btn-sm"
                    >
                      Save Location
                    </button>
                  </div>
                </div>
              </div>
            </div>

      </React.Fragment>
    );
  }
}

export default MyLocation;
