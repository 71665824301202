import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import {
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText
} from "../businessjs/AuthHelper";
import Navbar from "../compon-styled/SupportCenterNavBar";

class SubscriberHome extends Component {
  componentDidMount() {
    if (AuthHelperValidateOverToken("layoutsupportcenter")) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];

      this.setState({
        subscriber_id: decodedUser.subscriber_id,
        first_name: decodedUser.first_name,
        last_name: decodedUser.last_name,
        email: decodedUser.email,
        account_type: decodedUser.account_type, 
        loginType: "supportcenter",
        userType: "supportcenter",
        role: decodedUser.role,
        roleText: AuthHelperGerSubscriberRoleText(decodedUser.role)
      });
    } else {
      //this.props.history.push(`/login`);
      console.log('SubscriberHome login not valid !!');
    }
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />

        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <div className="row">
                  <h1> Support Center Home </h1>

                  {/* <small> This is parking page of dashboard! </small> */}
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default SubscriberHome;
