import React, { Component } from "react";
//import Navbar from "./Navbar";
import Navbar from "../compon-styled/Navbar";
import GoogleMap from "google-map-react";
import moment from "moment-timezone";
import {
  LiveTrakingDataByVehicle,
  HistoryTrakingDataByVehicle
} from "../businessjs/TrackingFn";

class LiveTracking extends Component {
  state = {
    map: null,
    maps: null,
    mapsLoaded: false,
    itemObject: this.props.location.state.itemObject,
    speed: null,
    time: null,
    ignition: null,
    historyCoordincates: [], // fully line cordinates
    historyPolyLine: null,

    liveCoordinates: [],
    livePolyLine: null
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  onMapLoaded(map, maps) {
    this.setState({
      // ...this.state,
      mapsLoaded: true,
      map: map,
      maps: maps
    });
    this.renderMarkers(map, maps);
    this.getHistoryData();

    this.getLastUpdate();
    this.intervalId = setInterval(this.getLastUpdate, 3000);
  }

  center = () => {
    const { liveCoordinates } = this.state;
    let length = liveCoordinates.length;
    if (length > 0) {
      let lat = liveCoordinates[length - 1].lat;
      let lng = liveCoordinates[length - 1].lng;
      this.state.map.setCenter({ lat: lat, lng: lng });
    }
  };

  renderStaticPolyLine = () => {
    const { historyCoordincates, map, maps, historyPolyLine } = this.state;
    if (historyPolyLine) {
      historyPolyLine.setPath(historyCoordincates);
    } else {
      let staticPolyline = new maps.Polyline({
        path: historyCoordincates,
        geodesic: true,
        strokeColor: "e55c5a",
        strokeOpacity: 1.0,
        strokeWeight: 4,
        icons: [
          {
            icon: {
              //path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 5,
              strokeColor: "e55c5a"
            },
            offset: "100%"
          }
        ],

        map: map
      });

      this.setState({ historyPolyLine: staticPolyline });
    }
  };

  renderLivePolyLine = () => {
    const { liveCoordinates, map, maps, livePolyLine } = this.state;
    if (livePolyLine) {
      livePolyLine.setPath(liveCoordinates);
    } else {
      let polyline = new maps.Polyline({
        path: liveCoordinates,
        geodesic: true,
        strokeColor: "green",
        strokeOpacity: 1.0,
        strokeWeight: 4,
        icons: [
          {
            icon: {
              //path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 5,
              strokeColor: "green"
            },
            offset: "100%"
          }
        ],

        map: map
      });

      this.setState({ livePolyLine: polyline });
    }
  };

  getLastUpdate = () => {
    const { marker } = this;
    const { liveCoordinates } = this.state;

    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      vehicle_id: this.state.itemObject.autoid // TODO
    };

    LiveTrakingDataByVehicle(fnInputes).then(res => {
      if (res && res.length>0) {        

        let lat = res[0].lat;
        let lng = res[0].log;

        marker.setPosition({
          lat: Number(lat),
          lng: Number(lng)
        });
        let title = "Time =" + res[0].gpstime + "\nSpeed =" + res[0].speed;
        marker.setTitle(title); //TODO

        if (!this.tracked) {

          this.map.setCenter({ lat: Number(lat), lng: Number(lng) });
          this.tracked = true;
        }

        let isAdd = true;
        if (liveCoordinates && liveCoordinates.length > 0) {
          let length = liveCoordinates.length;
          let preLat = liveCoordinates[length - 1].lat;
          let prelng = liveCoordinates[length - 1].lng;

          if (
            Number(preLat) === Number(lat) &&
            Number(prelng) === Number(lng)
          ) {
            isAdd = false;
          }
        }

        if (isAdd) {
          liveCoordinates.push({ lat: Number(lat), lng: Number(lng) });
          this.renderLivePolyLine();
        }

        this.setState({
          time: res[0].gpstime,
          speed: res[0].speed,
          ignition: res[0].ignition
        });
      } else {
      }
    });
  };

  getDate = date => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return year + "-" + month + "-" + day;
  };

  getDateTime = date => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let minus = date.getMinutes();
    let sec = date.getSeconds(); // getMilliseconds()

    return (
      year + "-" + month + "-" + day + " " + hours + ":" + minus + ":" + sec
    );
  };

  getHistoryData = () => {
    let dtStart = this.getDate(new Date());
    let dtEnd = this.getDate(new Date());

    let startDate = new Date(dtStart);
    let endDate = new Date(dtEnd + " 23:59:59");

    let startDateUTC = startDate.toISOString();
    let endDateUTC = endDate.toISOString();

    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      vehicle_id: this.state.itemObject.autoid,
      start_date: startDateUTC,
      end_date: endDateUTC
    };

    HistoryTrakingDataByVehicle(fnInputes).then(res => {
      if (res && res.length > 0) {
        //let count = res.length;
        let lineCoordinates = [];
        //let current_km = 0;

        for (let i = 0; i < res.length; i++) {
          let lat = Number(res[i].lat);
          let lng = Number(res[i].log);
          lineCoordinates.push({ lat: lat, lng: lng });

          res[i].lat = lat;
          res[i].log = lng;
        }

        // let startPosition = {
        //   lat: lineCoordinates[0].lat,
        //   lng: lineCoordinates[0].lng
        // };
        // let endPosition = {
        //   lat: lineCoordinates[count - 1].lat,
        //   lng: lineCoordinates[count - 1].lng
        // };

        this.setState({
          historyCoordincates: lineCoordinates
          //tripData: res
        });

        this.renderStaticPolyLine();
      } else {
        //alert('no data')
      }
    });
  };

  renderMarkers(map, maps) {
    this.map = map;
    this.maps = maps;
    this.marker = new maps.Marker({
      position: { lat: null, lng: null },
      map,
      title: ""
    });
  }

  render() {
    let _updateMoment = moment(this.state.time).tz(
      this.state.itemObject.timezone
    );
    
    return (
      <React.Fragment>
        <Navbar />
        <main role="main">
          <section className="manage-colom">
            <div className="container" style={{ width: "100%" }}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h1>View Vehicle - {this.state.itemObject.title}</h1>
                  <div>
                    <div>
                      <div>
                        <div className="form-body">
                          <div className="col-12">
                            <div className="row mb-2">
                              <div className="col-4">
                                Time :
                                {_updateMoment.format("DD MMM YYYY, h:mm:ss a")}
                                <span className="badge">
                                  {_updateMoment.fromNow()}
                                </span>
                              </div>

                              <div className="col-2">
                                Vehicle Speed :
                                <span className="badge badge-info">
                                  {this.state.speed}
                                </span>
                              </div>
                              <div className="col-2">
                                Ignition Status :
                                <span className="badge badge-info">
                                  {this.state.ignition ? "On" : "Off"}
                                </span>
                              </div>
                              <div className="col-2">
                                <button
                                  className="btn btn-md btn-primary ml-2"
                                  onClick={this.center}
                                >
                                  Center
                                </button>
                              </div>

                              
                            </div>
                            <div className="row">
                              <div
                                className="col-12"
                                style={{ height: "700px" }}
                              >
                                <GoogleMap
                                  bootstrapURLKeys={{
                                    key:
                                      "AIzaSyAKjw_wuep7sJmjM6tKylxLr6Yo_bHUxnw"
                                  }}
                                  defaultCenter={this.props.center}
                                  defaultZoom={this.props.zoom}
                                  options={{ mapTypeControl: true }}
                                  onGoogleApiLoaded={({ map, maps }) =>
                                    this.onMapLoaded(map, maps)
                                  }
                                />
                              </div>
                              {this.state.itemObject.autoid}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

LiveTracking.defaultProps = {
  center: [25.273217, 51.414257],
  zoom: 10
};

export default LiveTracking;
