import React from "react";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import jwt_decode from "jwt-decode";
import Navbar from "../compon-styled/Navbar";
import { Route } from "react-router-dom";
import CmbSelect from "../compon-controls/CmbSelect";
import ToastAlert from "./ToastAlert";


import { CountrySelect } from "../businessjs/CountryFn";

import { SubscriberUpdate, SubscriberGet } from "../businessjs/SubscribersFn";


class SubscriberEdit extends React.Component {
  constructor(props) {
    super(props);
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.state = {
      subscriber_id: decodedUser.subscriber_id,
      company_name: decodedUser.company_name,
      license_number: "",
      account_type: "",
      country: "",
      registration_type: "",
      preferred_timezone: "",
      shipment_preference: "",
      default_currency: "",
      updateby: decodedUser.autoid,
      optionsArray: [],
      countries: [],
      toastItems: []
      // logo_image: proxyServer + "images/subscriber/no_image.jpg"
    };
  }

  componentWillMount() {
    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      subscriber_id: this.state.subscriber_id
    };
    this.getCountry(fnInputes);
    this.getSubscriberDetails(fnInputes);
  }

  getCountry = fnInputes => {
    CountrySelect(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({ label: opt.name, value: opt }));
          dbArray.splice(0, 0, { label: "All", value: { autoid: 0 } });
          this.setState({ countries: dbArray });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  componentDidMount() {}

  getSubscriberDetails = fnInputes => {
    SubscriberGet(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({
            autoid: res[0].autoid,
            company_name: res[0].company_name,
            logo_image: res[0].logo_image,
            country: this.getCurrentCountry(res[0].country),
            default_currency: res[0].default_currency,
            license_number: res[0].license_number,
            shipment_preference: res[0].shipment_preference,
            registration_type: res[0].registration_type
          });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getCurrentCountry = value => {
    if (this.state.countries) {
      let filteredDataRows = this.state.countries.filter(el => {
        return el.value.autoid === value;
      })[0];
      return filteredDataRows;
    }
  };

  onUpdate = () => {
    let tempCountryDetails = {
      autoid: this.state.autoid,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId, // updateby
      subscriber_id: this.state.subscriber_id,
      company_name: this.state.company_name,
      logo_image: this.state.logo_image,
      country: this.state.country.value.autoid,
      default_currency: this.state.default_currency,
      license_number: this.state.license_number,
      shipment_preference: this.state.shipment_preference,
      registration_type: this.state.registration_type
    };
    this.updateSubscriberData(tempCountryDetails);
  };

  updateSubscriberData = data => {
    SubscriberUpdate(data).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);
        if (res.status === "success") {
          /*
          let subscriberDetails = {
            company_name: data.company_name
          };
          */
        }
      }
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }
    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };
    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  formatCancelButton(itemObject) {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-warning mr-1"
            onClick={() => {
              history.goBack({
                pathname: "/vehicles"
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container mt-4">
          <div className="container">
            <div className="row">
              <ToastAlert
                toastItems={this.state.toastItems}
                onItemClose={this.handleToastItemClose}
              />
              <div className=" col-12">
                <div className="card mb-1">
                  <div className="form-body">
                    <div className="card-header">
                      <h4 className="card-title">{this.state.company_name} </h4>
                    </div>
                    <form className="form mt-4" onSubmit={this.onUpdate}>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label>Company Name</label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="company_name"
                                  onChange={this.onChange}
                                  value={this.state.company_name}
                                />
                                <div className="form-control-position">
                                  <i className="ft-user" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <div className="form-group">
                              <label className="manufacture_name">
                                Country
                              </label>
                              <div style={{ width: "200px" }}>
                                <CmbSelect
                                  name="country"
                                  value={this.state.country}
                                  onChange={this.onChange}
                                  dataSource={this.state.countries}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="form-group">
                              <label className="title">Currency</label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="default_currency"
                                  value={this.state.default_currency}
                                  onChange={this.onChange}
                                />
                                <div className="form-control-position">
                                  <i className="ft-user" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="form-actions float-right mb-1">
                      {this.formatCancelButton(this.state.itemObject)}
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this.onUpdate}
                      >
                        <i className="fa fa-primary" /> Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SubscriberEdit;
