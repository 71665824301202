import React from "react";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import jwt_decode from "jwt-decode";
import Navbar from "../compon-styled/Navbar";
import ToastAlert from "./ToastAlert";
import { Route } from "react-router-dom";

import {
  ExecutiveSelectBySubscriber,
  ExecutiveUpdate
} from "../businessjs/ExecutiveFn";

class ExecutiveEdit extends React.Component {
  constructor(props) {
    super(props);
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];

    this.state = {
      subscriber_id: decodedUser.subscriber_id,
      autoid: decodedUser.autoid,
      honorifics: "",
      first_name: decodedUser.first_name,
      last_name: decodedUser.last_name,
      nick_name: "",
      contact_number: "",
      preferred_timezone: "",
      role: "",
      statuscode: "",
      updateby: decodedUser.autoid,
      toastItems: []
    };
  }

  componentWillMount() {
    const fnInputes = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      subscriber_id: this.state.subscriber_id
    };

    this.getExecutiveDetails(fnInputes);
  }

  componentDidMount() {}

  getExecutiveDetails = fnInputes => {
    ExecutiveSelectBySubscriber(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          const result = res.filter(item => item.autoid === this.state.autoid);
          this.setState({
            autoid: result[0].autoid,
            first_name: result[0].first_name,
            last_name: result[0].last_name,
            nick_name: result[0].nick_name,
            role: result[0].role,
            statuscode: result[0].statuscode,
            contact_number: result[0].contact_number
          });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onUpdate = () => {
    let tempVehicleDetails = {
      autoid: this.state.autoid,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      nick_name: this.state.nick_name,
      contact_number: this.state.contact_number,
      role: this.state.role,
      statuscode: this.state.statuscode,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId, // updateby
      subscriber_id: this.state.subscriber_id
    };
    this.updateExecutiveData(tempVehicleDetails);
  };

  updateExecutiveData = data => {
    ExecutiveUpdate(data).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        this.onToastItemAdd(res.status, res.message);
        if (res.status === "success") {
          //console.log("updated", res);
        }
      }
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }
    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };
    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  formatCancelButton(itemObject) {
    const ButtonCancel = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-warning mr-1"
            onClick={() => {
              history.goBack({
                pathname: "/vehicles"
              });
            }}
          >
            Cancel
          </button>
        )}
      />
    );
    return <ButtonCancel />;
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="container mt-4">
          <div className="container">
            <div className="row">
              <ToastAlert
                toastItems={this.state.toastItems}
                onItemClose={this.handleToastItemClose}
              />
              <div className=" col-12">
                <div className="card mb-1">
                  <div className="form-body">
                    <div className="card-header">
                      <h4 className="card-title">
                        {this.state.first_name + " " + this.state.last_name}{" "}
                      </h4>
                    </div>
                    <form className="form mt-4" onSubmit={this.onUpdate}>
                      <div className="form-body">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-4">
                              <div className="form-group">
                                <label className="title">First Name</label>
                                <div className="position-relative has-icon-left">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="first_name"
                                    onChange={this.onChange}
                                    value={this.state.first_name}
                                  />
                                  <div className="form-control-position">
                                    <i className="ft-user" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label className="title">Last Name</label>
                                <div className="position-relative has-icon-left">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="last_name"
                                    value={this.state.last_name}
                                    onChange={this.onChange}
                                  />
                                  <div className="form-control-position">
                                    <i className="ft-user" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              <div className="form-group">
                                <label className="manufacture_name">
                                  Nick Name
                                </label>
                                <div className="position-relative has-icon-left">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="nick_name"
                                    value={this.state.nick_name}
                                    onChange={this.onChange}
                                  />
                                  <div className="form-control-position">
                                    <i className="ft-user" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label className="title">Contact Number</label>
                                <div className="position-relative has-icon-left">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="contact_number"
                                    value={this.state.contact_number}
                                    onChange={this.onChange}
                                  />
                                  <div className="form-control-position">
                                    <i className="ft-user" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="form-actions float-right mb-1">
                      {this.formatCancelButton(this.state.itemObject)}
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this.onUpdate}
                      >
                        <i className="fa fa-primary" /> Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ExecutiveEdit;
