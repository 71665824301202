
import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "poi";

export const POIAddNew = objItem => {
    return axios
      .post(proxyServer + "/new", { objItem })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

export const POISelectBySubscriber = objItem => {
    return axios
      .post(proxyServer + "/getbysubscriber", { objItem } )
      .then(res => {
        //localStorage.setItem('usertoken', res.data);
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const POIDelete = objItem => {
    return axios
      .post(proxyServer + "/delete", { objItem })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

// Driver vehicles ========================================

export const POIVehicleAdd = objItem => {
  return axios
    .post(proxyServer + "/vehicle_add", { objItem } )
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const POIVehicleSelectBySubscriber = objItem => {
  return axios
    .post(proxyServer + "/getvehiclesbysubscriber", { objItem } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const POIVehicleRemove = objItem => {
  return axios
    .post(proxyServer + "/vehicle_remove", { objItem } )
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
