import React, { Component } from "react";

import {
 
  Link,
  
} from "react-router-dom";


import "bootstrap-daterangepicker/daterangepicker.css";

import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
 
import { VehicleGetAll2 } from "../businessjs/VehicleFn";

class LogList extends Component {
  constructor(props) {
    super(props);
    
    

    this.state = {
      vehicle: -1,
      displayedItemsArray: [],
      allItems: [],
       
      btnRefreshText: "Refresh", 
    };
  }

  componentDidMount() {
    this.onRefresh("load");
  }

  onRefresh = e => {
    this.setState({ btnRefreshText: "Progressing .." });


    VehicleGetAll2().then(res => {
     
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ displayedItemsArray: res, allItems: res, searchText : "" });
        }

        
      }
      this.setState({ btnRefreshText: "Go" });
    });
  };
 

  searchHandler = event => {
    let searcjQery = event.target.value.toLowerCase();
    let arrData = searcjQery.split(" ");
    let tempDataRows = this.state.allItems || [];
    let filteredDataRows = this.state.allItems || [];
    arrData.forEach(element => {
      if(element){
      filteredDataRows = tempDataRows.filter(el => {
        return (
          el.autoid.toString().indexOf(element) !== -1 ||
          el.company_name.toLowerCase().indexOf(element) !== -1 ||
          el.plate_number.toLowerCase().indexOf(element) !== -1 ||
          el.fleet_number.toLowerCase().indexOf(element) !== -1 ||
          (el.device_id || "").indexOf(element) !== -1 ||
          el.model.toLowerCase().indexOf(element) !== -1
        );
      });
    }
      tempDataRows = filteredDataRows || [];
    });

    this.setState({
      displayedItemsArray: filteredDataRows,
      searchTex: searcjQery
       

    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
 

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card mb-1">
              <div className="card-header">
                <button
                  type="button"
                  onClick={this.onRefresh}
                  className="btn btn-primary float-right"
                >
                  {this.state.btnRefreshText}
                </button>

                

                <h4 className="card-title">Vehicles List</h4>

                <p> {this.state.selectedrange} </p>
                
              </div>
              <div className="card-content collpase show">
                <div className="card-body">
                  <input
                    type="text"
                    className="card-search float-right w-50"
                    placeholder="Search, use space for multiple worlds"
                    onChange={this.searchHandler}
                  />

                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">company </th>
                        <th scope="col">web id </th>
                        <th scope="col">plate</th>
                        <th scope="col">fleet</th>
                        <th scope="col">model</th>
                        <th scope="col">device</th>
                        <th scope="col">Gps Time</th>
                        <th scope="col">action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.displayedItemsArray.map((Item, i) => (
                        <React.Fragment key={i}>
                          <tr>
                            <td>{i + 1}</td>
                            <td>{Item.company_name}</td>
                            <td>{Item.autoid}</td>
                            <td>{Item.plate_number}</td>
                            <td>{Item.fleet_number}</td>
                            <td>{Item.model}</td>
                            <td>{Item.device_id}</td>
                            <td>{AppHelper_DateAndTimeToString(Item.gpstime)}</td>
                            <td>  <Link to={`/tcplog?param1=${Item.autoid}`}>view</Link> </td>

                            
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogList;
