
import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi + "tracking";


export const LiveTrakingDataByVehicle = objItem => {
  return axios
    .post(proxyServer + "/livebyvehicle", { objItem } )
    .then(res => {
      //localStorage.setItem('usertoken', res.data);
      return res.data;
    })
    .catch(err => {
      return err;
    });
};



export const HistoryTrakingDataByVehicle= objItem =>{
  return axios
      .post (proxyServer + '/historybyvehicle', {objItem})
      .then(res =>{
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };


