const moment = require("moment");

const MegaMapMarker = function getMarker(
  masterContainer,
  info,
  vehicle,
  mapmarker,
  geoPosition
) {
  let _newMarker = {
    id: info.vehicle_id,
    mapContainer: masterContainer,
    gpstime: info.gpstime,
    showInfo: false,
    showMore: true,
    position: geoPosition,
    path: [],
    polyline: null,
    vehicle: vehicle,
    visible: true,
    mapmarker: mapmarker,
    infowindow: new masterContainer.maps.InfoWindow({
      content: vehicle.title,
      id: info.vehicle_id
    }),
    show(flag, showInfo) {
        if(this.visible === flag){
            return;
        }
        this.visible = flag;    

      if (flag) {
        this.mapmarker.setVisible(true);
        if (showInfo) {
          this.infowindow.open(this.mapContainer, this.mapmarker);
          this.showInfo = true;
        }
      } else {
          if(this.showInfo){
        this.infowindow.close();
        this.showInfo = false;
          }
        this.mapmarker.setVisible(false);
        
      }
    },

    updateInfo: function(newInfo) {
      let megaMarker = this;
      if (this.gpstime === newInfo.gpstime) {
        // return ;
      }

      let _position = {
        lat: parseFloat(newInfo.lat),
        lng: parseFloat(newInfo.log)
      };
      let ing = newInfo.ignition ? "On" : "Off";

      this.gpstime = newInfo.gpstime;
      this.position = _position;
      this.mapmarker.setPosition(_position);
      this.path.push(_position);

      if(this.path.length > 2){
      if(this.polyline === null ){
        let newpolyline = new this.mapContainer.maps.Polyline({
          path: this.path,
          geodesic: true,
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });

        // newpolyline.setMap(this.mapContainer.map);

        this.polyline = newpolyline;

      }else{

         this.polyline.setPath(this.path);
      }
    }

      this.infowindow.setContent(
        this.getInfoWindowContent(newInfo, this.vehicle)
      );

      if (ing === "Off") {
        megaMarker.mapmarker.setIcon(masterContainer.mapIcons.Stopped);
      } else {
        if (newInfo.speed === 0) {
          megaMarker.mapmarker.setIcon(masterContainer.mapIcons.Idle);
        } else {
          megaMarker.mapmarker.setIcon(masterContainer.mapIcons.Running);
        }
      }
    },
    toggleInfoWindow: function() {
      this.showInfo = !this.showInfo;

      if (this.showInfo) {
        this.infowindow.open(this.map, this.mapmarker);
      } else {
        this.infowindow.close();
      }
    },
    setShowMore(show){
      this.showMore = show;
    },
    getInfoWindowContent: function(info, vehicle) {
      let self = this;
      let _gpstime = moment(info.gpstime).tz(vehicle.timezone);
      let _ignition = info.ignition ? "On" : "Off";

      // let _pingtime = moment(info.pingtime).tz(vehicle.timezone);

      let buttonShowMore = document.createElement("button");
      buttonShowMore.setAttribute("type", "button");
      buttonShowMore.setAttribute("class", "btn btn-link btn-sm");
      buttonShowMore.innerText = vehicle.title;

      let _masterDiv = document.createElement("div");
      let _listUl = document.createElement("ul");
      if(self.showMore){
      _listUl.setAttribute("class", "d-block");
      }else{
        _listUl.setAttribute("class", "d-none");
      }
      let _listLi1 = document.createElement("li");

      _listLi1.innerText = "Ignition " + _ignition;

      buttonShowMore.addEventListener("click", function() {
        if (_listUl.getAttribute("class") === "d-block") {
          _listUl.setAttribute("class", "d-none");
          self.setShowMore(false);
        } else {
          _listUl.setAttribute("class", "d-block");
          self.setShowMore(true);
        }
      });

      let _listLi2 = document.createElement("li");
      _listLi2.innerText =
        "Speed: " + (_ignition === "On" ? info.speed : "0") + "kmph";

      let _listLi3 = document.createElement("li");
      _listLi3.innerHTML =
        _gpstime.format("DD MMM YYYY, h:mm:ss a") +
        " <br />" +
        (_gpstime.fromNow() === "a few seconds ago"? "now" : _gpstime.fromNow() ) ;

      let _listLi4 = document.createElement("li");
      _listLi4.innerHTML =
        '<a rel="noreferrer noopener" target="_blank"' +
        ' href="https://www.google.com/maps/place/' +
        info.lat +
        "," +
        info.log +
        '">' +
        " Google Map " +
        "</a>";

      let _listLi5 = document.createElement("li");
      _listLi5.innerText = info.driver_name;

      _listUl.appendChild(_listLi1);
      _listUl.appendChild(_listLi2);
      _listUl.appendChild(_listLi3);
      _listUl.appendChild(_listLi4);
      _listUl.appendChild(_listLi5);

      _masterDiv.appendChild(buttonShowMore);
      _masterDiv.appendChild(_listUl);

      return _masterDiv;
    }
  };

  masterContainer.maps.event.addListener(mapmarker, "click", function(e) {
    _newMarker.toggleInfoWindow();
  });

  return _newMarker;
};

module.exports = MegaMapMarker;
