import React from "react";
import {
  IgnitionAlerts,
  IgnitionAlertsCount
} from "../businessjs/IgnitionAlertFn";
import ToastAlert from "./ToastAlert";
import Navbar2 from "../compon-styled/Navbar";

import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import jwt_decode from "jwt-decode";
import { Route } from "react-router-dom";

import Pagination from "react-js-pagination";

import moment from "moment-timezone";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";

class Ignition extends React.Component {
  constructor(props) {
    super(props);
    this.searchHandler = this.searchHandler.bind(this);
    this.onChange = this.onChange.bind(this);

    let st = new Date();
    let d = new Date();
    let endDate = d.setMonth(d.getMonth() - 1);

    this.startDate = new Date(endDate);
    this.endDate = st;

    this.state = {
      allDataRows: [],
      filteredDataRows: [],
      toastItems: [],
      subscriber_id: 0,
      user_role: 3,
      start: 1,
      end: 10,
      activePage: undefined,

      itemsCountPerPage: 10,
      totalItemsCount: 0,

      plate_number: "",
      title: "",

      itemObject: this.props.location.state.itemObject
      //itemObject: this.props.location.query.data
    };
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      filteredDataRows = this.state.allDataRows.filter(el => {
        let searchValue = el.plate_number.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      filteredDataRows: filteredDataRows,
      start: 1,
      end: filteredDataRows.length
    });
  }

  componentDidMount() {
    if (this.props.location.state.itemObject) {
      const token = localStorage.apiAuthAccessKey;
      const decoded = jwt_decode(token);
      const decodedUser = decoded.userAccessKey[0];
      this.setState({
        subscriber_id: decodedUser.subscriber_id,
        vehicle_id: this.props.location.state.itemObject.autoid
      });

      //console.log("props=", this.props.location);

      const fnInputes = {
        subscriber_id: decodedUser.subscriber_id,
        vehicleId: this.props.location.state.itemObject.autoid,
        startDate: this.startDate.toISOString(),
        endDate: this.endDate.toISOString(),
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId
      };

      this.getIgnitionAlertCount(fnInputes);
    }
  }

  getIgnitionAlertCount = fnInputes => {
    console.log("fnInputes =", fnInputes);

    IgnitionAlertsCount(fnInputes).then(res => {
      if (res) {
        console.log("data =", res);
        if (Array.isArray(res)) {
          let alertCount = res[0].alertcount;
          if (alertCount > 0) {
            this.setState({ totalItemsCount: alertCount, activePage: 1 });
            this.getIgnitionAlerts(1);
          }
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getIgnitionAlerts = pageNumber => {
    let start = (pageNumber - 1) * this.state.itemsCountPerPage;

    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      vehicleId: this.state.vehicle_id,
      startDate: this.startDate.toISOString(),
      endDate: this.endDate.toISOString(),

      startLimit: start,
      endLimit: this.state.itemsCountPerPage
    };

    console.log("itemobject =", this.state.itemObject);
    IgnitionAlerts(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allDataRows: res });
          this.setState({ filteredDataRows: res });

          if (!this.state.activePage) {
            this.setState({
              activePage: pageNumber,
              totalItemsCount: res.length
            });
          } else {
          }
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    this.getIgnitionAlerts(pageNumber);
  };

  formatVehicleControlsButton(itemObject) {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              history.push({
                pathname: "/vehicle-controls",
                state: { itemObject: itemObject }
              });
            }}
          >
            Vehicle Controls
          </button>
        )}
      />
    );
    return <ButtonLive />;
  }

  formatLiveButton(itemObject) {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              history.push({
                pathname: "/live-tracking",
                state: { itemObject: itemObject }
              });
            }}
          >
            Live
          </button>
        )}
      />
    );
    return <ButtonLive />;
  }

  formatHistoryButton(itemObject) {
    let historyData = { autoid: itemObject.autoid, title: itemObject.title };
    const ButtonHistory = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              history.push({
                pathname: "/history-tracking",
                state: { itemObject: historyData }
              });
            }}
          >
            History
          </button>
        )}
      />
    );
    return <ButtonHistory />;
  }

  formatViewButton(item) {
    let historyData = {
      autoid: this.state.itemObject.autoid,
      title: this.state.itemObject.title,
      start: new Date(item.start_time).toLocaleString(),
      end: new Date(item.end_time).toLocaleString()
    };
    const ButtonHistory = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="nobutton"
            onClick={() => {
              history.push({
                pathname: "/viewtracking",
                state: { itemObject: historyData }
              });
            }}
          >
            View
          </button>
        )}
      />
    );
    return <ButtonHistory />;
  }

  render() {
    let showDataRows = this.state.filteredDataRows;
    return (
      <React.Fragment>
        <Navbar2 />

        <main role="main">
          <section className="manage-colom">
            <div className="container" style={{ width: "100%" }}>
              <div className="ml-15">
                <h1> Ignition Alerts</h1>
                <div className="col-12">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />
                  <div className="row">
                    <div className="col-5">
                      <div className="mb-1">
                        Title: {this.state.itemObject.title}
                      </div>
                      <div className="mb-1">
                        Plate Number: {this.state.itemObject.plage_number}
                      </div>
                      <div className="mb-1">
                        Device ID: {this.state.itemObject.device_id}
                      </div>
                    </div>
                    <div className="col-7" style={{ textAlign: "right" }}>
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        {this.formatLiveButton(this.state.itemObject)}
                        {this.formatHistoryButton(this.state.itemObject)}

                        {this.formatVehicleControlsButton(
                          this.state.itemObject
                        )}
                      </div>
                      <div>
                        From:
                        {moment(this.startDate).format(
                          "DD MMM YYYY, h:mm:ss a"
                        )}
                      </div>
                      <div>
                        To :
                        {moment(this.endDate).format("DD MMM YYYY, h:mm:ss a")}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-5">
                            <h4 className="card-title"> </h4>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Start</th>
                              <th>End</th>
                              <th>Distance</th>
                              <th>Duration</th>
                              <th>View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {showDataRows.map((Item, i) => (
                              <React.Fragment key={i}>
                                <tr>
                                  <td>
                                    <div>
                                      <div>{Item.start_place}</div>
                                      <div>{Item.start_geo}</div>
                                      <div>
                                        {AppHelper_DateAndTimeToString(
                                          Item.start_time
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div>{Item.end_place}</div>
                                      <div>{Item.end_geo}</div>
                                      <div>
                                        {AppHelper_DateAndTimeToString(Item.end_time)}
                                      </div>
                                    </div>
                                  </td>
                                  <td>{Item.mileage} </td>
                                  <td />
                                  <td>{this.formatViewButton(Item)}</td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                        <center>
                          {showDataRows.length ? "" : "No records available"}
                        </center>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12" style={{ textAlign: "right" }}>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                          //   prevPageText="prev"
                          //   nextPageText="next"
                          //   firstPageText="first"
                          //   lastPageText="last"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default Ignition;
