import React from "react";
import Navbar from "../compon-styled/Navbar";
import jwt_decode from "jwt-decode";
import ExportDataExcel from "../compon-controls/ExportDataExcel";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import {
  AcclerationAlertsList,
  VehicleUtilizationExportList,
  BrakingAlertsList,
  CorneringAlertsList,
  IgnitionAlertsList,
  OverSpeedAlertsList,
  POIAlertsList,
  IdlingAlertsList,
  DailyAlertSummaryList
} from "../businessjs/AlertFn";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import {
  ReconnectAlertsList,
  TimedPingAlertsList
} from "../businessjs/AlertFn";
import { css } from "@emotion/core";
import { RiseLoader } from "react-spinners";
import { GetVehicleListById } from "../businessjs/VehicleFn";
import { GetDeviceStatusExcel, GetAlertsSummaryExcel,   
          GetConsolidatedAlertsSummaryExcel,  GetConsolidatedUsageSummaryExcel,   
          GetUsageSummaryExcel   } from "../businessjs/ReportFn";
import {GetDriverLogListExcel} from "../businessjs/DriverLogFn";

const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class ExportToExcel extends React.Component {
  state = {
    subscriber_id: 0,
    type: this.props.location.state.type,
    name: this.props.location.state.name,
    showDataRows: [],
    vehicle: this.props.location.state.vehicle,
    loading: false,
    searchTex: this.props.location.state.searchTex
  };

  componentDidMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({ subscriber_id: decodedUser.subscriber_id });

    let type = this.props.location.state.type;
    let vehicle = this.props.location.state.vehicle;
    let vehicleId = 0;
    if (vehicle) {
      vehicleId = vehicle.value.autoid;
    }
    let driver = this.props.location.state.driver;
    let driverId = 0;
    if (driver) {
      driverId = driver.value.autoid;
    }
    const fnInputes = {
      subscriber_id: decodedUser.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      startDate: this.props.location.state.startDate,
      endDate: this.props.location.state.endDate,
      vehicleId: vehicleId,
      startLimit: 0,
      endLimit: this.props.location.state.totalItemsCount,
      searchTex: this.props.location.state.searchTex,
      vehicle_id: vehicleId,
      executive_id: decodedUser.autoid,
      driverId:driverId
    };
    if (type === "accelaration") this.exportAcceleration(fnInputes);
    else if (type === "braking") this.exportBraking(fnInputes);
    else if (type === "cornering") this.exportCornering(fnInputes);
    else if (type === "ignition") this.exportIgnition(fnInputes);
    else if (type === "overspeed") this.exportOverSpeed(fnInputes);
    else if (type === "poi") this.exportPOI(fnInputes);
    else if (type === "idling") this.exportIdling(fnInputes);
    else if (type === "dailyalert") this.exportDailyAlert(fnInputes);
    else if (type === "vehiclelist") this.exportVehicleList(fnInputes);
    else if (type === "vehicleUtilization")
      this.exportVehicleUtilization(fnInputes);
    else if (type === "reconnect") this.exportReconnectAlert(fnInputes);
    else if (type === "timedping") this.exportTimedPingAlert(fnInputes);
    else if (type === "rptdevicestatus")
      this.exportDeviceStatusReport(fnInputes);
    else if (type === "driver_log")
      this.exportDriverLogReport(fnInputes);

    switch(type )
    {

      case "accelaration" :
          this.exportAcceleration(fnInputes);
          break;
      case "braking" :
          this.exportBraking(fnInputes);
          break;
      case "cornering" :
         this.exportCornering(fnInputes);
         break;
      case "ignition" :
         this.exportIgnition(fnInputes);
         break;
      case "overspeed":
         this.exportOverSpeed(fnInputes);
         break;
      case "poi" :
         this.exportPOI(fnInputes);
         break;
      case "idling" :
         this.exportIdling(fnInputes);
         break;
      case "dailyalert":
         this.exportDailyAlert(fnInputes);
         break;
      case "vehiclelist" :
        this.exportVehicleList(fnInputes);
        break;
      case "vehicleUtilization":
        this.exportVehicleUtilization(fnInputes);
        break;
      case "reconnect" :
         this.exportReconnectAlert(fnInputes);
         break;
      case "timedping" :
         this.exportTimedPingAlert(fnInputes);
         break;
      case "rptdevicestatus" :        
        this.exportDeviceStatusReport(fnInputes);
        break;
      case "driver_log" :
        this.exportDriverLogReport(fnInputes);
        break;

      case "dated_Alert_smry" : 
        this.exportAlertsSummary(fnInputes);
        break;
      case "con_alert_smry":
        this.exportConsolidatedAlertsSummary(fnInputes);
        break;
      case "con_usage_smry":
        this.exportConsolidatedUsageSummary(fnInputes);
        break;
        default: // "dated_usage_smry" :
        this.exportUsageSummary(fnInputes);
        break;

    }
    
  }

  exportAcceleration = fnInputes => {
    this.setState({
      loading: true
    });
    AcclerationAlertsList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportBraking = fnInputes => {
    this.setState({
      loading: true
    });
    BrakingAlertsList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportCornering = fnInputes => {
    this.setState({
      loading: true
    });
    CorneringAlertsList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportIgnition = fnInputes => {
    this.setState({
      loading: true
    });
    IgnitionAlertsList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportOverSpeed = fnInputes => {
    this.setState({
      loading: true
    });
    OverSpeedAlertsList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportPOI = fnInputes => {
    this.setState({
      loading: true
    });
    POIAlertsList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportIdling = fnInputes => {
    this.setState({
      loading: true
    });
    IdlingAlertsList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportDailyAlert = fnInputes => {
    this.setState({
      loading: true
    });
    DailyAlertSummaryList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportVehicleList = fnInputes => {
    this.setState({
      loading: true
    });
    GetVehicleListById(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let displayVehicleList = res.filter(function(a) {
            if (!this[a.plate_number]) {
              this[a.plate_number] = true;
              return true;
            }
            
            return false;
          }, Object.create(null));
          this.setState({ showDataRows: displayVehicleList, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportReconnectAlert = fnInputes => {
    this.setState({
      loading: true
    });
    ReconnectAlertsList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportTimedPingAlert = fnInputes => {
    this.setState({
      loading: true
    });
    TimedPingAlertsList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportVehicleUtilization = fnInputes => {
    this.setState({
      loading: true
    });
    VehicleUtilizationExportList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportDeviceStatusReport = fnInputes => {
    this.setState({
      loading: true
    });
    GetDeviceStatusExcel(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {

          // let vehicles = res.filter(function(a) {
          //   if (!this[a.autoid]) {
          //     this[a.autoid] = true;
          //     return true;
          //   }
          // }, Object.create(null));

          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportDriverLogReport = fnInputes => {
    this.setState({
      loading: true
    });
    GetDriverLogListExcel(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportVehicleUtilization = fnInputes => {
    this.setState({
      loading: true
    });
    VehicleUtilizationExportList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };


  exportAlertsSummary = fnInputes => {
    this.setState({
      loading: true
    });
    GetAlertsSummaryExcel(fnInputes).then(res => {      
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };


  exportConsolidatedAlertsSummary = fnInputes => {
    this.setState({
      loading: true
    });
    GetConsolidatedAlertsSummaryExcel(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  exportConsolidatedUsageSummary = fnInputes => {
    this.setState({
      loading: true
    });
    GetConsolidatedUsageSummaryExcel(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };


  exportUsageSummary = fnInputes => {
    this.setState({
      loading: true
    });
    GetUsageSummaryExcel(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ showDataRows: res, loading: false });
        } else {
          this.setState({
            loading: false
          });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };


  render() {
    let showDate =
      this.props.location.state.type === "rptdevicestatus" ||
      this.props.location.state.type === "vehiclelist" ||
      this.props.location.state.type === "driver_log"
        ? "row hidden"
        : "row";

    return (
      <React.Fragment>
        <Navbar />
        <div className="container mt-4">
          <div className="container">
            <div className="row">
              <div className=" col-md-10 offset-md-2">
                <div className="card mb-1">
                  <div className="card-header">
                    <h4 className="card-title">{this.state.name} </h4>
                  </div>
                  <div className="card-content collpase show">
                    <div className="card-body">
                      <div className=" col-12">
                        <div className={showDate}>
                          <label>Start Date :</label>
                          <h6 className="m-1">
                            {AppHelper_DateAndTimeToString(
                              this.props.location.state.startDate
                            )}
                          </h6>

                          <label style={{ paddingLeft: "3em" }}>
                            End Date :
                          </label>
                          <h6 className="m-1">
                            {AppHelper_DateAndTimeToString(
                              this.props.location.state.endDate
                            )}
                          </h6>
                          <label style={{ paddingLeft: "3em" }}>
                            Vehicle :
                          </label>
                          <h6 className="m-1">
                            {this.state.type === "vehiclelist"
                              ? this.state.searchTex
                              : this.state.vehicle === undefined
                              ? ""
                              : this.state.vehicle.value.title}
                          </h6>
                        </div>

                        <div
                          className="row"
                          style={{ height: "98px", alignContent: "center" }}
                        >
                          <RiseLoader
                            css={loaderCSS}
                            sizeUnit={"px"}
                            size={10}
                            color={"#123abc"}
                            loading={this.state.loading}
                          />
                          <ExportDataExcel
                            name={this.state.name}
                            dataSource={this.state.showDataRows}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ExportToExcel;
