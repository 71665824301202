import React, { Component } from "react";
import Navbar2 from "./Navbar";

class VehicleTile extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar2 />

        <section class="manage-colom">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <h1>Manage Vehicles</h1>
                <div class="row tile-colom">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="tile-one">
                      <div class="nfcn">
                        <div class="nfcn-one">
                          <img src="images/engine.png" alt="icon" />
                        </div>
                        <div class="nfcn-one">
                          <img src="images/bell.png" alt="icon" />
                        </div>
                      </div>
                      <div class="tile-icon">
                        <h1>
                          <img src="images/truck.png" alt="icon" />
                        </h1>
                      </div>
                      <div class="tile-head">
                        <h2>Vehicle Name</h2>
                      </div>
                      <div class="location-name">
                        <ul>
                          <li>
                            <i class="fa fa-map-marker" aria-hidden="true" />
                            Dahl Al Hamam
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="tile-two">
                      <div class="nfcn">
                        <div class="nfcn-one">
                          <img src="images/engine.png" alt="icon" />
                        </div>
                        <div class="nfcn-one">
                          <img src="images/bell.png" alt="icon" />
                        </div>
                      </div>
                      <div class="tile-icon">
                        <h1>
                          <img src="images/car.png" alt="icon" />
                        </h1>
                      </div>
                      <div class="tile-head">
                        <h2>Vehicle Name</h2>
                      </div>
                      <div class="location-name">
                        <ul>
                          <li>
                            <i class="fa fa-map-marker" aria-hidden="true" />
                            Dahl Al Hamam
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="tile-three">
                      <div class="nfcn">
                        <div class="nfcn-one">
                          <img src="images/engine_disabled.png" alt="icon" />
                        </div>
                        <div class="nfcn-one">
                          <img src="images/bell.png" alt="icon" />
                        </div>
                      </div>
                      <div class="tile-icon">
                        <h1>
                          <img src="images/bus.png" alt="icon" />
                        </h1>
                      </div>
                      <div class="tile-head">
                        <h2>Vehicle Name</h2>
                      </div>
                      <div class="location-name">
                        <ul>
                          <li>
                            <i class="fa fa-map-marker" aria-hidden="true" />
                            Dahl Al Hamam
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div class="tile-four">
                      <div class="nfcn">
                        <div class="nfcn-one">
                          <img src="images/engine.png" alt="icon" />
                        </div>
                        <div class="nfcn-one">
                          <img src="images/bell.png" alt="icon" />
                        </div>
                      </div>
                      <div class="tile-icon">
                        <h1>
                          <img src="images/suv.png" alt="icon" />
                        </h1>
                      </div>
                      <div class="tile-head">
                        <h2>Vehicle Name</h2>
                      </div>
                      <div class="location-name">
                        <ul>
                          <li>
                            <i class="fa fa-map-marker" aria-hidden="true" />
                            Dahl Al Hamam
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default VehicleTile;
