import React from "react";
import { SubscriptionWithVehicleAddNew } from "../businessjs/SubscriptionFn";

import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";
import SubscriberSelect from "../compon-controls/SubscriberSelect";
import { SubscriberGetAll } from "../businessjs/SubscribersFn";
import VehicleTypeSelect from "../compon-controls/VehicleTypeSelect";

import { VehicleTypes } from "../businessjs/VehicleFn";

class SubscriptionWithVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriber_id: 0,
      title: "Subscription",
      type: 1,
      device_id: "",
      subscription_code: "",
      //expiry_date: "",
      re_newal_interval: 365,

      toastItems: [],
      subscribers: [],

      vtitle: "",
      plate_number: "",
      fleet_number: "",
      manufacture_name: "",
      model: "",
      year: "",
      fuel_type: "Diesel",
      engine: "",
      tyre: "",
      vehicleTypes: [],
      vehicle_type: {
        label: "Car",
        value: { autoid: 1, title: "Car", imagepath: "Car" }
      },
      sim: "",
      sim_provider: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    SubscriberGetAll({
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        if (res.userdata) {
          //this.state.subscribers.map(sub => (

          var jsonArr = [];
          res.userdata.map(data => {
            jsonArr.push({ value: data.autoid, text: data.company_name });
            return 0;
          });

          this.setState({ subscribers: jsonArr });
        }
      }
    });

    VehicleTypes({
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({ label: opt.title, value: opt }));
          this.setState({ vehicleTypes: dbArray });
        }
      }
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // subscriberChange = selected => {
  //   console.log(selected.target.value)
  //   this.setState({ subscriber_id: selected.target.value });
  // };

  onSubmit(e) {
    e.preventDefault();

    const regFormInputes = {
      subscriber_id: this.state.subscriber_id.value.autoid,
      title: this.state.title,
      type: this.state.type,
      device_id: this.state.device_id,
      subscription_code: this.state.subscription_code,
      //expiry_date: this.state.expiry_date,
      re_newal_interval: this.state.re_newal_interval,

      vtitle: this.state.vtitle,
      plate_number: this.state.plate_number,
      fleet_number: this.state.fleet_number,
      manufacture_name: this.state.manufacture_name,
      model: this.state.model,
      year: this.state.year,
      fuel_type: this.state.fuel_type,
      engine: this.state.engine,
      tyre: this.state.tyre,
      vehicle_type: this.state.vehicle_type.value.autoid,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      sim: this.state.sim,
      sim_provider: this.state.sim_provider
    };

    console.log(" submit regFormInputes ", regFormInputes);

    SubscriptionWithVehicleAddNew(regFormInputes).then(res => {
      if (res) {
        this.onToastItemAdd(res.status, res.message);
        if (res.status === "success") {
          this.setState({
            title: "Subscription",
            type: 1,
            device_id: "",
            subscription_code: "",
            re_newal_interval: 365,
            vtitle: "",
            plate_number: "",
            fleet_number: "",
            manufacture_name: "",
            model: "",
            year: "",
            fuel_type: "Diesel",
            engine: "",
            tyre: "",
            vehicle_type: {
              label: "Car",
              value: { autoid: 1, title: "Car", imagepath: "Car" }
            }
          });
        }
      }
    });
  }

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    //let showDataRows = this.state.filteredDataRows;

    return (
      <React.Fragment>
        <Navbar />
        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <h1> New Vehicle With Subscription</h1>
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />
                  <div className="col-12">
                    <div className="card mb-1">
                      <div className="card-header">
                        <h4 className="card-title"> Add New </h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <form className="form" onSubmit={this.onSubmit}>
                            <div className="form-body">
                              <div className="row">
                                <div className="col-6">
                                  <div className="form-group">
                                    <label>Subscriber</label>
                                    <div className="position-relative has-icon-left">
                                      <SubscriberSelect
                                        name="subscriber_id"
                                        value={this.state.subscriber_id}
                                        onChange={this.onChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label>Title</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        value={this.state.title}
                                        onChange={this.onChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label>Device Id</label>
                                    <div className="position-relative has-icon-left">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="device_id"
                                        value={this.state.device_id}
                                        onChange={this.onChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label>Type</label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="type"
                                        onChange={this.onChange}
                                        value={this.state.type}
                                      >
                                        <option value=""> Select </option>
                                        <option value="1">Tracking</option>
                                        <option value="2">Other</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label>Re-Newal Interval</label>
                                    <div className="position-relative has-icon-left">
                                      <select
                                        className="form-control"
                                        name="re_newal_interval"
                                        onChange={this.onChange}
                                        value={this.state.re_newal_interval}
                                      >
                                        <option value="0"> Select </option>
                                        <option value="30">1 Month</option>
                                        <option value="90">3 Month</option>
                                        <option value="365">1 Year</option>
                                        <option value="730">2 Year</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-6">
                                      <div className="form-group">
                                        <label>Vehicle Title</label>
                                        <div className="position-relative has-icon-left">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="vtitle"
                                            value={this.state.vtitle}
                                            onChange={this.onChange}
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-6">
                                      <div className="form-group">
                                        <label>Type</label>
                                        <div className="position-relative has-icon-left">
                                          <VehicleTypeSelect
                                            name="vehicle_type"
                                            value={this.state.vehicle_type}
                                            onChange={this.onChange}
                                            dataSource={this.state.vehicleTypes}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 col-12">
                                      <div className="form-group">
                                        <label>Plate Number</label>
                                        <div className="position-relative has-icon-left">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="plate_number"
                                            value={this.state.plate_number}
                                            onChange={this.onChange}
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>{" "}
                                    <div className="col-md-6 col-12">
                                      <div className="form-group">
                                        <label>Fleet Number</label>
                                        <div className="position-relative has-icon-left">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="fleet_number"
                                            value={this.state.fleet_number}
                                            onChange={this.onChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 col-12">
                                      <div className="form-group">
                                        <label>Manufacture Name</label>
                                        <div className="position-relative has-icon-left">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="manufacture_name"
                                            value={this.state.manufacture_name}
                                            onChange={this.onChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                      <div className="form-group">
                                        <label>Model</label>
                                        <div className="position-relative has-icon-left">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="model"
                                            value={this.state.model}
                                            onChange={this.onChange}
                                          />
                                        </div>
                                      </div>
                                    </div>{" "}
                                    <div className="col-md-4 col-12">
                                      <div className="form-group">
                                        <label>Year</label>
                                        <div className="position-relative has-icon-left">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="year"
                                            value={this.state.year}
                                            onChange={this.onChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-4 col-12">
                                      <div className="form-group">
                                        <label>Fuel Type</label>
                                        <div className="position-relative has-icon-left">
                                          <select
                                            className="form-control"
                                            name="fuel_type"
                                            onChange={this.onChange}
                                            value={this.state.fuel_type}
                                          >
                                            <option value=""> Select </option>
                                            <option value="Petrol">
                                              Petrol
                                            </option>
                                            <option value="Diesel">
                                              Diesel
                                            </option>
                                            <option value="Gas">Gas</option>
                                            <option value="Other">Other</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>{" "}
                                    <div className="col-md-4 col-12">
                                      <div className="form-group">
                                        <label>Engine</label>
                                        <div className="position-relative has-icon-left">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="engine"
                                            value={this.state.engine}
                                            onChange={this.onChange}
                                          />
                                        </div>
                                      </div>
                                    </div>{" "}
                                    <div className="col-md-4 col-12">
                                      <div className="form-group">
                                        <label>Tyre</label>
                                        <div className="position-relative has-icon-left">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="tyre"
                                            value={this.state.tyre}
                                            onChange={this.onChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Sim</label>
                                        <div className="position-relative has-icon-left">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="sim"
                                            value={this.state.sim}
                                            onChange={this.onChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Sim Provider</label>
                                        <div className="position-relative has-icon-left">
                                          <select
                                            className="form-control"
                                            name="sim_provider"
                                            onChange={this.onChange}
                                            value={this.state.sim_provider}
                                          >
                                            <option value=""> Select </option>
                                            <option value="Ooredoo">
                                              Ooredoo
                                            </option>
                                            <option value="Vodafone">
                                              Vodafone
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-actions right">
                                <button
                                  type="reset"
                                  className="btn btn-warning mr-1"
                                >
                                  <i className="ft-x" /> Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-check-square-o" /> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default SubscriptionWithVehicle;
