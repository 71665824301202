import React from "react";
import {
  SupportMemberAddNew,
  SupportMemberGetAll,
  SupportMemberUpdate,
  SupportMemberDelete
} from "../businessjs/SupportMemberFn";
import ToastAlert from "./ToastAlert";
import Navbar from "../compon-styled/SupportCenterNavBar";

class SupportMemberItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
      editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" },
      editMode: false,
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      auth_password: ""
    };

    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onDelete(e) {
    console.log("Delete BTN Text", this.state.deleteBtn.btnText);

    switch (this.state.deleteBtn.btnText) {
      case "Delete":
        this.setState({
          deleteBtn: { cssClass: "btn btn-danger btn-sm", btnText: "Confirm ?" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });
        break;
      case "Update":
        let temObj = this.props.itemObject;
        temObj.first_name = this.state.first_name;
        temObj.last_name = this.state.last_name;

        this.props.onUpdate(temObj);
        this.setState({ editMode: false });
        this.setState({
          deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
        });

        break;
      case "Confirm ?":
        this.props.onDelete(this.props.itemObject);

        break;
      default:
        this.setState({
          deleteBtn: { cssClass: "btn btn-danger btn-sm", btnText: "Confirm ?" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });
        break;
    }
  }

  onEdit(e) {
    if (this.state.editBtn.btnText === "Cancel") {
      this.setState({ editMode: false });
      this.setState({
        deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
      });
    } else if (this.state.editBtn.btnText === "Edit") {
      this.setState({ editMode: true });
      this.setState({
        deleteBtn: { cssClass: "btn btn-primary btn-sm", btnText: "Update" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
      });

      this.setState({ first_name: this.props.itemObject.first_name });
      this.setState({ last_name: this.props.itemObject.last_name });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  formateBody(mode) {
    if (mode) {
      return (
        <React.Fragment>
          <div className="mb-2 mt-2">
            <label htmlFor="SupportMember_name">First Name</label>
            <input
              type="text"
              className="form-control"
              name="first_name"
              value={this.state.first_name}
              onChange={this.onChange}
              required
            />

            <label htmlFor="short_code">Last Name</label>
            <input
              type="text"
              className="form-control"
              name="last_name"
              value={this.state.last_name}
              onChange={this.onChange}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span>
            {this.props.itemObject.first_name} {this.props.itemObject.last_name}
          </span>
          <span className="phone">{this.props.itemObject.email}</span>
          <span className="phone">{this.props.itemObject.contact_number}</span>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <li>
        {this.formateBody(this.state.editMode)}
        <div className="btn-group float-right">
          <button
            type="button"
            onClick={this.onEdit}
            className={this.state.editBtn.cssClass}
          >
            {this.state.editBtn.btnText}
          </button>
          <button
            type="button"
            onClick={this.onDelete}
            className={this.state.deleteBtn.cssClass}
          >
            {this.state.deleteBtn.btnText}
          </button>
        </div>
      </li>
    );
  }
}

class SupportMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allDataRows: [],
      filteredDataRows: [],
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      auth_password: "",
      toastItems: []
      // user_token: localStorage.apiAuthAccessKey,
      // user_token_id: localStorage.apiAuthAccessId
    };

    this.searchHandler = this.searchHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      filteredDataRows = this.state.allDataRows.filter(el => {
        let searchValue = el.first_name.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      filteredDataRows: filteredDataRows
    });
    console.log("inside searchHandler", this.state.filteredDataRows);
  }

  componentDidMount() {
    try {
      SupportMemberGetAll({
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId
      }).then(res => {
        if (res) {
          this.setState({ allDataRows: res.userdata });
          this.setState({ filteredDataRows: res.userdata });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const regFormInputes = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      contact_number: this.state.contact_number,
      auth_password: this.state.auth_password,
      updateby: 899,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    };

    SupportMemberAddNew(regFormInputes).then(res => {
      if (res) {
        //this.props.history.push(`/profile`);
        //console.log('on submit data',res); value={this.state.SupportMember_name}
        this.setState({ SupportMember_name: "", short_code: "" });
        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          SupportMemberGetAll(regFormInputes).then(res => {
            if (res) {
              this.setState({ allDataRows: res.userdata });
              this.setState({ filteredDataRows: res.userdata });
            }
          });
        }
      }
    });
  }

  handleOnDelete = itemObject => {
    const newFiltedItems = this.state.filteredDataRows.filter(
      c => c.autoid !== itemObject.autoid
    );

    const newAllItems = this.state.allDataRows.filter(
      c => c.autoid !== itemObject.autoid
    );

    this.setState({ allDataRows: newAllItems });
    this.setState({ filteredDataRows: newFiltedItems });

    SupportMemberDelete({
      itemObject,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        this.onToastItemAdd(res.status, res.message);
      }
    });
  };

  handleOnUpdate = itemObject => {
    SupportMemberUpdate({
      itemObject,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId
    }).then(res => {
      if (res) {
        //this.props.history.push(`/profile`);
        //console.log('on submit data',res);
        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          SupportMemberGetAll({
            itemObject,
            user_token: localStorage.apiAuthAccessKey,
            user_token_id: localStorage.apiAuthAccessId
          }).then(res => {
            if (res) {
              this.setState({ allDataRows: res.userdata });
              this.setState({ filteredDataRows: res.userdata });
            }
          });
        }
      }
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd(type, message) {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function(e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  }

  render() {
    // console.log("filteredDataRows",this.state.filteredDataRows)
    let showDataRows = this.state.filteredDataRows;

    return (
      <React.Fragment>
        <Navbar />
        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <h1> Member </h1>
                <div className="row">
                  <ToastAlert
                    toastItems={this.state.toastItems}
                    onItemClose={this.handleToastItemClose}
                  />
                  <div className="col-md-6 col-12">
                    <div className="card mb-1">
                      <div className="card-header">
                        <h4 className="card-title">Support Member Form</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <form className="form" onSubmit={this.onSubmit}>
                            <div className="form-body">
                              <div className="form-group">
                                <label htmlFor="SupportMember_name">
                                  First Name
                                </label>
                                <div className="position-relative has-icon-left">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="first_name"
                                    onChange={this.onChange}
                                    value={this.state.first_name}
                                    required
                                  />
                                  <div className="form-control-position">
                                    <i className="ft-user" />
                                  </div>
                                </div>
                              </div>

                              <div className="form-group">
                                <label htmlFor="short_code">Last Name</label>
                                <div className="position-relative has-icon-left">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="last_name"
                                    value={this.state.last_name}
                                    onChange={this.onChange}
                                    required
                                  />
                                  <div className="form-control-position">
                                    <i className="fa fa-briefcase" />
                                  </div>
                                </div>
                              </div>

                              <div className="form-group">
                                <label htmlFor="short_code">Email</label>
                                <div className="position-relative has-icon-left">
                                  <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    required
                                  />
                                  <div className="form-control-position">
                                    <i className="fa fa-briefcase" />
                                  </div>
                                </div>
                              </div>

                              <div className="form-group">
                                <label htmlFor="short_code">
                                  Contact Number
                                </label>
                                <div className="position-relative has-icon-left">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="contact_number"
                                    value={this.state.contact_number}
                                    onChange={this.onChange}
                                  />
                                  <div className="form-control-position">
                                    <i className="fa fa-briefcase" />
                                  </div>
                                </div>
                              </div>

                              <div className="form-group">
                                <label htmlFor="short_code">Password</label>
                                <div className="position-relative has-icon-left">
                                  <input
                                    type="Password"
                                    className="form-control"
                                    name="auth_password"
                                    value={this.state.auth_password}
                                    onChange={this.onChange}
                                    required
                                  />
                                  <div className="form-control-position">
                                    <i className="fa fa-briefcase" />
                                  </div>
                                </div>
                              </div>

                              <div className="form-actions right">
                                <button
                                  type="button"
                                  className="btn btn-warning mr-1"
                                >
                                  <i className="ft-x" /> Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-check-square-o" /> Save
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="card">
                      <div className="card-header">
                        <input
                          type="text"
                          className="card-search float-right"
                          placeholder="Search... "
                          onChange={this.searchHandler}
                        />
                        <h4 className="card-title">Support Member List</h4>
                      </div>
                      <div className="card-content collpase show">
                        <div className="card-body">
                          <div className="filterlist">
                            <ul>
                              {showDataRows.map(el => {
                                return (
                                  <SupportMemberItem
                                    key={el.autoid}
                                    itemObject={el}
                                    onDelete={this.handleOnDelete}
                                    onUpdate={this.handleOnUpdate}
                                  />
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default SupportMember;
