import axios from "axios";
import Api from "./APIConfig";

const proxyServer = Api.serverapi;

export const GetDriverLogCount = objItem => {
  return axios
    .post(proxyServer + "driverlog/getdriverlogcount", {
      objItem
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GetDriverLogList = objItem => {
  return axios
    .post(proxyServer + "driverlog/getdriverloglist", {
      objItem
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GetDriverLogListExcel = objItem => {
  return axios
    .post(proxyServer + "driverlog/getdriverloglistexcel", {
      objItem
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const DriverForCombo = objItem => {
  return axios
    .post(proxyServer + "driverlog/getDriverforCombo", { objItem })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
