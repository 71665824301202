import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import {
  AuthHelperValidateOverToken,
  AuthHelperGerSubscriberRoleText
} from "../businessjs/AuthHelper";
import Navbar from "../compon-styled/Navbar";
import { AuthHelperLogout} from "../businessjs/AuthHelper";
import {AppAuthTokenCheck} from "../businessjs/AppAuthTokenFn";

class SubscriberHome extends Component {
  componentDidMount() {

    
    if (AuthHelperValidateOverToken("layoutsubscriber")) {

      const regFormInputes = { 
        user_token: localStorage.apiAuthAccessKey,
        user_token_id: localStorage.apiAuthAccessId
      };
  
      AppAuthTokenCheck(regFormInputes).then(res => {
        if (res) {
          if (res.status === "success") {
            const token = localStorage.apiAuthAccessKey;
            const decoded = jwt_decode(token);
            const decodedUser = decoded.userAccessKey[0];
      
            this.setState({
              subscriber_id: decodedUser.subscriber_id,
              first_name: decodedUser.first_name,
              last_name: decodedUser.last_name,
              email: decodedUser.email,
              account_type: decodedUser.account_type,
              company_name: decodedUser.company_name,
              loginType: "subscriber",
              userType: "subscriber",
              role: decodedUser.role,
              roleText: AuthHelperGerSubscriberRoleText(decodedUser.role)
            });

            this.props.history.push(`/vehicles`);

          }
            else
            {
              AuthHelperLogout(res);
              this.props.history.push(`/login`);
            }
           
        }
      });
     
    } else {
      this.props.history.push(`/login`);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />

        <main role="main">
          <section>
            <div className="container mt-4">
              <div className="container">
                <div className="row">
                  <h1> Subscriber Home </h1>

                  <small> This is parking page of dashboard! </small>
                </div>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default SubscriberHome;
