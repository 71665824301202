import React, { Component } from "react";
import Navbar from "../compon-styled/Navbar";
import jwt_decode from "jwt-decode";
import Pagination from "react-js-pagination";
import { AuthHelperAccessCheck } from "../businessjs/AuthHelper";
import {
  GetDriverLogCount,
  GetDriverLogList,
  DriverForCombo
} from "../businessjs/DriverLogFn";
import { AppHelper_DateAndTimeToString } from "../businessjs/AppHelperFn";
import { Route } from "react-router-dom";
import Datetime from "react-datetime";
import CmbSelect from "../compon-controls/CmbSelect";
import { RiseLoader } from "react-spinners";
import { css } from "@emotion/core";
const loaderCSS = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class DriverLog extends Component {
  state = {
    allDataRows: [],
    filteredDataRows: [],
    toastItems: [],
    subscriber_id: 0,
    executive_id: 0,
    start: 1,
    end: 10,
    activePage: undefined,

    itemsCountPerPage: 25,
    totalItemsCount: 0,
    loading: true,
    startDate: "",
    endDate: "",
    drivers: [],
    driver: undefined
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentWillMount() {
    const token = localStorage.apiAuthAccessKey;
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];
    this.setState({
      subscriber_id: decodedUser.subscriber_id,
      executive_id: decodedUser.autoid,
      startDate: new Date(),
      endDate: new Date()
    });
  }

  componentDidMount() {
    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      executive_id: this.state.executive_id
    };
    this.getDrivers(fnInputes);
    this.getDriverLogCount();
  }

  getDrivers = fnInputes => {
    DriverForCombo(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let dbArray = res.map(opt => ({
            label: opt.driver_name,
            value: opt
          }));
          dbArray.splice(0, 0, { label: "All", value: { autoid: 0 } });

          this.setState({ drivers: dbArray });
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getDriverLogCount = () => {
    this.setState({ loading: true });
    const { startDate, endDate, driver } = this.state;
    let driverId = 0;
    if (driver) {
      driverId = driver.value.autoid;
    }

    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      startDate: startDate,
      endDate: endDate,
      driverId: driverId,
      executive_id: this.state.executive_id
    };
    GetDriverLogCount(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          let driverCount = res[0].count;
          if (driverCount > 0) {
            this.setState({ totalItemsCount: driverCount, activePage: 1 });
            this.getDriverLogList(1);
          } else {
            this.setState({
              allDataRows: [],
              filteredDataRows: [],
              totalItemsCount: 0,
              activePage: 1,
              loading: false
            });
          }
        } else {
          this.setState({ loading: false });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  getDriverLogList = pageNumber => {
    const { startDate, endDate, driver, itemsCountPerPage } = this.state;
    let driverId = 0;
    if (driver) {
      driverId = driver.value.autoid;
    }

    let start = (pageNumber - 1) * this.state.itemsCountPerPage;

    const fnInputes = {
      subscriber_id: this.state.subscriber_id,
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      startLimit: start,
      endLimit: itemsCountPerPage,
      executive_id: this.state.executive_id,
      startDate: startDate,
      endDate: endDate,
      driverId: driverId
    };

    GetDriverLogList(fnInputes).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({
            allDataRows: res,
            filteredDataRows: res,
            loading: false
          });
          if (!this.state.activePage) {
            this.setState({ totalItemsCount: res.length, activePage: 1 });
          } else {
          }
        } else {
          this.setState({ loading: false });
          AuthHelperAccessCheck(res);
        }
      }
    });
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    this.getDriverLogList(pageNumber);
  };

  onStartDateChange = e => {
    this.setState({ startDate: e._d });
  };

  onEndDateChange = e => {
    this.setState({ endDate: e._d });
  };

  exportToExceldButton() {
    const ButtonExport = () => (
      <Route
        render={({ history }) => (
          <React.Fragment>
            <button
              type="button"
              className="btn btn-primary ml-2 fa fa-file-excel-o"
              onClick={() => {
                history.push({
                  pathname: "/exporttoexcel",
                  state: {
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    driver: this.state.driver,
                    totalItemsCount: this.state.totalItemsCount,
                    name: "Driver Log",
                    type: "driver_log"
                  }
                });
              }}
            />
          </React.Fragment>
        )}
      />
    );
    return <ButtonExport />;
  }

  render() {
    let showDataRows = this.state.filteredDataRows;
    return (
      <React.Fragment>
        <Navbar />
        <section className="manage-colom">
          <div className="container">
            <main role="main">
              <section>
                <div className="mt-4" style={{ width: "100%" }}>
                  <div>
                    <h1> Driver Log </h1>
                    <div className="row">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <div className="row">
                              <div className="col-3">
                                <div className="row">
                                  <label className="mr-3">Start :</label>
                                  <div style={{ width: "200px" }}>
                                    <Datetime
                                      defaultValue={this.state.startDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onStartDateChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-3">
                                <div className="row">
                                  <label className="mr-3">End :</label>
                                  <div style={{ width: "200px" }}>
                                    <Datetime
                                      defaultValue={this.state.endDate}
                                      input={true}
                                      closeOnSelect={true}
                                      onChange={this.onEndDateChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="row">
                                  <label className="mr-3">Driver :</label>
                                  <div style={{ width: "200px" }}>
                                    <CmbSelect
                                      name="driver"
                                      value={this.state.driver}
                                      onChange={this.onChange}
                                      dataSource={this.state.drivers}
                                    />
                                  </div>
                                  <div className="btn-group float-right">
                                    <button
                                      type="button"
                                      className="btn btn-primary ml-2 fa fa-search"
                                      onClick={this.getDriverLogCount}
                                    >
                                      <i className="fa" />
                                    </button>
                                    {this.exportToExceldButton()}
                                  </div>
                                  <div className="sweet-loading ml-2">
                                    <RiseLoader
                                      css={loaderCSS}
                                      sizeUnit={"px"}
                                      size={10}
                                      color={"#123abc"}
                                      loading={this.state.loading}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Driver Name</th>
                                  <th>Vehicle</th>
                                  <th>Plate No</th>
                                  <th>Event Time</th>
                                  <th>Trip Number</th>
                                  <th>Status Code</th>
                                </tr>
                              </thead>
                              <tbody>
                                {showDataRows.map((Item, i) => (
                                  <React.Fragment key={i}>
                                    <tr>
                                      <td>{Item.driver_name}</td>
                                      <td>{Item.title}</td>
                                      <td>{Item.plate_number}</td>
                                      <td>
                                        {AppHelper_DateAndTimeToString(
                                          Item.event_time
                                        )}
                                      </td>
                                      <td>{Item.device_tripno}</td>
                                      <td>{Item.statuscode}</td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                            <center>
                              {showDataRows.length
                                ? ""
                                : "No records available"}
                            </center>
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className="col-12"
                            style={{ textAlign: "right" }}
                          >
                            <Pagination
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.itemsCountPerPage}
                              totalItemsCount={this.state.totalItemsCount}
                              pageRangeDisplayed={10}
                              onChange={this.handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default DriverLog;
